import React, { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

const PathologyEdit = () => {
    const [contents, setContent] = useState('')
    const dispatchvalue = useDispatch();
    const navigate = useNavigate()
    const content = useSelector((state) => state.userRecord?.foreditcontent);
    const contentRef = useRef(null);
    const handlepagesave = () =>{
        dispatchvalue({type : 'pathalogydoc' , value : contents})
        dispatchvalue({type : 'iseditedsenior' , value : 'PathEdited'})

        navigate('/Home/NavigationPathalogist')
    }
  return (
    <div>
         <div ref={contentRef} className='editor_life'>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
        />
      </div>

      <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handlepagesave}>
            Save
          </button>
        </div>
    </div>
  )
}

export default PathologyEdit