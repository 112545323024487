import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './RegisReqList.css';
import SearchIcon from '@mui/icons-material/Search';
import LoupeIcon from '@mui/icons-material/Loupe';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';



import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';


function CancelAppointmentDialog({ open, onClose, onConfirm ,cancelReason,setCancelReason}) {

  

  const handleConfirm = () => {
    // Call the onConfirm callback with the cancellation reason
    onConfirm(cancelReason);
    // Close the dialog
    onClose();
    
  };

  const handleClose=()=>{
    setCancelReason('')
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cancel Register Reason</DialogTitle>
      <DialogContent>
        <TextField
          label="Cancellation Reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign:'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display:'flex !important',
            justifyContent:'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

function RegisterList(  ) {

const dispatchvalue = useDispatch();

  const SelectedPatientCalender = useSelector(state => state.userRecord?.SelectedPatientCalender);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setselectedId] = useState(null)

  const [cancelReason, setCancelReason] = useState('');

  const [rows, setRows] = useState([]);
  const [PatientFirstName, setPatientFirstName] = useState('');
  const [PatientPhoneNo, setPatientPhoneNo] = useState('');
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();

  const userRecord=useSelector(state=>state.userRecord?.UserData)

  const columns = [
    { field: 'PatientName', headerName: 'Patient Name', width: 150},
    { field: 'PhoneNo', headerName: 'Phone No', width: 150},
    { field: 'DoctorName', headerName: 'Doctor Name', width: 150 },
    { field: 'AppointmentType', headerName: 'Appointment Type', width: 160},
    { field: 'Status', headerName: 'Status', width: 150},
    { field: 'Location', headerName: 'Branch Location', width: 150},

    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <>
          <Button className='cell_btn'
           onClick={() => handleCancel(params)}
           >
            <HighlightOffSharpIcon  />
          </Button>
          </>
      ),
    },

  ];



  const handleCancel = (params) => {   

    
    const selectedId = params.row.id;
    console.log('Selected ID:', selectedId);
    setselectedId(selectedId)
    setOpenDialog(true);    
   
  };

  const handleCancelAppointment = () => {
    console.log('Cancellation Reason:', cancelReason);
    console.log(selectedId)
    
    const confirmation = window.confirm('Are you sure you want to cancel the appointment?');
    console.log(confirmation);

    if (confirmation) {
      axios
    .post('http://127.0.0.1:8000/appointmentmanagement/Post_deleted_appointment_patientregister', {
      selectedId: selectedId,
      cancelReason:cancelReason,
    })
    .then((response) => {
      console.log(response.data.message);
      alert("successfully deleted")
      
      handleFetchData()
    })
    .catch((error) => {
      console.error('Error updating record:', error);
    });
    setCancelReason('')
    setOpenDialog(false)
    }else{
      setCancelReason('')
      setOpenDialog(false)
    }
    
  };

  const handleRegisterForm = () => {
    navigate('/Home/Register');
    dispatchvalue({type:'selectedRequestList',value: null });
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const totalPages = Math.ceil(rows.length / 10);

  const handleSearch = (e) => {
    e.preventDefault();
  
    // Assuming you have PatientFirstName and PatientPhoneNo defined in your component state
    // const [PatientFirstName, setPatientFirstName] = useState('');
    // const [PatientPhoneNo, setPatientPhoneNo] = useState('');
  
    fetch('http://127.0.0.1:8000/appointmentmanagement/get_appointments')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          const filteredData = data.filter(appointment => {
            const lowerCaseFirstName = appointment?.FirstName.toLowerCase();
            const lowerCasePhoneNo = appointment?.PhoneNumber.toString();
  
            const lowerCaseSearch = PatientFirstName?.toLowerCase();
            const lowerCasePhoneSearch = PatientPhoneNo?.toLowerCase();
  
            // Check if the search query matches the start of either name or phone number,
            // and ignore the empty field
            return (
              (PatientFirstName === '' || lowerCaseFirstName?.startsWith(lowerCaseSearch)) &&
              (PatientPhoneNo === '' || lowerCasePhoneNo?.startsWith(lowerCasePhoneSearch))
            );
          });
  
          if (filteredData.length === 0) {
            alert('No matching appointments found.');
            setRows([]);
          } else {
            const Records = filteredData?.map((userdata,index) => ({
              id: userdata.appointment_patientregisterID,
              PatientName: userdata.firstname + ' ' + userdata.lastname,
              PhoneNo: userdata.PhoneNumber,
              DoctorName: userdata.DoctorName,
              AppointmentType: userdata.AppointmentPurpose,
              Status: userdata.Status,
            }));
            setRows(Records);
          }
        } else {
          console.error('Fetched data is not as expected:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching appointment data:', error);
      });
  };
  
  
  const handleFetchData = () => {
    axios.get( `http://127.0.0.1:8000/appointmentmanagement/get_appointments?location=${userRecord?.location}`)
      .then((response) => {
        console.log(response.data)
        // Assuming 'data' is an array as per the provided data structure
        const Records = response.data.map((userdata) => ({
          id: userdata.appointment_patientregisterID,
          PatientName: userdata.FirstName + ' ' + userdata.LastName,
          PhoneNo: userdata.PhoneNumber,
          DoctorName: userdata.DoctorName,
          AppointmentType: userdata.AppointmentPurpose,
          Status: userdata.Status,
          Location: userdata.Location
        }));
        if(SelectedPatientCalender){
          const newrecords =Records.filter((p)=>SelectedPatientCalender  ===p.PatientName)
          setRows(newrecords);
        }else{
          setRows(Records);
        }
        
      })
      .catch((error) => {
        console.error('Error fetching patient data:', error);
      });
  };

  
  useEffect(() => {
    axios.get( `http://127.0.0.1:8000/appointmentmanagement/get_appointments?location=${userRecord?.location}`)
      .then((response) => {
        console.log(response.data)
        // Assuming 'data' is an array as per the provided data structure
        const Records = response.data.map((userdata) => ({
          id: userdata.appointment_patientregisterID,
          PatientName: userdata.FirstName + ' ' + userdata.LastName,
          PhoneNo: userdata.PhoneNumber,
          DoctorName: userdata.DoctorName,
          AppointmentType: userdata.AppointmentPurpose,
          Status: userdata.Status,
          Location: userdata.Location
        }));
        if(SelectedPatientCalender){
          const newrecords =Records.filter((p)=>SelectedPatientCalender  ===p.PatientName)
          setRows(newrecords);
        }else{
          setRows(Records);
        }
        
      })
      .catch((error) => {
        console.error('Error fetching patient data:', error);
      });
  },[SelectedPatientCalender]);

 

  return (
    <>
      <Helmet>
        <title>Register List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h3>Register List</h3>
        </div>
        <form onSubmit={handleSearch}>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">First Name :</label>
            <input
              type="text"
              id="FirstName"
              value={PatientFirstName}
              onChange={(e) => setPatientFirstName(e.target.value)}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone No :</label>
            <input
              type="number"
              id="PhoneNo"
              value={PatientPhoneNo}
              onChange={(e) => setPatientPhoneNo(e.target.value)}
              placeholder="Enter the Phone No"
            />
          </div>
          <button className="btn_1" type='submit'>
            <SearchIcon />
          </button>
          <button
            className="btn_1"
            onClick={() => handleRegisterForm()}
            title="New Register"
          >
            <LoupeIcon />
          </button>
        </div>
        
        </form>
        <ThemeProvider theme={theme} className="theme">
          <div className="grid_1">
            <DataGrid
              rows={rows.slice(page * 10, (page + 1) * 10)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {rows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
      <CancelAppointmentDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleCancelAppointment}
        setCancelReason={setCancelReason}
        cancelReason={cancelReason}
      />
    </>
  );
}

export default RegisterList;
