import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function LabLocationStock() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const [page1, setPage1] = useState(0);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [Productype, setProductype] = useState("");
  const [summa, setsumma] = useState([]);
  const [selectedShow, setSelectedShow] = useState(false);
  // const [statustype, setstatustype] = useState("NotExpired");

  const [Rowdata, setRowdata] = useState([]);

  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${urllink}SupplierMaster/Expiry_Soon_stocks_medical_by_location?Location=${userRecord?.location}`
  //     )
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.length !== 0) {
  //         const rows = data.map((p) => {
  //           return `Item: ${p.ItemName}, BatchNo: ${p.BatchNo}, Pack_type: ${p.Pack_type}`;
  //         });

  //         console.log(rows);
  //         alert(
  //           `These Medicines are Expiring within the next 6 months :\n \t${rows.join(
  //             "\n"
  //           )}`
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${urllink}SupplierMaster/Expired_stocks_medical_by_Location?Location=${userRecord?.location}&Productype=${Productype}`
  //     )
  //     .then((response) => {
  //       const data = response.data;
  //       console.log(data);
  //       if (data.length !== 0) {
  //         const rows = data.map((p) => {
  //           return `Item: ${p.ItemName}, BatchNo: ${p.BatchNo}, Pack_type: ${p.Pack_type}`;
  //         });

  //         console.log(rows);
  //         alert(`These Medicines are Expired :\n \t${rows.join("\n")}`);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    if (Productype !== "") {
      if (Productype === "Medical") {
        axios
          .get(
            `${urllink}LabInventory/get_Location_Stock_medinfo?Location=${userRecord?.location}&Productype=${Productype}`
          )
          .then((response) => {
            const data = response.data;
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error("Error fetching patients data:", error);
          });
      } else {
        axios
          .get(
            `${urllink}LabInventory/get_Location_Stock_nonmedinfo?producttype=${Productype}&Location=${userRecord?.location}`
          )
          .then((response) => {
            const data = response.data;
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error("Error fetching patients data:", error);
          });
      }
    }
  }, [Productype]);

  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    console.log(params.row);
    const { Item_Code, Pack_type } = params.row;
    axios
      .get(
        `${urllink}LabInventory/get_Stock_Location_medinfo_by_name?Pack_type=${Pack_type}&Item_Code=${Item_Code}&Location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;
        console.log(data);
        setSelectedShow(true);
        setRowdata([
          ...data.map((row, ind) => ({
            id: ind + 1,
            ...row,
          })),
        ]);
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.Item_Code.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);

  // Define the columns dynamically
  const dynamicColumns = () => {
    if (Productype === "Medical" ){
      return [
        { field: "Item_Code", headerName: "Item Code", width: 150 },
        { field: "Item_Name", headerName: "Item Name", width: 150 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 120 },
        { field: "Pack_type", headerName: "Pack Type", width: 120 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 120 },
        { field: "SoldQuantity", headerName: "Sold Quantity", width: 120 },
        {
          field: "AvailableQuantity",
          headerName: "Available Quantity",
          width: 120,
          renderCell:(params)=>{
            let cellValue = params.value;
          let val = 'green';
          if (cellValue <= 50) {
            val = 'red';
          } else if (cellValue > 50 && cellValue <= 100) {
            val = 'orange';
          } else {
            val = 'green';
          }
            console.log(params.value);
            return(
              <div style={{color:val}}>
              {cellValue}
              </div>
            )
            
          }
        },
        {
          field: "View",
          headerName: "View",
          width: 100,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params)}
                title="View"
              >
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
      ];
    }
    else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 150 },
        { field: "Item_Name", headerName: "Item Name", width: 150 },
        { field: "Pack_type", headerName: "Pack Type", width: 120 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 120 },
        { field: "IssuedQuantity", headerName: "Moved Quantity", width: 120 },
        {
          field: "AvailableQuantity",
          headerName: "Available Quantity",
          width: 120,
          renderCell:(params)=>{
            let cellValue = params.value;
          let val = 'green';
          if (cellValue <= 50) {
            val = 'red';
          } else if (cellValue > 50 && cellValue <= 100) {
            val = 'orange';
          } else {
            val = 'green';
          }
            console.log(params.value);
            return(
              <div style={{color:val}}>
              {cellValue}
              </div>
            )
            
          }
        },
      ];
    }
  };

  const dynamicColumns1 = () =>{
   if (Productype==='Medical'){
    return[
        { field: "Item_Code", headerName: "Item Code", width: 150 },
        { field: "Item_Name", headerName: "Item Name", width: 150 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 120 },
        { field: "Pack_type", headerName: "Pack Type", width: 120 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
        { field: "Batch_No", headerName: "Batch No ", width: 120 },
        { field: "HSN_Code", headerName: "Batch No ", width: 120 },
        { field: "ManufactureDate", headerName: "Manufacture Date", width: 120 },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 120 },
        { field: "TotalQuantity", headerName: "Total Quantity", width: 120 },
        { field: "SoldQuantity", headerName: "Sold Quantity", width: 120 },
        {
          field: "AvailableQuantity",
          headerName: "Available Quantity",
          width: 120,
          renderCell:(params)=>{
            let cellValue = params.value;
          let val = 'green';
          if (cellValue <= 50) {
            val = 'red';
          } else if (cellValue > 50 && cellValue <= 100) {
            val = 'orange';
          } else {
            val = 'green';
          }
            console.log(params.value);
            return(
              <div style={{color:val}}>
              {cellValue}
              </div>
            )
            
          }
        },
    ]
   } 
   
  }
 

  return (
    <>
          <div className="appointment">
      <div className="h_head h_head_h_2">
          <h4>Location Store Stock List</h4>

          <div className="doctor_select_1 selt-dctr-nse vcxw2er">
            <label htmlFor="Calender"> Product Type :</label>
            <select
              className="Product_Master_div_select_opt"
              value={Productype}
              onChange={(e) => {
                setProductype(e.target.value);
              }}
            >
            <option value="">-Select-</option>
            <option value="Medical">Medical</option>
            <option value="NonMedical">Non Medical</option>
            <option value="Stationary">Stationary</option>
            </select>
          </div>
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Invoice No <span>:</span></label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Supplier Code"
            />
          </div>
{/* 
          {(Productype === "MedicalConsumable" ||
          Productype === "MedicalNonConsumable" ||
          Productype === "NonMedicalConsumable" ||
          Productype === "NonMedicalNonConsumable" ||
          Productype === "Surgical") && (
            <div className="inp_1">
              <label htmlFor="input">Status <span>:</span></label>
              <select
                value={statustype}
                onChange={(e) => setstatustype(e.target.value)}
              >
                <option value="NotExpired">Not-Expired</option>
                <option value="ExpirySoon">Expires Soon</option>
                <option value="Expired">Expired</option>
              </select>
            </div>
          )} */}

        </div>
        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns()} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}

          {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>{Productype} Stock List</h3>
                  </div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} // Display only the current page's data
                          columns={dynamicColumns1()} // Use dynamic columns here
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
