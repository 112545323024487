import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import { saveAs } from 'file-saver';
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const LabIndentReturnMaster = () => {
 

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const navigate = useNavigate();

  const [datalist, setdatalist] = useState([]);

  const cu_date = format(new Date(), "yyyy-MM-dd");

  // console.log('vathuruchu',IndentReturnListData)

  const [isedit, setisedit] = useState(null);

  const [Selecteditemdata, setSelecteditemdata] = useState([]);

  const [ItemCodedetailes, setItemCodedetailes] = useState([]);

  const [OverallPurchaseRecieveFormData, setOverallPurchaseRecieveFormData] =
    useState({
      Indent_Return_Invoice_NO: "",
      Indent_Return_Date: cu_date,
      ProductCategory: "",
      Reason: "",
      CreatedBy: userRecord?.username,
      Location: userRecord?.location,
      RecievedBy: "",
      Finel_NetAmount: "",
      Return_From: userRecord?.location,
      Return_To: "CentralStore",
    });

  const [PurchaseRecieveFormData, setPurchaseRecieveFormData] = useState({
    Item_Code: "",
    Item_Name: "",
    Generic_Name: "",
    Strength: "",
    UOM: "",
    Pack_type: "",
    Pack_Quantity: "",
    Return_TotalQuantity: "",
    HSNCode: "",
    BatchNo: "",
    ManufactureDate: "",
    ExpiryDate: "",
    MRP: "",
    Tax_Percentage: "",
    TotalAmount: "",
  });

  console.log("qqq", OverallPurchaseRecieveFormData);


  const [datalist1, setdatalist1] = useState([]);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const showdown1 = datalist1.length;
  const totalPages = Math.ceil(datalist.length / 10);
  const totalPages1 = Math.ceil(datalist1.length / 10);

  console.log("cu_date:", datalist1);


  useEffect(()=>{
    if(Object.keys(userRecord).length !==0){
      setOverallPurchaseRecieveFormData((prev)=>({
        ...prev,
        CreatedBy: userRecord.username || '',
        Location: userRecord.location || '',
        Return_From: userRecord.location || '',
      }))
    }

  },[userRecord])


  useEffect(() => {
    
      if (
        OverallPurchaseRecieveFormData.ProductCategory !=="") {
        axios
          .get(
            `${urllink}LabInventory/get_All_Indent_Return_InvoiceNo?productcategorytype=${OverallPurchaseRecieveFormData.ProductCategory}`
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Return_Invoice_NO: response.data.nextInvoiceNo,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } 
      
    

  }, [OverallPurchaseRecieveFormData.ProductCategory]);

 
 
  useEffect(() => {
    
    if(OverallPurchaseRecieveFormData.ProductCategory !=='')
      {
    if (
      OverallPurchaseRecieveFormData.ProductCategory === "Medical" ) {
      axios
        .get(
          `${urllink}LabInventory/get_Medical_ProductMaster_data`
        )
        .then((response) => {
          console.log("ITEM:", response.data);
          const A_Status = response.data.filter((ele) => ele.Status === "Active");
          setItemCodedetailes(A_Status);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      axios
        .get(
          `${urllink}LabInventory/get_NonMedical_ProductMaster_data`
        )
        .then((response) => {
          console.log("ITEM:", response.data);
          const filteredData = response.data.filter((ele) => ele.Status === "Active" && ele.ProductCategory === OverallPurchaseRecieveFormData.ProductCategory);
          setItemCodedetailes(filteredData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } 
    }
  }, [OverallPurchaseRecieveFormData.ProductCategory]);


  useEffect(() => {
    if (PurchaseRecieveFormData.Item_Code !=='' && OverallPurchaseRecieveFormData.ProductCategory !=='') {
      if ( OverallPurchaseRecieveFormData.ProductCategory === "Medical" ) {
        axios
          .get(
            `${urllink}LabInventory/get_medical_stock_information_location?Item_Code=${PurchaseRecieveFormData.Item_Code}&productcategorytype=${OverallPurchaseRecieveFormData.ProductCategory}`
          )
          .then((response) => {
            console.log("veera1:", response.data);
            setSelecteditemdata(response.data);
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
      else{
        axios
        .get(
          `${urllink}LabInventory/getNonMedical_Stock_information_location?Item_Code=${PurchaseRecieveFormData.Item_Code}&productcategorytype=${OverallPurchaseRecieveFormData.ProductCategory}`
        )
        .then((response) => {
          console.log("veera2:", response.data);
          setSelecteditemdata(response.data);
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error fetching data:", error);
        });
      }
    }
  }, [PurchaseRecieveFormData.Item_Code,OverallPurchaseRecieveFormData.ProductCategory]);


  useEffect(() => {
    if (datalist1.length !== 0) {
      let G_Total = 0;

      datalist1.map((ele, ind) => {
        return (G_Total += +ele.TotalAmount);
      });

      console.log("G_Total:", G_Total);

      setOverallPurchaseRecieveFormData((prev) => ({
        ...prev,
        Finel_NetAmount: G_Total,
      }));
    }
  }, [datalist1]);

  const dynamicColumns1 = () => {
    if ( OverallPurchaseRecieveFormData.ProductCategory === "Medical" ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 100 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        {
          field: "Return_TotalQuantity",
          headerName: "Return TotalQuantity",
          width: 170,
        },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
    else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        {
          field: "Return_TotalQuantity",
          headerName: "Return TotalQuantity",
          width: 170,
        },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
  };

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };

  const handlechangeReturnto = (e) => {
    const { name, value } = e.target;

    setOverallPurchaseRecieveFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputFormChange = (e) => {
    const { name, value } = e.target;

if(OverallPurchaseRecieveFormData.ProductCategory === "Medical")
  {
  if (name === "Item_Code") {
    const Set_datas = ItemCodedetailes.find((ele) => {
      return ele.ItemCode === value;
    });

    if (Set_datas) {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Name: Set_datas.ItemName,
        Generic_Name: Set_datas.GenericName,
        Strength: Set_datas.Strength,
        UOM: Set_datas.UOM,
        Pack_type: Set_datas.ProductType,
        Pack_Quantity: Set_datas.ProductQuantity,
      }));
    } else {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Name: "",
        Generic_Name: "",
        Strength: "",
        UOM: "",
        Pack_type: "",
        Pack_Quantity: "",
      }));
    }
  } else if (name === "Item_Name") {
    const Set_datas = ItemCodedetailes.find((ele) => {
      return ele.ItemName === value;
    });

    if (Set_datas) {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Code: Set_datas.ItemCode,
        Generic_Name: Set_datas.GenericName,
        Strength: Set_datas.Strength,
        UOM: Set_datas.UOM,
        Pack_type: Set_datas.ProductType,
        Pack_Quantity: Set_datas.ProductQuantity,
      }));
    } else {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Code: "",
        Generic_Name: "",
        Strength: "",
        UOM: "",
        Pack_type: "",
        Pack_Quantity: "",
      }));
    }
  } else if (name === "BatchNo") {
    const S_data = Selecteditemdata.find((ele) => {
      return ele.Batch_No === value;
    });
    console.log("S_data:", S_data);
    if (S_data) {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
        HSNCode: S_data.HSN_Code,
        ManufactureDate: S_data.ManufactureDate,
        ExpiryDate: S_data.ExpiryDate,
        MRP: S_data.MRP_Per_Quantity,
        Tax_Percentage: S_data.Tax_Percentage,
        GRN_InvoiceNo: S_data.GRN_InvoiceNo,
      }));
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
        HSNCode: "",
        ManufactureDate: "",
        ExpiryDate: "",
        MRP: "",
        Tax_Percentage: "",
      }));
    }
  }else {
    setPurchaseRecieveFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
}else{
  if (name === "Item_Code") {
    const Set_datas = ItemCodedetailes.find((ele) => {
      return ele.ItemCode === value;
    });    
    if (Set_datas) {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Name: Set_datas.ItemName,
        Pack_type: Set_datas.ProductType,
        Pack_Quantity: Set_datas.ProductQuantity,
      }));
    } else {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Name: "",
        Pack_type: "",
        Pack_Quantity: "",
      }));
    }
  } else if (name === "Item_Name") {
    const Set_datas = ItemCodedetailes.find((ele) => {
      return ele.ItemName === value;
    });
    if (Set_datas) {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Code: Set_datas.ItemCode,
        Pack_type: Set_datas.ProductType,
        Pack_Quantity: Set_datas.ProductQuantity,
      }));
    } else {
      setPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        [name]: value,
        Item_Code: "",
        Pack_type: "",
        Pack_Quantity: "",
      }));
    }
  } 
   else {
    setPurchaseRecieveFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
}

   
  };


  useEffect(()=>{
    if(OverallPurchaseRecieveFormData.ProductCategory !=="Medical" && PurchaseRecieveFormData.Item_Code !=='' && Object.keys(Selecteditemdata).length !==0)
      {
      
      const S_data = Selecteditemdata.find((ele) => {
        return ele.
        Item_Code
         === PurchaseRecieveFormData.Item_Code;
        
      });
      
      console.log('useeffff2222',Selecteditemdata)

      console.log('useeffff',S_data)

      if(S_data){
        setPurchaseRecieveFormData((prevData) => ({
          ...prevData,
          MRP: S_data.MRP_Per_Quantity,
          Tax_Percentage: S_data.Tax_Percentage,
        }));
      }
      else{
        setPurchaseRecieveFormData((prevData) => ({
          ...prevData,
          MRP:"",
          Tax_Percentage:"",
        }));
      }
    
    }
  },[Selecteditemdata,OverallPurchaseRecieveFormData.ProductCategory,PurchaseRecieveFormData.Item_Code])


  useEffect(() => {
    const T_Amount =
      PurchaseRecieveFormData.MRP *
      PurchaseRecieveFormData.Return_TotalQuantity;

    setPurchaseRecieveFormData((prev) => ({
      ...prev,
      TotalAmount: T_Amount.toFixed(2),
    }));
  }, [
    PurchaseRecieveFormData.Return_TotalQuantity,
    PurchaseRecieveFormData.MRP, 
  ]);



  const handleRequestEdit1 = (params) => {
    setPurchaseRecieveFormData({ ...params });
    setisedit(params);
  };

  const handleAdd = () => {
    let requiredFields;
    if (OverallPurchaseRecieveFormData.ProductCategory === "Medical") {
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Generic_Name",
        "Strength",
        "UOM",
        "Pack_type",
        "Return_TotalQuantity",
        "HSNCode",
        "BatchNo",
        "ManufactureDate",
        "ExpiryDate",
        "MRP",
        "Tax_Percentage",
        "TotalAmount",
      ];
    } else {
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Pack_type",
        "Return_TotalQuantity",
        "MRP",
        "Tax_Percentage",
        "TotalAmount",
      ];
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      console.log("PurchaseRecieveFormData", PurchaseRecieveFormData);
      let id = 0;
      const data = { id: datalist1.length + 1, ...PurchaseRecieveFormData };

      console.log("dataArray", data);
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) =>
        prev.filter(
          (item) => item.Item_Code !== PurchaseRecieveFormData.Item_Code
        )
      );
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleUpdate = () => {
    let requiredFields;
    if (OverallPurchaseRecieveFormData.ProductCategory === "Medical") {
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Generic_Name",
        "Strength",
        "UOM",
        "Pack_type",
        "Return_TotalQuantity",
        "HSNCode",
        "BatchNo",
        "ManufactureDate",
        "ExpiryDate",
        "MRP",
        "Tax_Percentage",
        "TotalAmount",
      ];
    } else {
      requiredFields = [
        "Item_Code",
        "Item_Name",
        "Pack_type",
        "Return_TotalQuantity",
        "MRP",
        "Tax_Percentage",
        "TotalAmount",
      ];
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex(
        (item) => item.Item_Code === isedit.Item_Code
      );
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData;
        return updatedCart;
      });
      setisedit(null);
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

 

  const handleClear = () => {
   
      setPurchaseRecieveFormData({
        Item_Code: "",
        Item_Name: "",
        Generic_Name: "",
        Strength: "",
        UOM: "",
        Pack_type: "",
        Pack_Quantity: "",
        Return_TotalQuantity: "",
        HSNCode: "",
        BatchNo: "",
        ManufactureDate: "",
        ExpiryDate: "",
        MRP: "",
        Tax_Percentage: "",
        TotalAmount: "",
      });
  };



  const handleSubmit = () => {
    const requiredFields = [
      "Finel_NetAmount",
      "Return_From",
      "Return_To",
      "Reason",
    ];

    const missingFields = requiredFields.filter(
      (field) => !OverallPurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const newdataa = {
        OverallPurchaseRecieveFormData: { ...OverallPurchaseRecieveFormData },
        datalist1: [...datalist1],
      };

      axios
        .post(
          `${urllink}LabInventory/All_indent_Return_insert`,
          newdataa
        )
        .then((response) => {
          console.log(response.data);
          // navigate("/Home/LabIndentReturnList");
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });

      console.log(newdataa);
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Indent Return Invoice</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Product Category <span>:</span>
            </label>

            <select
              value={OverallPurchaseRecieveFormData.ProductCategory}
              name="ProductCategory"
              onChange={handlechangeReturnto}
            >
              <option value="">-Select-</option>
              <option value="Medical">Medical</option>
              <option value="NonMedical">Non Medical</option>
              <option value="Stationary">Stationary</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Indent Return Invoice No <span>:</span>
            </label>
            <input
              type="text"
              name="Indent_Return_Invoice_NO"
              value={OverallPurchaseRecieveFormData.Indent_Return_Invoice_NO}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Indent Return Date <span>:</span>
            </label>
            <input
              type="date"
              name="Indent_Return_Date"
              value={OverallPurchaseRecieveFormData.Indent_Return_Date}
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Return From <span>:</span>
            </label>
            <input
              type="text"
              name="Return_From"
              value={OverallPurchaseRecieveFormData.Return_From}
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Return To <span>:</span>
            </label>
            <input
              type="text"
              name="Return_To"
              value={OverallPurchaseRecieveFormData.Return_To}
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Reason <span>:</span>
            </label>

            <select
              value={OverallPurchaseRecieveFormData.Reason}
              name="Reason"
              onChange={handlechangeReturnto}
            >
              <option value="">Select</option>
               <option value="Expired">Expired</option>
              <option value="Quality">Quality</option>
              <option value="Damage">Damage</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Net Amount <span>:</span>
            </label>
            <input
              type="number"
              name="Finel_NetAmount"
              value={OverallPurchaseRecieveFormData.Finel_NetAmount}
              readOnly
              required
            />
          </div>
        </div>
        <br />
    
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  Item Code <span>:</span>
                </label>

                <input
                  type="text"
                  name="Item_Code"
                  list="ItemCode_list"
                  value={PurchaseRecieveFormData.Item_Code}
                  onChange={handleInputFormChange}
                  required
                  autoComplete="off"
                />
                <datalist id="ItemCode_list">
                  {ItemCodedetailes.map((item, index) => (
                    <option key={index} value={item.ItemCode}></option>
                  ))}
                </datalist>
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Item Name <span>:</span>
                </label>
                <input
                  type="text"
                  name="Item_Name"
                  list="ItemCode_name"
                  value={PurchaseRecieveFormData.Item_Name}
                  onChange={handleInputFormChange}
                  required
                />
                <datalist id="ItemCode_name">
                  {ItemCodedetailes.map((item, index) => (
                    <option key={index} value={item.ItemName}></option>
                  ))}
                </datalist>
              </div>
            {OverallPurchaseRecieveFormData.ProductCategory === "Medical" &&
              <>
              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Generic Name <span>:</span>
                </label>
                <input
                  type="text"
                  name="Generic_Name"
                  value={PurchaseRecieveFormData.Generic_Name}
                  readOnly
                />
              </div>             
             

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Strength <span>:</span>
                </label>
                <input
                  type="text"
                  name="Strength"
                  value={PurchaseRecieveFormData.Strength}
                  readOnly
                />
              </div>
              
              
              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  UOM <span>:</span>
                </label>
                <input
                  type="text"
                  name="UOM"
                  value={PurchaseRecieveFormData.UOM}
                  required
                  readOnly
                />
              </div>
              </>
              }

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Pack Type <span>:</span>
                </label>
                <input
                  type="text"
                  name="Pack_type"
                  value={PurchaseRecieveFormData.Pack_type}
                  required
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Pack Quantity <span>:</span>
                </label>
                <input
                  type="number"
                  name="Pack_Quantity"
                  value={PurchaseRecieveFormData.Pack_Quantity}
                  readOnly
                />
              </div>

             { OverallPurchaseRecieveFormData.ProductCategory === "Medical" &&
             <>
             <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  Batch No <span>:</span>
                </label>
                <input
                  id="browser"
                  list="browsers"
                  type="text"
                  name="BatchNo"
                  value={PurchaseRecieveFormData.BatchNo}
                  onChange={handleInputFormChange}
                  required
                  autoComplete="off"
                />

                <datalist id="browsers">
                  {Selecteditemdata.map((item, index) => (
                    <option key={index} value={`${item.Batch_No}`}>
                      {`${item.Item_Name} | BatchNo:${
                        item.Batch_No
                      } | Ava.Quantity:${
                        item.AvailableQuantity
                      } | MRP:${parseFloat(item.MRP_Per_Quantity).toFixed(2)}`}
                    </option>
                  ))}
                </datalist>
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  HSN Code <span>:</span>
                </label>
                <input
                  type="text"
                  name="HSNCode"
                  value={PurchaseRecieveFormData.HSNCode}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Manufacture Date <span>:</span>
                </label>
                <input
                  type="date"
                  name="ManufactureDate"
                  value={PurchaseRecieveFormData.ManufactureDate}
                  readOnly
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Expiry Date <span>:</span>
                </label>
                <input
                  type="date"
                  name="ExpiryDate"
                  value={PurchaseRecieveFormData.ExpiryDate}
                  readOnly
                />
              </div>
              </>
              }

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Return Quantity / Piece  <span>:</span>
                </label>
                <input
                  type="number"
                  list="AvailQ_list"
                  name="Return_TotalQuantity"
                  value={PurchaseRecieveFormData.Return_TotalQuantity}
                  onChange={handleInputFormChange}
                />
                { OverallPurchaseRecieveFormData.ProductCategory !== "Medical" && <datalist id="AvailQ_list">
                  {Selecteditemdata.map((item, index) => (
                    <option key={index}>
                    {`${item.Item_Name} | Ava.Quantity:${
                      item.AvailableQuantity
                    } | MRP:${parseFloat(item.MRP_Per_Quantity).toFixed(2)}`}
                  </option>
                  ))}
                </datalist>}
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  MRP (Per Quantity) <span>:</span>
                </label>
                <input
                  type="number"
                  name="MRP"
                  value={PurchaseRecieveFormData.MRP}
                  readOnly
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">Tax Percentage<span>:</span></label>
                <input
                  type="number"
                  name="Tax_Percentage"
                  value={PurchaseRecieveFormData.Tax_Percentage}
                  readOnly
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  Total Amount <span>:</span>
                </label>
                <input
                  type="number"
                  name="TotalAmount"
                  value={PurchaseRecieveFormData.TotalAmount}
                  required
                  readOnly
                />
              </div>
            </div>

            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={isedit ? handleUpdate : handleAdd}
              >
                {isedit ? "Update" : "Add"}
              </button>
            </div>
          
       

       


        {datalist1.length !== 0 && (
          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={datalist1.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns1()} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange1}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown1 > 0 && datalist1.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && datalist1.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        )}
        {datalist1.length !== 0 && (
          <div className="Register_btn_con">
            <button className=" RegisterForm_1_btns" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default LabIndentReturnMaster;
