import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import './ClinicDetails.css';
import { useSelector } from 'react-redux';

const ClinicDetails = ( ) => {

    const userRecord = useSelector(state=>state.userRecord?.UserData)
    const urllink=useSelector(state=>state.userRecord?.UrlLink)

    console.log(userRecord);

    const [concernName, setConcernName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [landline, setLandline] = useState('');
    const [gstno, setGstno] = useState('');
    const [doorNo, setDoorNo] = useState('');
    const [street, setStreet] = useState('');
    const [area, setArea] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');

    useEffect(() => {
        // Make the POST request to fetch clinic details
        Axios.get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)
        .then(response => {
          const record = response.data[0]; // Access the first (and only) object in the array
          console.log(record);
          setConcernName(record.concern_name);
          setEmail(record.email);
          setPhoneNo(record.phone_no);
          setLandline(record.landline_no);
          setGstno(record.Gst_no)
          setDoorNo(record.door_no);
          setStreet(record.street);
          setArea(record.area);
          setCity(record.city);
          setPincode(record.pincode);
          setState(record.state);
          setCountry(record.country);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      
    }, [userRecord?.location]);

    const handleSave = () => {
        // Create an object with the data to send to the backend
        const data = {
            concernName:concernName,
            email:email,
            phoneNo:phoneNo,
            landline_no:landline,
            Gst_no:gstno,
            doorNo:doorNo,
            street:street,
            area:area,
            city:city,
            pincode:pincode,
            state:state,
            country:country,
            location: userRecord?.location
        };

        // Send a POST request to your backend endpoint
        Axios.post(`${urllink}usercontrol/clinicInsert`, data)
            .then(response => {
                // Handle the response if needed
                alert(response.data.message);
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.error('Error:', error);
            });
    };


    return (
        <>
            <div className="Clinic_det_new ">
                <div className="clinic_head">
                    <h3> Clinic Details</h3>
                </div>
                <div className="bill_table_invoice ">
                    <div className="new_user_items">
                        <div className="new_clinic_form">
                            <div className="new_form_pack">
                                <label className="new_form_first new_first_1" htmlFor="concernName">Clinic Name:</label>
                                <input
                                    type="text"
                                    id="concernName"
                                    name="concernName"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your first name"
                                    value={concernName}
                                    onChange={(e) => setConcernName(e.target.value)}
                                />
                            </div>
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="email">Email:</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="new_clinic_form">
                           
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="phoneNo">Phone no:</label>
                                <input
                                    type="text"
                                    id="phoneNo"
                                    name="phoneNo"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your Phone No"
                                    value={phoneNo}
                                    onChange={(e) => setPhoneNo(e.target.value)}
                                />
                            </div>
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="email">Landline No:</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your Landline No"
                                    value={landline}
                                    onChange={(e) => setLandline(e.target.value)}
                                />
                            </div>
                        </div>


                        <div className="new_clinic_form">
                            
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="phoneNo">GST No:</label>
                                <input
                                    type="text"
                                    id="phoneNo"
                                    name="phoneNo"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your GST No"
                                    value={gstno}
                                    onChange={(e) => setGstno(e.target.value)}
                                />
                            </div>
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="doorNo">Door No:</label>
                                <input
                                    type="text"
                                    id="doorNo"
                                    name="doorNo"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your Door No"
                                    value={doorNo}
                                    onChange={(e) => setDoorNo(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="new_clinic_form">
                            
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="street">Street:</label>
                                <input
                                    type="text"
                                    id="street"
                                    name="street"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your first name"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                />
                            </div>
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="area">Area:</label>
                                <input
                                    type="text"
                                    id="area"
                                    name="area"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your Area"
                                    value={area}
                                    onChange={(e) => setArea(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="new_clinic_form">
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="city">City:</label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                            <div className="new_packlog">
                                <label className="new_form_first" htmlFor="pincode">PinCode:</label>
                                <input
                                    type="text"
                                    id="pincode"
                                    name="pincode"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your PinCode"
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="new_clinic_form">
                            <div className="new_form_pack">
                                <label className="new_form_first" htmlFor="state">State:</label>
                                <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your State"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </div>

                            <div className="new_packlog">
                                <label className="new_form_first" htmlFor="country">Country:</label>
                                <input
                                    type="text"
                                    id="country"
                                    name="country"
                                    className="new_clinic_form_inp"
                                    placeholder="Enter your Country"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </div>

                        </div>
                        <button className="new_btn1_1" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClinicDetails;
