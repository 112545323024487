import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { saveAs } from "file-saver";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";

const BalanceSheet = () => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");

  console.log(formattedDate);

  const [SearchformData, setSearchformData] = useState({
    Location: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });

  const [Location, setLocation] = useState([]);

  const [Totaldata, setTotaldata] = useState({
    Assettotal: "",
    Liabilitietotal: "",
  });

  const [Assetarray, setAssetarray] = useState([]);

  const [Liabilitiesarray, setLiabilitiesarray] = useState([]);

  const [conbinedData, setconbinedData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setSearchformData({
          ...SearchformData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setSearchformData({
          ...SearchformData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setSearchformData({
        ...SearchformData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setSearchformData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
    axios
      .get(`http://127.0.0.1:8000/usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map((p) => p.location_name);

        console.log([...data, "CentralStore"]);
        setLocation([...data, "CentralStore"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handletoSearch = () => {
    const params = {
      Location: SearchformData.Location,
      DateType: SearchformData.DateType,
      CurrentDate: SearchformData.CurrentDate,
      FromDate: SearchformData.FromDate,
      ToDate: SearchformData.ToDate,
    };

    let requiredFields = [];
    if (SearchformData.DateType === "Customize") {
      requiredFields = ["Location", "DateType", "FromDate", "ToDate"];
    } else {
      requiredFields = ["Location", "DateType"];
    }
    const existingItem = requiredFields.filter(
      (field) => !SearchformData[field]
    );
    if (existingItem.length > 0) {
      alert(`Please Fill the RequiredFields : ${existingItem.join(",")} `);
    } else {
      axios
        .get(
          `http://127.0.0.1:8000/FinanceMagement/get_Ledger_Entry_details_BalanceSheet`,
          { params }
        )
        .then((response) => {
          console.log("111", response.data);

          const Assdata = response.data.Assetsdata;

          const Libdata = response.data.Liabilitiesdata;

          const Asstot = response.data.Assettotal;

          const Libtot = response.data.Liabilitietotal;

          setAssetarray(Assdata);
          setLiabilitiesarray(Libdata);
          console.log();
          const result = Assdata.map((item, index) => {
            const matchingItem = Libdata[index];
            if (matchingItem) {
              return { ...item, ...matchingItem };
            } else {
              return item;
            }
          });
          console.log(result);
          setconbinedData(result);

          setTotaldata((prev) => ({
            ...prev,
            Assettotal: Asstot,
            Liabilitietotal: Libtot,
          }));

          // const A_data=response.data
          // const B_data =A_data.map((p,index)=>({
          //     id:index+1,
          //     ...p
          // }))
          // setFilteredRows(B_data)
          // console.log('111B_data',B_data)
          // setSearchformData({
          //     Location: '',
          //     DateType: '',
          //     CurrentDate:'',
          //     FromDate: '',
          //     ToDate: '',
          // })
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleExportToExcel = () => {
    if (Assetarray.length !== 0) {
      const columns = [
        { dataKey: "id", header: "S.NO" },
        { dataKey: "Location", header: "Branch Name" },
        { dataKey: "GRN_InvoiceNo", header: "GRN_InvoiceNo" },
        { dataKey: "GRN_Date", header: "GRN Date" },
        { dataKey: "Supplier_Bill_No", header: "Supplier Bill No" },
        { dataKey: "Supplier_Bill_Date", header: "Supplier Bill Date" },
        { dataKey: "Supplier_Code", header: "Supplier Code" },
        { dataKey: "Supplier_Name", header: "Supplier Name" },
        { dataKey: "SupplierGST", header: "Supplier GST No" },
        { dataKey: "TotalAmountWithoutTax", header: "TaxableValue" },
        { dataKey: "SGST 9.0", header: "CGST 9" },
        { dataKey: "CGST 9.0", header: "SGST 9" },
        { dataKey: "IGST 18.0", header: "IGST 18" },
        { dataKey: "SGST 6.0", header: "CGST 6" },
        { dataKey: "CGST 6.0", header: "SGST 6" },
        { dataKey: "IGST 12.0", header: "IGST 12" },
        { dataKey: "SGST 2.5", header: "CGST 2.5" },
        { dataKey: "CGST 2.5", header: "SGST 2.5" },
        { dataKey: "IGST 5.0", header: "IGST 5" },
        { dataKey: "TotalTaxAmount", header: "Total Tax Amount" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...Assetarray.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, "PurchaseRegisterReport.csv");
    } else {
      alert("No Data to Save");
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Balance Sheet</h4>
      </div>

      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="">
            Location <span>:</span>
          </label>
          <select
            name="Location"
            value={SearchformData.Location}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Location.map((p) => (
              <option key={p} value={p}>
                {p.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={SearchformData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>
  

      
        {SearchformData.DateType === "Current" && (
          <div className="RegisForm_1">
            <label htmlFor="">
              Current Date <span>:</span>
            </label>
            <input
              type="date"
              name="CurrentDate"
              value={SearchformData.CurrentDate}
              onChange={handleChange}
            />
          </div>
        )}
        {SearchformData.DateType === "Customize" && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="">
                From Date <span>:</span>
              </label>
              <input
                type="date"
                name="FromDate"
                value={SearchformData.FromDate}
                onChange={handleChange}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="">
                To Date <span>:</span>
              </label>
              <input
                type="date"
                name="ToDate"
                value={SearchformData.ToDate}
                onChange={handleChange}
              />
            </div>
          </>
        )}
         </div>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handletoSearch}>
          Search
        </button>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Liabilities</th>
              <th>Amount</th>
              <th>Assets</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {conbinedData.map((row, index) => (
              <tr key={index}>
                <td>
                  {row.Group_Name} <br />
                  {row.Under_Group}
                </td>
                <td>{row.Current_Balance}</td>
                <td>
                  {row.AssGroup_Name}
                  <br />
                  {row.AssUnder_Group}
                </td>
                <td>{row.AssCurrent_Balance}</td>
              </tr>
            ))}
            <tr>
              <td>
                Profit & Loss A/c <br />
                <br />
                <table>
                  <tr>
                    <th>Opening Balance</th>
                  </tr>
                  <tr>
                    <th>Opening Balance</th>
                  </tr>
                </table>
              </td>
              <td>
                Profit & Loss A/c <br />
                <br />
                <table>
                  <tr>
                    <th>Opening Balance</th>
                  </tr>
                  <tr>
                    <th>Opening Balance</th>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{Totaldata.Liabilitietotal}</td>
              <td>Total</td>
              <td>{Totaldata.Assettotal}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {Assetarray.length !== 0 && Liabilitiesarray.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Exel</button>
        </div>
      )}
    </div>
  );
};

export default BalanceSheet;
