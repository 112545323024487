import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

const RoomMaster = () => {
  const successMsg = (Message) => {
    toast.success(`${Message}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userRecord = useSelector(state => state.userRecord?.UserData)
  const [WardName, setWardName] = useState({
    WardId: '',
    WardType: '',
    Status: 'Active'
  });
  const [Wards, setWards] = useState([])
  const [IswardEdit, setIswardEdit] = useState(false)
  const [WardRoomType, setWardRoomType] = useState({
    RoomId: '',
    WardType: '',
    RoomType: '',
    Charge: '',
    Location: '',
    Status: 'Active'
  })
  const [WardRooms, setWardRoom] = useState([])
  const [IsRoomEdit, setIsRoomEdit] = useState(false)
  const [PackageType, setPackageType] = useState({
    PackageId: '',
    WardType: '',
    RoomType: '',
    Days: '',
    Charge: '',
    Location: '',
    Status: 'Active',
  })
  const [Packages, setPackages] = useState([])
  const [IsPackageEdit, setIsPackageEdit] = useState(false)
  const [LocationData, setLocationData] = useState([]);

  useEffect(() => {
    axios.get('http://127.0.0.1:8000/usercontrol/getlocationdata')
      .then((response) => {
        const data = response.data;
        console.log(data)

        setLocationData(data.filter((p) => p.status === 'Active' && p.location_name !== 'ALL'));
      })
      .catch((error) => {
        console.error('Error fetching location data:', error);
      });
  }, [])
  // ward ------------
  const handleWardType = () => {
    console.log(WardName)
    if (WardName.WardType) {
      axios.post(`http://127.0.0.1:8000/usercontrol/insert_Ward_Type`, WardName)
        .then((response) => {
          console.log(response)
          if (response.data.message) {
            successMsg(response.data.message)
          } else {
            userwarn(response.data.Exists)
          }
          setWardName({
            WardId: '',
            WardType: '',
            Status: 'Active'
          })
          setIswardEdit(false);
          getWardType()
        })
        .catch((error) => {
          console.log(error)
        })

    } else {
      userwarn('Please Fill the WardType');
    }
  };
  const handleEditWard = (row) => {

    setWardName({ ...row })
    setIswardEdit(true)
    const inputElement = document.getElementById("WardType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  const getWardType = useCallback(() => {
    axios.get(`http://127.0.0.1:8000/usercontrol/get_ward_type`)
      .then((response) => {
        console.log(response)
        setWards(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    getWardType()
  }, [getWardType])

  const handlewardClick = (row) => {
    const data = {
      WardId: row.WardId,
      WardType: row.WardType,
      Status: row.Status === 'Active' ? 'InActive' : 'Active'
    }
    axios.post(`http://127.0.0.1:8000/usercontrol/insert_Ward_Type`, data)
      .then((response) => {
        console.log(response)
        getWardType()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Room----------

  const handleRoomType = () => {
    if (WardRoomType.WardType && WardRoomType.RoomType && WardRoomType.Charge && WardRoomType.Location) {
      axios.post(`http://127.0.0.1:8000/usercontrol/insert_Room_Type`, WardRoomType)
        .then((response) => {
          console.log(response)
          if (response.data.message) {
            successMsg(response.data.message)
          } else {
            userwarn(response.data.Exists)
          }
          setWardRoomType({
            WardType: '',
            RoomType: '',
            Charge: '',
            Location: '',
            Status: 'Active'
          })
          setIsRoomEdit(false);
          getRoomType()
        })
        .catch((error) => {
          console.log(error)
        })

    } else {
      userwarn('Please Fill all the Fields');
    }
  };
  const handleEditRoom = (row) => {

    setWardRoomType({ ...row })
    setIsRoomEdit(true)
    const inputElement = document.getElementById("WardRoom");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  const getRoomType = useCallback(() => {
    axios.get(`http://127.0.0.1:8000/usercontrol/get_Room_type`)
      .then((response) => {
        console.log(response)
        setWardRoom(response.data)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [])
  useEffect(() => {
    getRoomType()

  }, [getRoomType])

  const handleRoomClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === 'Active' ? 'InActive' : 'Active'
    }
    axios.post(`http://127.0.0.1:8000/usercontrol/insert_Room_Type`, data)
      .then((response) => {
        console.log(response)
        getRoomType()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // package-------------

  const handlePackageType = () => {
    if (PackageType.WardType && PackageType.RoomType && PackageType.Days && PackageType.Charge && PackageType.Location) {
      axios.post(`http://127.0.0.1:8000/usercontrol/insert_Package`, PackageType)
        .then((response) => {
          console.log(response)
          if (response.data.message) {
            successMsg(response.data.message)
          } else {
            userwarn(response.data.Exists)
          }
          setPackageType({
            PackageId: '',
            WardType: '',
            RoomType: '',
            Days: '',
            Charge: '',
            Location: '',
            Status: 'Active',
          })
          setIsPackageEdit(false);
          getPackageType()
        })
        .catch((error) => {
          console.log(error)
        })

    } else {
      userwarn('Please Fill all the Fields');
    }
  };
  const handlePackageEdit = (row) => {

    setPackageType({ ...row })
    setIsPackageEdit(true)
    const inputElement = document.getElementById("PackageType");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  const getPackageType = useCallback(() => {
    axios.get(`http://127.0.0.1:8000/usercontrol/get_Package_type`)
      .then((response) => {
        console.log(response)
        setPackages(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
    axios.get(`http://127.0.0.1:8000/usercontrol/get_Package_type_id`)
      .then((response) => {
        console.log(response)
        setPackageType((prev) => ({
          ...prev,
          PackageId: response.data.next_id
        }))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    getPackageType()
  }, [getPackageType])

  const handlePackageClick = (row) => {
    const data = {
      ...row,
      Status: row.Status === 'Active' ? 'InActive' : 'Active'
    }
    axios.post(`http://127.0.0.1:8000/usercontrol/insert_Package`, data)
      .then((response) => {
        console.log(response)
        getPackageType()
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <div className='appointment'>
        <div className='h_head'>
          <h3>Room Masters</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='WardType'>
          <div className="RegisFormcon " >
            <div className="RegisForm_1">
              <label> Ward Type <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Ward Type'
                name='WardType'
                required
                value={WardName.WardType}
                onChange={(e) => setWardName((prev) => ({ ...prev, WardType: e.target.value.toUpperCase() }))}
              />
            </div>



          </div>
          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handleWardType}>{IswardEdit ? "Update" : "Add"}</button>
          </div>
          <div className="RateCard_table">
            <div className="RateCard_table_1">
              <table>
                <thead>
                  <tr>
                    <th>S_No</th>
                    <th>Ward Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Wards.map((client, index) => (
                    <tr key={index}>
                      <td>{client.WardId}</td>
                      <td>{client.WardType}</td>
                      <td>
                        <button onClick={() => handlewardClick(client)} className='Addnamebtn_pt2'>
                          {client.Status}
                        </button>
                      </td>
                      <td>
                        <button onClick={() => handleEditWard(client)} className='ratecarededitbutton'>
                          <EditIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='WardRoom'>
          <div className="RegisFormcon " >
            <div className="RegisForm_1">
              <label> Ward Type <span>:</span> </label>

              <select
                name='WardType'
                required
                value={WardRoomType.WardType}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, WardType: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  Array.from(new Set(Wards.filter(p => p.Status === 'Active').map(p => p.WardType))).map((wardType, index) => (
                    <option key={index} value={wardType}>{wardType}</option>
                  ))
                }
              </select>
            </div>
            <div className="RegisForm_1">
              <label> Room Type <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Room Type'
                name='RoomType'
                required
                value={WardRoomType.RoomType}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, RoomType: e.target.value.toUpperCase() }))}
              />
            </div>
            <div className="RegisForm_1">
              <label> Charge <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Charge'
                name='Charge'
                required
                value={WardRoomType.Charge}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, Charge: e.target.value }))}
              />
            </div>


          </div>
          <div className="RegisFormcon ">
            <div className="RegisForm_1">
              <label> Location <span>:</span> </label>

              <select
                name='Location'
                required
                value={WardRoomType.Location}
                onChange={(e) => setWardRoomType((prev) => ({ ...prev, Location: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  LocationData.map((p, index) => (
                    <option key={index} value={p.location_name}>{p.location_name}</option>
                  ))
                }
              </select>
            </div>



          </div>
          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handleRoomType}>{IsRoomEdit ? "Update" : "Add"}</button>
          </div>
          <div className="RateCard_table">
            <div className="RateCard_table_1">
              <table>
                <thead>
                  <tr>
                    <th>S_No</th>
                    <th>Ward Type</th>
                    <th>Room Type</th>
                    <th>Charge</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {WardRooms.map((client, index) => (
                    <tr key={index}>
                      <td>{client.RoomId}</td>
                      <td>{client.WardType}</td>
                      <td>{client.RoomType}</td>
                      <td>{client.Charge}</td>
                      <td>{client.Location}</td>
                      <td>
                        <button onClick={() => handleRoomClick(client)} className='Addnamebtn_pt2'>
                          {client.Status}
                        </button>
                      </td>
                      <td>
                        <button onClick={() => handleEditRoom(client)} className='ratecarededitbutton'>
                          <EditIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} id='PackageType'>
          <div className="RegisFormcon " >

            <div className="RegisForm_1">
              <label> Package Id <span>:</span> </label>
              <input
                type="text"
                placeholder='Enter the Package Id'
                name='PackageId'
                required
                value={PackageType.PackageId}
                onChange={(e) => setPackageType((prev) => ({ ...prev, PackageId: e.target.value }))}
              />
            </div>
            <div className="RegisForm_1">
              <label> Ward Type <span>:</span> </label>

              <select
                name='WardType'
                required
                value={PackageType.WardType}
                onChange={(e) => setPackageType((prev) => ({ ...prev, WardType: e.target.value }))}
              >
                <option value=''>Select</option>

                {
                  Array.from(new Set(WardRooms.filter(p => p.Status === 'Active').map(p => p.WardType))).map((wardType, index) => (
                    <option key={index} value={wardType}>{wardType}</option>
                  ))
                }
              </select>
            </div>
            <div className="RegisForm_1">
              <label> Room Type <span>:</span> </label>

              <select
                name='RoomType'
                required
                value={PackageType.RoomType}
                onChange={(e) => setPackageType((prev) => ({ ...prev, RoomType: e.target.value }))}
              >
                <option value=''>Select</option>
                
                
                {
                  Array.from(new Set(WardRooms.filter(p => p.Status === 'Active' && p.WardType === PackageType.WardType).map(p => p.RoomType))).map((RoomType, index) => (
                    <option key={index} value={RoomType}>{RoomType}</option>
                  ))
                }
              </select>
            </div>



          </div>
          <div className="RegisFormcon ">
            <div className="RegisForm_1">
              <label> Package Days <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Package Days'
                name='Days'
                required
                value={PackageType.Days}
                onChange={(e) => setPackageType((prev) => ({ ...prev, Days: e.target.value }))}
              />
            </div>
            <div className="RegisForm_1">
              <label> Charge <span>:</span> </label>
              <input
                type="number"
                placeholder='Enter the Charge'
                name='Charge'
                required
                value={PackageType.Charge}
                onChange={(e) => setPackageType((prev) => ({ ...prev, Charge: e.target.value }))}
              />
            </div>
            <div className="RegisForm_1">
              <label> Location <span>:</span> </label>

              <select
                name='Location'
                required
                value={PackageType.Location}
                onChange={(e) => setPackageType((prev) => ({ ...prev, Location: e.target.value }))}
              >
                <option value=''>Select</option>
                {
                  LocationData.map((p, index) => (
                    <option key={index} value={p.location_name}>{p.location_name}</option>
                  ))
                }
              </select>
            </div>



          </div>
          <div className="Register_btn_con">
            <button className='RegisterForm_1_btns' onClick={handlePackageType}>{IsPackageEdit ? "Update" : "Add"}</button>
          </div>
          <div className="RateCard_table">
            <div className="RateCard_table_1">
              <table>
                <thead>
                  <tr>
                    <th>S_No</th>
                    <th>Ward Type</th>
                    <th>Room Type</th>
                    <th>Package Days</th>
                    <th>Charge</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Packages.map((client, index) => (
                    <tr key={index}>
                      <td>{client.PackageId}</td>
                      <td>{client.WardType}</td>
                      <td>{client.RoomType}</td>
                      <td>{client.Days}</td>
                      <td>{client.Charge}</td>
                      <td>{client.Location}</td>
                      <td>
                        <button onClick={() => handlePackageClick(client)} className='Addnamebtn_pt2'>
                          {client.Status}
                        </button>
                      </td>
                      <td>
                        <button onClick={() => handlePackageEdit(client)} className='ratecarededitbutton'>
                          <EditIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )

}
export default RoomMaster;