import {combineReducers} from 'redux';

import userRecorddata from './UserrecordStore';
import FinanceStore from './FinanceStore';
import IpManagement from './IpManagement';

const Rootreducer = combineReducers({
    userRecord : userRecorddata,
    FinanceStore: FinanceStore,
    InPatients :IpManagement
});
export default Rootreducer;