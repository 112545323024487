import React, { useState, useEffect } from "react";
import AddCardIcon from "@mui/icons-material/AddCard";
import "./Billing.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
// import "../AppointmentManagement/ReqRegis.css";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
// import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Barcode from "react-barcode";
import { useDispatch } from "react-redux";
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import { useSelector } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";
import { toPng } from 'html-to-image';
import QRCode from 'qrcode.react';


function Billing() {


  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [pdfBlob, setPdfBlob] = useState(null);
  const [preview, setpreview] = useState(false);
  const dispatchvalue = useDispatch();
  const [patientbarcode, setpatientbarcode] = useState('');
  const [type, setType] = useState("PatientDetailCon");
  const [testname, setTestName] = useState([]);
  const [visitid, setvisitid] = useState("");
  const [patientid, setpatientid] = useState("");
  const [testdetails, settestdetails] = useState([]);
  const handleToggleChange = (event) => {
    const newValue = event.target.value;
    setType(newValue);
  };
  const urllink = useSelector(state => state.userRecord?.UrlLink)
  const [invoiceno, setInvoiceno] = useState();
  const [barcode, setbarcode] = useState();
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  const [rural, setrural] = useState([]);
  const [commissionper, setcommissionper] = useState();

  const [patientdetails, setPatientDetails] = useState({
    visitid: "",
    visittype: "",
    title: "",
    patientname: "",
    fathername: "",
    dob: "",
    age: "",
    gender: "",
    maritalstatus: "",
    phlebotomisttype: "",
    phlebotomistname: "",
    Referingdoctor: "",
    Patientgroup: "",
    Doorno: "",
    Rural: "",
    Pincode: "",
    Street: "",
    // District: "",
    City: "",
    State: "",
    PhoneNo: "",
    Alternatephn: "",
    Email: "",
    Dispatchmethod: "",
    testmethod: "",
    OutsourceType: "",
    OutsourceLab: ""
  });
  const [trfpicture, settrfpicture] = useState(null)
  const [isEdit, setIsEdit] = useState(null);
  const [doctorname, setdoctorname] = useState([])
  const [Billing_date, setBilling_date] = useState('');
  const [totalPaidAmount, settotalPaidAmount] = useState(0);
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState('')
  const [formAmount, setFormAmount] = useState([]);

  const [paymentdetail, setpaymentdetail] = useState({
    paidamount: '',
    Billpay_method: '',

  })
  const [patientname, setpatientname] = useState([]);
  const [patientdata, setpatientdata] = useState([]);
  const [initialState, setinitialState] = useState({
    totalItems: 0,
    totalAmount: 0,
    totalDiscount: 0,
    totalGstamount: 0,
    totalUnits: 0,
    totalNetAmount: 0,
    totalrecived: 0
  });

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: '',
    ClinicGST: '',
    ClinicAddress: '',
    ClinicCity: '',
    ClinicState: '',
    ClinicCode: '',
    ClinicMobileNo: '',
    ClinicLandLineNo: '',
    ClinicMailID: '',
    InvoiceNo: ''
  });
  const [orderdetails, setorderdetails] = useState({
    testname: "",
    grossAmount: 0,
    discountType: "", // "Cash" or "Percentage"
    discountValue: "",
    paymentMethod: "",
    receivedAmount: 0,
    netAmount: 0,
    dueAmount: 0,
    CardName: '',
    CardNumber: '',
    TransactionId: '',
    UPI_Mob: '',
    CardType: ''
  });




  const handleinputchangepay = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    const total_netamount = initialState.totalNetAmount;
    const totalPaidAmount = formAmount.reduce((acc, val) => acc + Number(val.paidamount), 0);

    if (+totalPaidAmount !== +total_netamount) {
      if (name === "Billpay_method") {
        setpaymentdetail((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        if (formAmount.length > 0) {
          const remainingAmount = parseFloat(total_netamount) - totalPaidAmount;
          if (+remainingAmount >= +value) {
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: value,
            }));
          } else {
            alert(`Enter a value less than or equal to ${remainingAmount}`);
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: "",
            }));
          }
        } else {
          if (+total_netamount >= +value) {
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: value,
            }));
          } else {
            alert(`Enter a value less than or equal to ${total_netamount}`);
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: "",
            }));
          }
        }
      }
    } else {
      alert("No Balance Amount");
    }
  };



  const handleInputChange1 = (event) => {
    const { name, value } = event.target;

    if (name === "discountValue") {
      // If updating discountValue
      const isDiscountValueEntered = !!value.trim(); // Check if discountValue is not empty
      setorderdetails((prevState) => ({
        ...prevState,
        [name]: value,
        isDiscountValueEntered: isDiscountValueEntered,
      }));

      if (isDiscountValueEntered) {
        // If discountValue is entered, enforce mandatory requirement for discountType
        if (!orderdetails.discountType.trim()) {
          // If discountType is not selected
          alert("Please select a discount type.");
          setorderdetails(prev => ({
            ...prev,
            discountValue: ''
          }))
        }
      }
    } else {
      // If updating other fields
      setorderdetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const numberToWords = (number) => {
    let num = parseInt(number.toString().split('.')[0])
    if (num === 0) {
      return 'Zero Rupees Only';
    }

    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convert = (num) => {
      if (num <= 10 && num !== 0) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
      if (num < 1000) return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' and ' + convert(num % 100) : '');
      if (num < 100000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' and ' + convert(num % 1000) : '');
      if (num < 10000000) return convert(Math.floor(num / 100000)) + ' Lakh' + (num % 100000 !== 0 ? ' and ' + convert(num % 100000) : '');

    };

    return convert(num) + ' Rupees  Only';
  };

  // console.log("invoiceno", initialState);

  const handleAdd = () => {
    const requiredFields = ["Billpay_method", "paidamount"];
    const missingFields = requiredFields.filter((field) => !paymentdetail[field]);

    if (missingFields.length === 0) {
      const existingMethod = formAmount.find((payment) => payment.Billpay_method === paymentdetail.Billpay_method);

      if (!existingMethod) {
        setFormAmount((prev) => [...prev, paymentdetail]);
        resetPaymentDetail();
      } else {
        alert("The Payment Method already exists");
      }
    } else {
      alert(`Enter the required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleEdit = (index) => {
    setIsEdit(index);
    const item = formAmount[index];
    const editamount = item.paidamount;
    // console.log('item', editamount);

    setpaymentdetail({
      ...item,
    });

    setorderdetails(prev => ({
      ...prev,
      dueAmount: prev.dueAmount + editamount, // Corrected line
    }));
  };


  const handleUpdate = () => {
    const req = ["Billpay_method", "paidamount"];
    const missing = req.filter((row) => ![row]);
    if (missing.length === 0) {
      const data = [...formAmount];
      data[isEdit] = paymentdetail;

      setFormAmount(data);
      setpaymentdetail({
        paidamount: "",
        Billpay_method: "",
      });
      setIsEdit(null);
    } else {
      alert(`enter the required fields : ${missing.join(",")}`);
    }
  };

  const resetPaymentDetail = () => {
    setpaymentdetail({
      paidamount: "",
      Billpay_method: "",
    });
  };

  const franchaise = [
    'Basic Price',
    'Special Price',
    'Tamil',
    'Bharathi',
    'Jeeva',
    'Mohan',
    'Vishva',
    'fran1',
    'fran2',
    'fran3',
    'fran4',
    'fran5',
    'fran6',
    'fran7',
    'fran8',
    'fran9',
    'fran10',
    'fran11',
    'fran12',
    'fran13',
    'fran14',
    'fran15',
    'fran16',
    'fran17',
    'fran18',
    'fran19',
    'fran20',
    'fran21',
    'fran22',
    'fran23',
    'fran24',
    'fran25',
    'fran26',
    'fran27',
    'fran28',
    'fran29',
    'fran30',
    'fran31',
    'fran32',
    'fran33',
    'fran34',
    'fran35',
    'fran36',
    'fran37',
    'fran38',
    'fran39',
    'fran40',
    'fran41',
    'fran42',
    'fran43',
    'fran44',
    'fran45'
  ]

  const handleSearch = () => {
    if (patientdetails.patientname && orderdetails.testname !== "" && patientdetails.Patientgroup !== '') {


      if (patientdetails.testmethod === "Individual" || patientdetails.testmethod === "Group") {
        axios
          .get(
            patientdetails.testmethod === "Individual" ?
              `${urllink}usercontrol/getcostpertest?testname=${orderdetails.testname}&ratecard=${patientdetails.Patientgroup}` :
              `${urllink}usercontrol/getgroupcost?testname=${orderdetails.testname}`
          )
          .then((response) => {
            // console.log('response.data', response.data);  // For debugging

            let newData = [];

            if (
              response.data &&
              !Array.isArray(response.data) &&
              response.data.test_code
            ) {
              newData = [response.data];
            } else if (Array.isArray(response.data)) {
              newData = response.data;
            }

            // Check if newData already exists in testdetails
            const alreadyExists = newData.some((newItem) =>
              testdetails.some((existingItem) => existingItem.test_name === newItem.test_name)
            );

            if (alreadyExists) {
              alert("Data already exists in testdetails");
              return; // Don't proceed further if data already exists
            } else {
              // Update the state with the new test names
              settestdetails((prevData) => [...prevData, ...newData]);
              // console.log('newData', newData);

              // Accumulate total cost
              const totalCost = newData.reduce(
                (acc, curr) => acc + Number(curr.cost_per_reportable),
                0
              );

              // Update orderdetails with accumulated total cost
              setorderdetails((prevState) => ({
                ...prevState,
                grossAmount: Number(prevState.grossAmount || 0) + totalCost,
              }));

              setorderdetails((prev) => ({
                ...prev,
                testname: ""
              }))

              // console.log('orderdetails', orderdetails);
            }
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });


      }
    } else {
      alert("Please enter patient details");
    }
  };


  // console.log('orderdetails', orderdetails);

  // console.log('testdetails', testdetails)
  useEffect(() => {
    const currentDate = new Date();
    setBilling_date(format(currentDate, 'dd-MM-yyyy'))
    if (patientdetails.gender !== '') {
      axios
        .get(`${urllink}usercontrol/getalltestname?gender=${patientdetails.gender}`)
        .then((response) => {
          // console.log(response.data);
          const data = response.data;
          setTestName(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    axios.get(`${urllink}usercontrol/get_ref_doctorname`)
      .then((response) => {
        // console.log('response.data', response.data)
        const data = response.data
        setdoctorname(data)
      })
      .catch((error) => {
        console.error(error)
      })

    axios
      .get(`${urllink}Billing/getinvoice`)
      .then((response) => {
        // console.log(response.data);
        const data = response.data.nextInvoiceCode;

        setInvoiceno(data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`${urllink}Billing/getbarcode`)
      .then((response) => {
        // console.log(response.data);
        const data = response.data.nextInvoiceBarcode;
        setbarcode(data);
        // setInvoiceno(data);
      })
      .catch((error) => {
        console.error(error);
      });

    if (patientdetails.patientname) {
      axios
        .get(
          `${urllink}Billing/getpatientid?patientname=${patientdetails.patientname}&patientphn=${patientdetails.PhoneNo}`
        )
        .then((response) => {
          // console.log(response.data);
          const data = response.data.nextPatientId;
          setpatientid(data);
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${urllink}Billing/getforvisitid?patientphn=${patientdetails.PhoneNo}&patientname=${patientdetails.patientname}`
        )
        .then((response) => {
          // console.log(response.data);
          const data = response.data.nextVisitId;
          setvisitid(data);
        })
        .catch((error) => {
          console.error(error);
        });

      axios.get(`${urllink}Billing/get_patient_barcode`)
        .then((response) => {
          // console.log(response)
          const data = response.data.patientbarcode
          setpatientbarcode(data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [patientdetails.patientname, patientdetails.PhoneNo, urllink]);


  //  useeffect for get clinic details

  useEffect(() => {
    axios
      .get(
        `${urllink}usercontrol/getClinic?location=${userRecord?.location}`
      )
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        // console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.door_no + "," + data.area + "," + data.street,
            ClinicGST: data.Gst_no,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phone_no,
            ClinicLandLineNo: data.landline_no,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });


    if (patientdetails.patientname) {
      axios.get(`${urllink}Billing/get_billing_patient_barcode?Patientid=${patientid}&Patientname=${patientdetails.patientname}`)
        .then((response) => {
          // console.log(response)
          setpatinetbillingbarcode(response.data.Patient_Barcode)
        })
        .catch((error) => {
          console.error(error)
        });


    }

  }, [patientid, patientdetails.patientname])



  const handleDeleted = (testname) => {

    // Find the item to be deleted
    const deletedItem = testdetails.find((p) => p.test_name === testname);

    if (!deletedItem) {
      console.error(`Item with test name ${testname} not found.`);
      return;
    } else {
      const reductionAmount = Number(deletedItem.cost_per_reportable || 0);
      const updatedTestDetails = testdetails.filter((p) => p.test_name !== testname);
      // console.log('updated testdetails', updatedTestDetails);
      settestdetails(updatedTestDetails);


      setorderdetails((prevState) => ({
        ...prevState,
        grossAmount: Number(prevState.grossAmount || 0) - reductionAmount,
      }));
    }
  };



  // console.log('testdetails', testdetails);


  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);

    let updatedValue = value;


    if (name === "PhoneNo" && value.length > 10) {
      updatedValue = value.slice(0, 10);
      // console.log("Phone number exceeds 10 characters");
    }
    else if (name === "Alternatephn" && value.length > 10) {
      updatedValue = value.slice(0, 10);
      // console.log("Phone number exceeds 10 characters");
    }

    let updatedDetails = {
      ...patientdetails,
      [name]: updatedValue,
    };

    if (name === "title") {
      const genderValue =
        value.toLowerCase() === "mr"
          ? "Male"
          : value.toLowerCase() === "mrs" || value.toLowerCase() === "ms"
            ? "Female"
            : "Other";
      updatedDetails = { ...updatedDetails, gender: genderValue };
    }

    if (name === "dob") {
      const age = calculateAge(value);
      updatedDetails = { ...updatedDetails, age: age.toString() }; // Assuming age is stored as a string
    }


    setPatientDetails(updatedDetails);
  };


  // console.log('ClinicDetials',ClinicDetials)

  useEffect(() => {

    const parsedGrossAmount = parseFloat(orderdetails.grossAmount || 0); // Ensure parsing and handle undefined
    const parsedDiscountValue = parseFloat(orderdetails.discountValue || 0); // Ensure parsing and handle undefined

    let discount = 0;
    if (orderdetails.discountType === "Cash") {
      discount = parsedDiscountValue;
    } else if (orderdetails.discountType === "Percentage") {
      discount = (parsedDiscountValue / 100) * parsedGrossAmount;
    }

    const netAmount = parsedGrossAmount - discount;

    // Check if discount is greater than netAmount
    if (discount > parsedGrossAmount) {
      alert("Discount cannot exceed the gross amount.");
      setorderdetails(prev => ({
        ...prev,
        discountValue: ''
      }))
      return; // Do not update state if discount is invalid
    }

    setorderdetails((prevState) => ({
      ...prevState,
      netAmount: Number(netAmount.toFixed(2)), // Ensure parsing
    }));
    setinitialState((prev) => ({
      ...prev,
      totalDiscount: Number(discount.toFixed(2)), // Ensure parsing
      totalNetAmount: Number(netAmount.toFixed(2)), // Ensure parsing
    }));

  }, [orderdetails.discountType, orderdetails.discountValue, orderdetails.grossAmount, orderdetails.testname])








  useEffect(() => {
    const phonenum = patientdetails.PhoneNo;

    if (phonenum !== '') {
      const time = setTimeout(() => {
        axios
          .get(`${urllink}Billing/getforpatientinfo?phonenum=${phonenum}`)
          .then((response) => {
            console.log(response.data);
            const data = response.data.patient_infos;
            const patient = response.data.patients;
            setpatientname(patient);
            setpatientdata(data);
            const error = response.data.error;


          })
          .catch((error) => {
            console.log(error);
          });
      }, 300); // Adjust delay as needed

      // Cleanup timeout on component unmount or dependency change
      return () => clearTimeout(time);
    }
  }, [patientdetails.PhoneNo, urllink]);

  useEffect(() => {
    if (patientdata) {


      const patientdaa = patientdata.filter((p) => p.Patient_Name === patientdetails.patientname)
        .forEach((item) => {
          setPatientDetails((prevState) => ({
            ...prevState,
            patientname: item.Patient_Name || "",
            fathername: item.Father_Name || "",
            dob: item.DOB || "",
            age: item.Age ? item.Age.toString() : "", // Making sure age is a string if it's being used in a text input
            gender: item.Gender || "",
            maritalstatus: item.Marital_Status || "",
            Doorno: item.Door_No || "",
            Street: item.Street || "",
            State: item.State || "",
            PhoneNo: patientdetails.PhoneNo, // Assuming you want to keep the searched phone number
            Alternatephn: item.Alternate_No || "",
            Email: item.Email || "",
            title: item.Title || "",
            City: item.City || "",
            Rural: item.Rural_Urban || "",
            Pincode: item.Pincode || "" // Add other fields if necessary
          }));
        });
      // console.log('patientdaa............................', patientdaa)
    }
  }, [patientdata, patientdetails.patientname, patientdetails.PhoneNo]);


  // console.log('patientdetails................', patientdata);


  const handlePrint = useReactToPrint({
    content: () => {
      const printContent = document.getElementById('reactprintcontent');
      if (printContent) {
        return printContent;
      } else {
        console.error('Print content not found');
        return null;
      }
    },
    onBeforePrint: () => {
      // Perform actions before print
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      const printdata = document.getElementById('reactprintcontent');

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20;
          const pdfWidth = contentWidth + 2 * padding;
          const pdfHeight = contentWidth * 1.5;
          const pdf = new jsPDF({
            unit: 'px',
            format: [pdfWidth, pdfHeight],
          });

          const barcodeImagecon1 = await toPng(printdata.querySelector('#get_imagecontent_1'));
          const barcodeImagecon2 = await toPng(printdata.querySelector('#get_imagecontent_2'));

          const barcodeImage1 = await new Promise((resolve, reject) => {
            const barcodeImg1 = new Image();
            barcodeImg1.onload = () => resolve(barcodeImg1);
            barcodeImg1.onerror = reject;
            barcodeImg1.src = barcodeImagecon1;
          });

          const barcodeImage2 = await new Promise((resolve, reject) => {
            const barcodeImg2 = new Image();
            barcodeImg2.onload = () => resolve(barcodeImg2);
            barcodeImg2.onerror = reject;
            barcodeImg2.src = barcodeImagecon2;
          });

          // Append barcode images to their respective containers
          const barcodeContainer1 = printdata.querySelector('#get_imagecontent_1');
          const barcodeContainer2 = printdata.querySelector('#get_imagecontent_2');
          barcodeContainer1.innerHTML = '';
          barcodeContainer2.innerHTML = '';
          barcodeContainer1.appendChild(barcodeImage1);
          barcodeContainer2.appendChild(barcodeImage2);

          // Generate PDF
          pdf.html(printdata, {
            x: padding,
            y: padding,
            callback: async () => {
              const generatedPdfBlob = pdf.output('blob');
              setPdfBlob(generatedPdfBlob);

              const postpersonaldata = {
                visitid: visitid,
                visittype: patientdetails.visittype,
                title: patientdetails.title,
                patientname: patientdetails.patientname,
                fathername: patientdetails.fathername,
                dob: patientdetails.dob,
                age: patientdetails.age,
                gender: patientdetails.gender,
                maritalstatus: patientdetails.maritalstatus,
                Doorno: patientdetails.Doorno,
                Street: patientdetails.Street,
                State: patientdetails.State,
                City: patientdetails.City,
                rural: patientdetails.Rural,
                pincode: patientdetails.Pincode,
                PhoneNo: patientdetails.PhoneNo,
                Alternatephn: patientdetails.Alternatephn,
                Email: patientdetails.Email,
                phlebotomisttype: patientdetails.phlebotomisttype,
                phlebotomistname: patientdetails.phlebotomistname,
                Referingdoctor: patientdetails.Referingdoctor,
                Patientgroup: patientdetails.Patientgroup,
                Dispatchmethod: patientdetails.Dispatchmethod,
                Location: userRecord?.location,
                CreatedBy: userRecord?.username,
                patientbarcode: patientbarcode,
                patientid: patientid,
                Billing_Type: patientdetails.OutsourceType
              };

              const postbillingdetails = {
                ...orderdetails,
                invoiceno: invoiceno,
                barcode: barcode,
                itemcount: testdetails.length,
                patientid: patientid,
                visitid: visitid,
                patientname: patientdetails.patientname,
                Dispatchmethod: patientdetails.Dispatchmethod,
                location: userRecord?.location,
                CreatedBy: userRecord?.username,
                outsource_type: patientdetails.Patientgroup,
                refering_doctor: patientdetails.Referingdoctor,
                Billing_Type: patientdetails.OutsourceType
              };

              const postorderdetailsforpayment = {
                payment_details: formAmount,
                invoiceno: invoiceno,
                barcode: barcode,
                patientid: patientid,
                visitid: visitid,
                patientname: patientdetails.patientname,
                Dispatchmethod: patientdetails.Dispatchmethod,
                receivedAmount: orderdetails.receivedAmount,
                netAmount: orderdetails.netAmount,
                cardnum: orderdetails.CardNumber,
                cardname: orderdetails.CardName,
                cardtype: orderdetails.CardType,
                transactionid: orderdetails.TransactionId,
                onlinenum: orderdetails.UPI_Mob,
                location: userRecord?.location,
                CreatedBy: userRecord?.username,
              };

              const postorderdetailsinfo = {
                testdetails,
                invoiceno: invoiceno,
                patientid: patientid,
                barcode: barcode,
                visitid: visitid,
                testmethod: patientdetails.testmethod,
                patientname: patientdetails.patientname,
                Dispatchmethod: patientdetails.Dispatchmethod,
                location: userRecord?.location,
                CreatedBy: userRecord?.username,
              };

              const postcommissionfordoctor = {
                doctorname: patientdetails.Referingdoctor,
                billinginvoice: invoiceno,
                visitid: visitid,
                commissionper: commissionper,
                netAmount: orderdetails.netAmount,
                billingdate: new Date().toLocaleDateString(),
                patientname: patientdetails.patientname,
                location: userRecord?.location,
              };

              const formData = new FormData();
              formData.append("pdf", new Blob([generatedPdfBlob], { type: "application/pdf" }), "Billrecipt.pdf");
              formData.append("invoice", invoiceno);
              formData.append("Patientbarcode", patinetbillingbarcode);
              formData.append("Visitid", visitid);
              formData.append("location", userRecord?.location);
              formData.append("createdby", userRecord?.username);

              const postPromises = [
                axios.post(`${urllink}Billing/inertbillinginfo`, postorderdetailsinfo),
                axios.post(`${urllink}Billing/inserpatientprofile`, postpersonaldata),
                axios.post(`${urllink}Billing/insertvisitid`, postpersonaldata),
                axios.post(`${urllink}Billing/insertbillinginvoice`, postbillingdetails),
                axios.post(`${urllink}Billing/insertpaymentdetails`, postorderdetailsforpayment),
                axios.post(`${urllink}Billing/insert_billing_receipts`, formData),
                axios.post(`${urllink}Billing/insert_into_outsourced_details`, postbillingdetails)
              ];

              if (doctorname.includes(patientdetails.Referingdoctor)) {
                postPromises.push(
                  axios.post(`${urllink}Billing/insert_doctor_payment_commission`, postcommissionfordoctor)
                );
              }

              Promise.all(postPromises)
                .then((responses) => {
                  responses.forEach((response) => {
                    console.log(response.data);
                  });

                  setType('PatientDetailCon');
                  setTimeout(cleardataafterpost, 500);
                })
                .catch((error) => {
                  console.error(error);
                });

              setIsPrintButtonVisible(true);
            }
          });
        } else {
          throw new Error('Unable to get the target element');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
  });

  const handleSubmit = () => {
    const requiredFields = [
      "visittype",
      "title",
      "patientname",     
      "age",
      "gender",
    ];

    const isFormValid = requiredFields.every(
      (field) => patientdetails[field].trim() !== ""
    );

    if (!isFormValid) {
      alert("Please fill all required fields.");
      return;
    }
    setIsPrintButtonVisible(false);

    setTimeout(() => {
      handlePrint();
    }, 800);
  };


  const cleardataafterpost = () => {
    setPatientDetails({
      visitid: "",
      visittype: "",
      title: "",
      patientname: "",
      fathername: "",
      dob: "",
      age: "",
      gender: "",
      maritalstatus: "",
      phlebotomisttype: "",
      phlebotomistname: "",
      Referingdoctor: "",
      Patientgroup: "",
      Doorno: "",
      Rural: "",
      Pincode: "",
      Street: "",
      // District: "",
      City: "",
      State: "",
      PhoneNo: "",
      Alternatephn: "",
      Email: "",
      Dispatchmethod: "",
      testmethod: "",
    });

    setorderdetails({
      testname: "",
      grossAmount: 0,
      discountType: "",
      discountValue: "",
      paymentMethod: "",
      receivedAmount: "",
      netAmount: 0,
      dueAmount: 0,
    });
    settestdetails([]);
    setvisitid('');
    setFormAmount([]);
  };


  useEffect(() => {
    const pincode = patientdetails.Pincode;

    if (patientdetails.Pincode !== "") {
      const time = setTimeout(() => {
        axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
          .then((response) => {
            // console.log(response);
            const ruraldata = response.data[0].PostOffice;
            const State = response.data[0].PostOffice[0].State;
            // const City = response.data[0].PostOffice[0].Block;
            const District = response.data[0].PostOffice[0].District
            // console.log('ruraldata', ruraldata);
            setrural(ruraldata);

            setPatientDetails(prev => ({
              ...prev,
              City: District,
              State: State,
            }))
          })
          .catch((error) => {
            console.error(error);
          });
      }, 200);

      return () => {
        clearTimeout(time);
      };
    }
  }, [patientdetails.Pincode]);


  // console.log('userRecord', userRecord)




  const handleBillingModeChange = (event) => {
    const { value } = event.target;
    setPatientDetails({ ...patientdetails, testmethod: value });

    if (value === "Individual") {
      axios
        .get(`${urllink}usercontrol/getalltestname?gender=${patientdetails.gender}`)
        .then((response) => {
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (value === "Group") {

      if (patientdetails.gender !== '') {
        axios
          .get(`${urllink}usercontrol/getallgroupname?gender=${patientdetails.gender}`)
          .then((response) => {
            // console.log(response.data)
            setTestName(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

  };


  useEffect(() => {
    const totalPaidAmount = formAmount.reduce(
      (total, ele) => +total + +ele.paidamount,
      0
    );

    const pendingamount = initialState.totalNetAmount - totalPaidAmount
    settotalPaidAmount(totalPaidAmount);
    // const totalnet = initialState.totalNetAmount
    setorderdetails(prev => ({
      ...prev,
      receivedAmount: totalPaidAmount,
      dueAmount: pendingamount,
    }))
    // Remainingamount(formAmount)
  }, [formAmount, paymentdetail.paidamount, initialState]);

  useEffect(() => {
    if (patientdetails.Referingdoctor !== "") {
      const time = setTimeout(() => {
        axios.get(`${urllink}usercontrol/get_for_indivitual_doctor_commission?Referingdoctor=${patientdetails.Referingdoctor}`)
          .then((response) => {
            // console.log(response)
            const data = response.data.amount;
            setcommissionper(data)
          })
          .catch((error) => {
            console.error(error)
          })
      }, 500);
      return () => {
        clearTimeout(time);
      };
    }

  }, [patientdetails.Referingdoctor])

  // console.log('commissionper...............=>', commissionper)

  const forPrintData = () => {
    return (
      <div className="billing-invoice santhu_billing" id="reactprintcontent">
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            <span>{ClinicDetials.ClinicName}</span>
            <div>

              <span >{ClinicDetials.ClinicAddress},</span>
              <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
              <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>

          </div>

        </div>
        <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
          Billing Invoice
        </div>


        <div className="new_billing_address">
          <div className="new_billing_address_2">
            <div className='Register_btn_con_barcode' id="get_imagecontent_1">
              <Barcode value={patinetbillingbarcode || ''} lineColor="black" height={40} width={1.2} fontSize={12} />
            </div>
            <div className="new_billing_div">
              <label>Patient Name <span>:</span></label>
              <span>{patientdetails.patientname}</span>
            </div>
            <div className="new_billing_div">
              <label>Visit ID <span>:</span></label>
              <span>{visitid}</span>
            </div>
            <div className="new_billing_div">
              <label>Age <span>:</span></label>
              <span>{patientdetails.age}</span>
            </div>
            <div className="new_billing_div">
              <label>Gender <span>:</span></label>
              <span>{patientdetails.gender}</span>
            </div>
            <div className="new_billing_div">
              <label>Address <span>:</span></label>
              <span>{patientdetails.City}</span>
            </div>

          </div>

          <div className="new_billing_address_2">
            <div className='Register_btn_con_barcode' id="get_imagecontent_2">
              <Barcode value={barcode || ''} lineColor="black" height={40} width={1.2} fontSize={12} />
            </div>
            <div className="new_billing_div">
              <label>Invoice No <span>:</span></label>
              <span>{invoiceno}</span>
            </div>
            <div className="new_billing_div">
              <label>GSTIN No <span>:</span></label>
              <span>{ClinicDetials.ClinicGST}</span>
            </div>
            <div className="new_billing_div">
              <label>Physician Name <span>:</span></label>
              <span>{patientdetails.Referingdoctor}</span>
            </div>
            <div className="new_billing_div">
              <label>Date <span>:</span></label>
              <span>{Billing_date}</span>
            </div>
            <div className="new_billing_div">
              <label> Patient Mobile No <span>:</span></label>
              <span>{patientdetails.PhoneNo}</span>
            </div>
          </div>
        </div>


        <div className="new_billing_invoice_detials">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Test Method</th>
                <th>Service Name</th>

                <th>Amount</th>

              </tr>
            </thead>
            <tbody>

              {testdetails.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>{" "}
                  <td>{row.test_code}</td>
                  <td>{row.test_name}</td>

                  <td>{row.cost_per_reportable}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="new_billing_invoice_detials " style={{ paddingBottom: '10px', height: 'auto' }}>

          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div className="bill_body">
                <label> Items Count <span>:</span></label>
                <span>{testdetails.length}</span>
              </div>
              {/* <div className="bill_body">
                            <label> Unit <span>:</span></label>
                            <span>{orderdetails.totalUnits}</span>
                        </div> */}
              <div className="bill_body">
                <label> Amount <span>:</span></label>
                <span>{orderdetails.grossAmount}</span>
              </div>
              <div className="bill_body">
                <label> Discount <span>:</span></label>
                <span>{initialState.totalDiscount}</span>
              </div>
              <div className="bill_body">
                <label> Billed By <span>:</span></label>
                <span>{userRecord?.username}</span>
              </div>
            </div>
            <div className="total_con_bill">

              <div className="bill_body">
                <label>Net Amount <span>:</span></label>
                <span>{orderdetails.netAmount}</span>
              </div>
              {formAmount.map((row, index) => (
                <div key={index} className="bill_body">
                  {/* <div className="item-index">{index + 1}</div> */}
                  <label>
                    {row.Billpay_method}
                    <span>:</span>
                  </label>
                  <span>{row.paidamount}</span>
                </div>
              ))}
              <div className="bill_body">
                <label> Recived <span>:</span></label>
                <span>{(orderdetails.receivedAmount)} </span>
              </div>


            </div>

          </div>
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className='total_con_bill'>
              <div className="bill_body" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <label> Amount In Words<span>:</span></label>
                <span style={{ color: 'grey' }}>{numberToWords(+orderdetails.netAmount)} </span>
              </div>
            </div>
          </div>
          <div className='Register_btn_con_QRcode'>
            <QRCode
              value="http//localhost:3000/DispatchResult"
              size={106}
              level="H"
              bgColor="#ffffff"
              fgColor="#000000"
            />
          </div>

          <div className="signature-section909">
            <p className='disclaimer23'>
              This page is created automatically without a signature.
            </p>
          </div>
        </div>

      </div>
    );
  }





  return (

    <>

      {isPrintButtonVisible ? (
        <div className="Supplier_Master_form_Container">
          <div className="Supplier_Master_form_Container_Header">
            <h3>Billing </h3>
          </div>
          {/* <BarcodeDisplay patientDetails={patientDetails} barcode={barcode} /> */}

          <div className="Supplier_Master_form_sub_con">
            <div className="Supplier_Master_form_sub_con_div">
              <div className="Supplier_Master_form_sub_con_div_1">
                <ToggleButtonGroup
                  value={type}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    value="PatientDetailCon" // Set the value prop here
                    style={{
                      backgroundColor:
                        type === "PatientDetailCon"
                          ? "var(--ProjectColor)"
                          : "inherit",
                    }}
                    className="Supplier_Master_form_sub_con_div_1_toggle"
                  >
                    Patient Details
                  </ToggleButton>
                  <ToggleButton
                    value="CommunicationAddressCon" // Set the value prop here
                    style={{
                      backgroundColor:
                        type === "CommunicationAddressCon"
                          ? "var(--ProjectColor)"
                          : "inherit",
                    }}
                    className="Supplier_Master_form_sub_con_div_1_toggle"
                  >
                    Test Informations
                  </ToggleButton>
                </ToggleButtonGroup>

                {type === "PatientDetailCon" ? (
                  <div className="Supplier_Master_form_sub_con_div_1">
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Patient Name:
                        </label>
                        <div className="Supplier_Master_form_Con_div_Row_title_name">
                          <select
                            id="title"
                            name="title"
                            value={patientdetails.title}
                            onChange={handleInputChange}
                            className="Supplier_Master_form_Con_div_Row_inputtiltle"
                          >
                            <option value="">Title</option>
                            <option value="mr">Mr.</option>
                            <option value="ms">Ms.</option>
                            <option value="mrs">Mrs.</option>
                          </select>
                          <input
                            type="text"
                            name="patientname"
                            list="patientname"
                            // placeholder="Enter Patient Name"
                            value={patientdetails.patientname}
                            onChange={handleInputChange}
                            required
                            className="Supplier_Master_form_Con_div_Row_input11"
                          // style={{ width: "180px" }}
                          />
                          <datalist id="patientname">
                            {patientname?.map((item, index) => (
                              <option
                                key={index}
                                value={item}
                              >
                                {item}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          PhoneNo(WhatsApp) :
                        </label>

                        <input
                          type="number"
                          name="PhoneNo"
                          // placeholder="Enter Phone No"
                          value={patientdetails.PhoneNo}
                          onChange={handleInputChange}
                          maxLength={10}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />



                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="city"
                        >
                          Father's Name:
                        </label>
                        <input
                          type="text"
                          name="fathername"
                          // placeholder="Enter Father's Name"
                          value={patientdetails.fathername}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Visit Id:
                        </label>
                        <input
                          type="text"
                          name="visitid"
                          // placeholder="Enter Visit Id"
                          value={visitid}
                          required
                          readOnly
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          Age:
                        </label>
                        <input
                          type="number"
                          name="age"
                          // placeholder="Enter Age"
                          value={patientdetails.age}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="place"
                        >
                          Date Of Birth:
                        </label>
                        <input
                          type="Date"
                          name="dob"
                          value={patientdetails.dob}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">


                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="email"
                        >
                          Gender:
                        </label>
                        <input
                          type="gender"
                          name="gender"
                          // placeholder="Enter Gender"
                          value={patientdetails.gender}
                          onChange={handleInputChange}
                          readOnly
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Marital Status:
                        </label>
                        <select
                          id="phlebotomist"
                          name="maritalstatus"
                          value={patientdetails.maritalstatus}
                          onChange={handleInputChange}
                          className="Supplier_Master_form_Con_div_Row_input"
                        >
                          <option value="">Select</option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="country"
                        >
                          Door No :
                        </label>
                        <input
                          type="text"
                          name="Doorno"
                          // placeholder="Enter Door No"
                          value={patientdetails.Doorno}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Pincode :
                        </label>
                        <input
                          id="Pincode"
                          name="Pincode"
                          value={patientdetails.Pincode}
                          onChange={handleInputChange}
                          // placeholder="Enter your Address"
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">


                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Street Name :
                        </label>
                        <input
                          id="Street"
                          name="Street"
                          value={patientdetails.Street}
                          onChange={handleInputChange}
                          // placeholder="Enter your Address"
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Rural / Urban :
                        </label>
                        <input
                          id="Rural"
                          name="Rural"
                          list="browsers"
                          value={patientdetails.Rural}
                          onChange={handleInputChange}
                          // placeholder="Enter your Address"
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                        <datalist id="browsers">
                          {rural.map((item, index) => (
                            <option
                              key={index}
                              value={item.Name}
                            >
                              {item.Name}
                            </option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          City :
                        </label>
                        <input
                          type="text"
                          name="City"
                          // placeholder="Enter State"
                          value={patientdetails.City}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          State :
                        </label>
                        <input
                          type="text"
                          name="State"
                          // placeholder="Enter Alternate PhoneNo"
                          value={patientdetails.State}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>


                    </div>
                    <div className="Supplier_Master_form_Con_Row">

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          Email  :
                        </label>
                        <input
                          type="email"
                          name="Email"
                          // placeholder="Enter Email"
                          value={patientdetails.Email}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          Alternate Phone :
                        </label>
                        <input
                          type="text"
                          name="Alternatephn"
                          // placeholder="Enter Alternate PhoneNo"
                          value={patientdetails.Alternatephn}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>

                  </div>
                ) : (
                  <div className="Supplier_Master_form_sub_con_div_1">
                    <div className="Supplier_Master_form_Con_Row">

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Visit Type:
                        </label>
                        <select
                          id="visittype"
                          name="visittype"
                          className="Supplier_Master_form_Con_div_Row_input"
                          value={patientdetails.visittype}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          <option value="Walkin">Walk-in</option>
                          <option value="InHouse">In-House</option>
                          <option value="Outsource">Out-Source</option>
                          {/* <option value="others">Others</option> */}
                        </select>
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone1"
                        >
                          Phlebotomist Type:
                        </label>
                        <select
                          id="phlebotomist"
                          name="phlebotomisttype"
                          value={patientdetails.phlebotomisttype}
                          onChange={handleInputChange}
                          className="Supplier_Master_form_Con_div_Row_input"
                        >
                          <option value="">Select</option>
                          <option value="in-house">In-House</option>
                          <option value="pbt">Home-collection</option>
                          {/* <option value="others">Others.</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone1"
                        >
                          Phlebotomist Name:
                        </label>
                        <select
                          id="phlebotomist"
                          name="phlebotomistname"
                          value={patientdetails.phlebotomistname}
                          onChange={handleInputChange}
                          className="Supplier_Master_form_Con_div_Row_input"
                        >
                          <option value="">Select</option>
                          <option value="in">In-pilo.</option>
                          <option value="pbt">PBT-ONE.</option>
                          <option value="others">Others.</option>
                        </select>
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Refering Doctor:
                        </label>
                        <input
                          type="text"
                          name="Referingdoctor"
                          // placeholder="Enter Reference Doctor"
                          value={patientdetails.Referingdoctor}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">

                      {patientdetails.visittype !== 'Outsource' && <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Ratecard Type:
                        </label>
                        <input
                          id="Patientgroup"
                          name="Patientgroup"
                          list="Patientgroup1"
                          value={patientdetails.Patientgroup}
                          onChange={handleInputChange}
                          // placeholder="Enter your Address"
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                        <datalist id="Patientgroup1">
                          {franchaise?.map((item, index) => (
                            <option
                              key={index}
                              value={item}
                            >
                              {item}
                            </option>
                          ))}
                        </datalist>
                      </div>}
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Dispatch Method:
                        </label>
                        <select
                          id="dispatchMode"
                          name="Dispatchmethod"
                          value={patientdetails.Dispatchmethod}
                          onChange={handleInputChange}
                          className="Supplier_Master_form_Con_div_Row_input"
                        >
                          <option value="">Select</option>
                          <option value="sms">SMS</option>
                          <option value="wp">whatsapp</option>
                          <option value="email">EMAIL</option>
                          <option value="courier">Courier</option>
                        </select>
                      </div>

                      {patientdetails.visittype === 'Outsource' &&
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="phone2"
                          >
                            Outsource Type:
                          </label>
                          <select
                            id="OutsourceType"
                            name="OutsourceType"
                            value={patientdetails.OutsourceType}
                            onChange={handleInputChange}
                            className="Supplier_Master_form_Con_div_Row_input"
                          >
                            <option value="">Select</option>
                            <option value="Internal">Internal</option>
                            <option value="External">External</option>
                          </select>
                        </div>
                      }
                    </div>
                    {patientdetails.visittype === 'Outsource' &&
                      (
                        <div className="Supplier_Master_form_Con_Row">

                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="phone2"
                            >
                              Outsource Lab:
                            </label>
                            <input
                              id="Patientgroup"
                              name="Patientgroup"
                              list="Patientgroup"
                              value={patientdetails.Patientgroup}
                              onChange={handleInputChange}
                              // placeholder="Enter your Address"
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                            <datalist id="OutsourceLab">
                              {franchaise?.map((item, index) => (
                                <option
                                  key={index}
                                  value={item}
                                >
                                  {item}
                                </option>
                              ))}
                            </datalist>
                          </div>

                        </div>
                      )
                    }

                    <ToggleButton
                      value="orderdetails"
                      style={{
                        color: "black",
                        background: "var(--ProjectColor)",
                        font: "14px",
                      }}
                      className="Supplier_Master_form_sub_con_div_1_toggle"
                    >
                      Billing Informations
                    </ToggleButton>
                    <div className="Supplier_Master_form_sub_con_div_1">
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor=""
                          >
                            Billing Mode:
                          </label>
                          <select
                            id="testmethod"
                            name="testmethod"
                            className="Supplier_Master_form_Con_div_Row_input"
                            value={patientdetails.testmethod}
                            onChange={handleBillingModeChange}
                          >
                            <option value="">Select</option>
                            <option value="Individual">Individual</option>
                            <option value="Group">Group</option>
                          </select>
                        </div>
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="shortName"
                          >
                            Test Name:
                          </label>
                          <div className="Supplier_Master_form_Con_div_Row_title_name" >
                            {/* <Autocomplete
                              freeSolo
                              id="test-name-autocomplete"
                              disableClearable
                              // style={{ width: "220px" }}
                              className="auto_completed_solo"
                              name="testname"
                              onChange={handleInputChange12}
                              value={orderdetails.testname}
                              // className='Supplier_Master_form_Con_div_Row_input'
                              options={testname.map((option) => option)} // Map your testName array to the options prop
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                    className:
                                      "Supplier_Master_form_Con_div_Row_input_autocomplete",
                                  }}
                                />
                              )}
                            /> */}

                            <input
                              id="testname"
                              name="testname"
                              list="browsers1"
                              value={orderdetails.testname}
                              onChange={handleInputChange1}
                              // placeholder="Enter your Address"
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                            <datalist id="browsers1">
                              {testname?.map((item, index) => (
                                <option
                                  key={index}
                                  value={item}
                                >
                                  {item}
                                </option>
                              ))}
                            </datalist>
                            <button style={{ backgroundColor: "transparent", border: "none" }}>
                              <AddCardIcon onClick={handleSearch} />

                            </button>

                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                )}
              </div>
            </div>
            <div className="Supplier_Master_form_sub_con_div">
              <div className="Supplier_Master_form_sub_con_div_1">
                <ToggleButton
                  value="orderdetails"
                  style={{
                    color: "black",
                    background: "var(--ProjectColor)",
                    font: "14px",
                  }}
                  className="Supplier_Master_form_sub_con_div_1_toggle"
                >
                  Order Details
                </ToggleButton>

                <div className="Supplier_Master_form_sub_con_div_1">
                  <div
                    className="Selected-table-container"
                    style={{ marginBottom: "50px" }}
                  >
                    <table className="selected-medicine-table2 ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {testdetails?.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>{" "}
                            <td>{row.test_code}</td>
                            <td>{row.test_name}</td>
                            <td>{row.cost_per_reportable}</td>
                            <td>
                              <button onClick={() => handleDeleted(row.test_name)}>
                                <MdOutlineCancel />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="remarks"
                      >
                        Invoice No:
                      </label>
                      <input
                        type="text"
                        name="invoiceno"
                        placeholder=""
                        value={invoiceno}
                        onChange={handleInputChange1}
                        readOnly
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="remarks"
                      >
                        Gross Amount:
                      </label>
                      <input
                        type="text"
                        name="grossAmount"
                        placeholder=""
                        value={orderdetails.grossAmount}
                        onChange={handleInputChange1}
                        readOnly
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                  </div>
                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Discount Type:
                      </label>
                      <select
                        id="discountType"
                        name="discountType"
                        value={orderdetails.discountType}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        <option value="Percentage">Percentage</option>
                      </select>
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Discount:
                      </label>
                      <input
                        type="text"
                        name="discountValue"
                        // placeholder="Enter Discount"
                        value={orderdetails.discountValue}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                  </div>


                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="Billpay_method"
                      >
                        Payment Mode:
                      </label>
                      <select
                        id="dispatchMode"
                        name="Billpay_method"
                        value={paymentdetail.Billpay_method}
                        onChange={handleinputchangepay}
                        className="Supplier_Master_form_Con_div_Row_input"
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="OnlinePayment">Online Payment</option>
                      </select>
                    </div>


                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Received Amount:
                      </label>

                      <input
                        type="number"
                        name="paidamount"
                        // placeholder="Enter Received Amount"
                        value={paymentdetail.paidamount}
                        onChange={handleinputchangepay}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />


                    </div>
                  </div>
                  {paymentdetail.Billpay_method === 'Card' ?
                    (
                      <>
                        <div className="Supplier_Master_form_Con_Row">
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="CardName"
                            >
                              Card Type :
                            </label>
                            <select name="CardType" value={orderdetails.CardType} onChange={handleInputChange1}
                              className="Supplier_Master_form_Con_div_Row_input"
                            >
                              <option value="">Select</option>
                              <option value="Credit">Credit</option>
                              <option value="Debit">Debit</option>
                            </select>
                          </div>
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="CardNumber"
                            >
                              Card Number:
                            </label>
                            <input
                              type="text"
                              name="CardNumber"
                              // placeholder="Enter Discount"
                              value={orderdetails.CardNumber}
                              onChange={handleInputChange1}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>

                        </div>
                        <div className="Supplier_Master_form_Con_Row">

                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="CardName"
                            >
                              Card Name :
                            </label>
                            <input
                              type="text"
                              name="CardName"
                              // placeholder="Enter Discount"
                              value={orderdetails.CardName}
                              onChange={handleInputChange1}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>
                        </div>

                      </>

                    ) : null}
                  {paymentdetail.Billpay_method === 'OnlinePayment' &&
                    (
                      <>
                        <div className="Supplier_Master_form_Con_Row">
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="TransactionId"
                            >
                              OnlinePayment Number/ UPI Id :
                            </label>
                            <input
                              type="text"
                              name="UPI_Mob"
                              // placeholder="Enter Discount"
                              value={orderdetails.UPI_Mob}
                              onChange={handleInputChange1}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="TransactionId"
                            >
                              Transaction Id :
                            </label>
                            <input
                              type="text"
                              name="TransactionId"
                              // placeholder="Enter Discount"
                              value={orderdetails.TransactionId}
                              onChange={handleInputChange1}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>
                        </div>


                      </>

                    )}




                  <button
                    className="btncon_add prs-ad-sub-btn"
                    onClick={isEdit !== null ? handleUpdate : handleAdd}
                  >
                    {isEdit !== null ? "Update" : "Add"}
                  </button>

                  <div
                    className="Selected-table-container"
                    style={{ marginBottom: "50px" }}
                  >
                    <table className="selected-medicine-table2 ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th> Payment Method</th>
                          <th>Paid Amount</th>
                          {/* <th>Ordered Time</th>
                      <th>TAT Time</th> */}
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formAmount.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>{" "}
                            <td>{row.Billpay_method}</td>
                            <td>{row.paidamount}</td>
                            {/* <td>Ordered Time </td>{" "}
                        <td>TAT Time </td>{" "} */}
                            <td><button onClick={() => handleEdit(index)}><EditIcon /></button></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Net Amount:
                      </label>
                      <input
                        type="text"
                        name="netAmount"
                        placeholder="Enter Net Amount"
                        value={orderdetails.netAmount}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Due Amount:
                      </label>
                      <input
                        type="text"
                        name="dueAmount"
                        placeholder="Enter Balance Amount"
                        value={orderdetails.dueAmount}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Supplier_Master_form_Save">
            <button
              className="Supplier_Master_form_Save_button"
              onClick={handleSubmit}
            >
              Print
            </button>
          </div>
        </div>) : forPrintData()}
    </>
  );
}

export default Billing;
