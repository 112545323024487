import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Barcode from 'react-barcode';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { toPng } from 'html-to-image';
import { useNavigate } from 'react-router-dom';

export const BarcodePrint = () => {
  const [department, setDepartment] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const Printdata = useSelector((state) => state.userRecord?.PrintBarcode);
  const [PdfBlob, setPdfBlob] = useState(null);
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${urllink}Billing/getfor_barcode_details_print?invoice=${Printdata?.Billing_Invoice}`)
      .then((response) => {
        console.log(response);
        setDepartment(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [Printdata, urllink]);

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  };

  const currentDate = getCurrentDate();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('requestbarcode'),
    onBeforePrint: () => {
      console.log('Before');
      setShow(false);
    },
    onAfterPrint: async () => {
      console.log('After');
      const printdata = document.getElementById('requestbarcode');

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdfWidth = contentWidth + 2 * 5;
          const pdfHeight = contentHeight + 2 * 10;
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [pdfWidth, 1120],
          });

          const barcodeImagecon1 = await toPng(printdata.querySelector('#get_imagecontent_1'));

          const barcodeImage1 = await new Promise((resolve, reject) => {
            const barcodeImg1 = new Image();
            barcodeImg1.onload = () => resolve(barcodeImg1);
            barcodeImg1.onerror = reject;
            barcodeImg1.src = barcodeImagecon1;
          });

          const barcodeContainer1 = printdata.querySelector('#get_imagecontent_1');
          barcodeContainer1.innerHTML = ''; // Clear previous content
          barcodeContainer1.appendChild(barcodeImage1);

          pdf.html(printdata, {
            x: 5,
            y: 5,
            callback: () => {
              const generatedPdfBlob = pdf.output('blob');
              setPdfBlob(generatedPdfBlob);
              pdf.save('barcode.pdf');
              console.log('generatedPdfBlob', generatedPdfBlob);
              setShow(true);  // Re-show the button if needed
              navigate('/Home/PhelobotomistCapture');  // Navigate to the desired page after print
            },
          });
        } else {
          throw new Error('Unable to get the target element');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    },
  });

  return (
    <>
      <div className='parent_barcode_div' id='requestbarcode'>
        {department.map((dept, index) => (
          <div className="barcode_container_print_data" key={index}>
            <div className="barcode_patient">
              <p>{Printdata?.Patient_Name}</p>
              <p>{Printdata?.Age}</p>
            </div>
            <div className="" id='get_imagecontent_1'>
              <Barcode
                value={Printdata?.Barcode || ''}
                lineColor="black"
                height={40}
                width={1.2}
                displayValue={true}
                fontSize={12}
              />
            </div>
            <div className="barcode_dept">
              <p>{dept}</p>
              <p>{currentDate}</p>
            </div>
          </div>
        ))}
      </div>
      {show ? (
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handlePrint}>
            Print
          </button>
        </div>
      ) : null}
    </>
  );
};
