import React, { useState, useEffect, useRef } from 'react';
// import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import mammoth from 'mammoth';
import html2canvas from 'html2canvas';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import './ckeditor-custom.css';  // Import custom CSS
import Webcam from "react-webcam";
import jsPDF from 'jspdf';
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { v4 as uuidv4 } from 'uuid';
// import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import EditIcon from '@mui/icons-material/Edit';

function Reportcapture() {
  const urllink = useSelector(state => state.userRecord?.UrlLink)
  const userRecord = useSelector(state => state.userRecord?.UserData)
  const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [visitid, setvisitid] = useState();
  const [captureOrganism, setcaptureOrganism] = useState('');
  const [captureantibiotic, setcaptureantibiotic] = useState('');
  const [sensitivetype, setsensitivetype] = useState('');
  const [completeantibiotic, setcompleteantibiotic] = useState([])
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log('capturedatas', capturedatas)
  const [showFile, setShowFile] = useState({
    file1: false,
  });
  const [isImageCaptured1, setIsImageCaptured1] = useState(false);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);
  const [samplepicture, setsamplepicture] = useState(null)
  const dispatchvalue = useDispatch()
  const [alerts, setalert] = useState([]);
  const [department, setDepartment] = useState([])
  const [selectdepartment, setselectdepartment] = useState()
  const [openpreview, setopenpreview] = useState(false)

  const navigate = useNavigate()


  // const selectdepartment = useSelector((state)=>state.userRecord?.selecteddepartment)
  // console.log('selectdepartment......................', selectdepartment);
  const [showdown, setshowdown] = useState(false)
  const [content, setContent] = useState('');
  const contentRef = useRef(null);
  const [edited, setedited] = useState(null)
  const [formData3, setFormData3] = useState({
    CapturedImage1: null,
  });

  const videoConstraints = {
    facingMode: facingMode,
  };
  const webcamRef1 = useRef(null);
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const loadWordFile = async (content) => {
    try {
      const arrayBuffer = await new Response(content).arrayBuffer();
      const { value, messages } = await mammoth.convertToHtml({ arrayBuffer });

      if (messages && messages.length > 0) {
        console.warn('Conversion warnings:', messages);
      }

      setContent(value);
    } catch (error) {
      console.error('Error converting DOCX to HTML:', error);
    }
  };


  const saveAsPDF = () => {
    const input = contentRef.current;
    setedited(contentRef.current)
    const menuHeader = input.querySelector('.ck-toolbar');
    if (menuHeader) {
      menuHeader.style.display = 'none';
    }
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('edited_content.pdf');
      if (menuHeader) {
        menuHeader.style.display = 'block';
      }
    });
  };


  const handleRecaptureImage1 = () => {

    setFormData3((prev) => ({
      ...prev,
      CapturedImage1: null,
    }));
    setIsImageCaptured1(false);
  };


  const handleCaptureImage1 = () => {
    const imageSrc = webcamRef1.current.getScreenshot();
    const blob = imageSrc
      ? dataURItoBlob(imageSrc, "captured_image.jpg")
      : null;

    
      setsamplepicture(
          blob instanceof Blob ? new File([blob], "captured_image.jpg") : null,
      );

    setIsImageCaptured1(true);
  };


  useEffect(() => {

    axios.get(`${urllink}Billing/getcompletedsample?Billinginvoice=${capturedatas?.Billing_Invoice}&Location=${userRecord?.location}&gender=${capturedatas.Gender}`)
      .then((response) => {
        console.log('------------', response)
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          testcode: item.Test_Code,
          testname: item.Test_Name,
          testnames: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          Container_Name: item.Container_Name,
          Specimen_Name: item.Specimen_Name,
          UpdateAt: capturedatas.UpdatedAt,
          paniclow: item.PanicLow,
          panichigh: item.PanicHigh,
          Calculation: item.Calculation,
          Formula: item.Formula,
          patient_name: capturedatas.Patient_Name,
          Billing_Invoice: capturedatas.Billing_Invoice,
          barcodedata: capturedatas.Barcode_id,
          parameterone: item.Parameterone,
          parametertwo: item.parametertwo,
          Colonycount: item.Colonycount,
          uom: item.UOM,
          culturetest: item.culturetest || "",
          inputtype: item.input_type,
          organisn: item.Organism || [],
          antibiotic: item.Antibiotic || [],
          subdepartment: item.culturetest === 'Yes'? item.Department : item.Sub_Department
          // captureOrganism : captureOrganism

          // You don't need to explicitly include the 'actions' field here
        }));
        // setFilteredRows(data);
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas.Billing_Invoice]);



  // useEffect(() => {
  //   // Fetch the Word file content when the component mounts
  //   let department = filteredRows[0]?.subdepartment
  //   axios.get(`${urllink}/Billing/get_word_data?department=${department}`, { responseType: 'blob' })
  //     .then((response) => {
  //       const content = response.data;
  //       loadWordFile(content);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching DOCX file:', error);
  //     });
  // }, [urllink, filteredRows]);


  const handledeptchange = (e) => {
    setselectdepartment(e.target.value);
    // dispatchvalue({type :'selecteddepartment', value : selectdepartment})
  };


  const handleRemarksChange = (index, newValue, depart) => {
    const updatedvall = [...testDetails];
    const updatedvalue = updatedvall.filter((p) => p.subdepartment === depart);
    
    
    // Check if culture test is "Yes" for the corresponding test
    if (updatedvalue[index].culturetest === 'Yes') {
        updatedvalue[index].Colonycount = newValue; // Update Colonycount
    }else{
      updatedvalue[index].capturevalue = newValue;

    }

    setTestDetails(updatedvall); // Use the original array with the modified sub-array
};

  // const capturevaluealert = (index) => {
  //   const alertvalue = testDetails.filter((p) => p.id === index + 1);
  //   console.log('alertvalue', alertvalue);
  //   setalert(alertvalue);
  // };

  // useEffect(() => {
  //   // Set a new timeout to show the alert after typing is complete
  //   let alertTimeout = setTimeout(() => {
  //     if (alerts.length > 0) {
  //       const alertItem = alerts[0];
  //       if (Number(alertItem?.capturevalue) >= Number(alertItem?.panichigh) || Number(alertItem?.capturevalue) <= Number(alertItem?.paniclow)) {
  //         const resultEntry = window.confirm(`Result Out of Range ${alertItem?.capturevalue}`);
  //         if (resultEntry) {
  //           // User confirmed, proceed with the entry (no action needed)
  //         } else {
  //           // User canceled, revert the change
  //           setTestDetails((prevDetails) => {
  //             const revertedDetails = [...prevDetails];
  //             revertedDetails[alertItem.id - 1].capturevalue = ''; // or set to previous value if available
  //             return revertedDetails;
  //           });
  //         }
  //       }
  //     }
  //   }, 500);

  //   return () => {
  //     clearTimeout(alertTimeout);
  //   };
  // }, [alerts]);
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  useEffect(() => {
    const updatedTestDetails = testDetails.map((test) => {
      if (test.Calculation === "Yes") {
        const formula = test.Formula.trim();
        const parts = tokenizeFormula(formula); // Tokenize formula excluding parentheses and operators

        const parameters = {};

        // Populate parameters with values from testDetails
        parts.forEach(element => {
          if (isNaN(element)) {
            const parameterTest = testDetails.find(item => item.testname === element);
            parameters[element] = parameterTest ? parseFloat(parameterTest.capturevalue) || 0 : 0;
          }
        });

        // Evaluate expression using parameters
        return evaluateExpression(formula, parameters)
          .then(result => {
            console.log('res........', result);
            return { ...test, capturevalue: parseFloat(result) || 0 }; // Return a new object with updated capturevalue
          })
          .catch(error => {
            console.error('Error evaluating expression:', error);
            return { ...test, capturevalue: 0 }; // Return a new object with capturevalue set to 0 in case of error
          });
      }
      return test;
    });

    // Wait for all promises to resolve
    Promise.all(updatedTestDetails)
      .then(updatedTestDetailsResolved => {
        // Check if the updatedTestDetailsResolved is different from the current state
        const isTestDetailsChanged = JSON.stringify(updatedTestDetailsResolved) !== JSON.stringify(testDetails);

        // Update the state only if the testDetails has changed
        if (isTestDetailsChanged) {
          setTestDetails(updatedTestDetailsResolved);
        }
      });
  }, [testDetails]); // Depend on testDetails to trigger the effect

  async function evaluateExpression(tokens, parameters) {
    console.log(parameters);
    try {
      const response = await axios.get(`${urllink}Billing/getformula_calculated_value`, {
        params: {
          formula: tokens,
          parameters: JSON.stringify(parameters)
        }
      });
      console.log(response.data);
      const currentOperand = response.data?.valll;
      console.log(currentOperand, '0000000000000000');
      return parseFloat(currentOperand) || 0;
    } catch (error) {
      console.error('Error fetching expression:', error);
      return 0;
    }
  }


  function tokenizeFormula(formula) {
    // Add spaces around operators and parentheses to tokenize them properly
    formula = formula.replace(/([+\-*/%^()])/g, ' $1 ');
    // Split by spaces to get tokens

    const tokens = formula.split(/\s+/).filter(token => token.trim() !== '');

    // Remove parentheses and operators from tokens
    return tokens.filter(token => !['(', ')', '+', '-', '*', '/', '%', '^'].includes(token));
  }


  const handlecompleteantibiotic = () => {
    if (captureOrganism !== '' && captureantibiotic !== '') {
      // Check if the captureantibiotic already exists in completeantibiotic
      const exists = completeantibiotic.some(item => item.antibiotic === captureantibiotic);
      if (exists) {
        alert("Antibiotic already exists.");
      } else {
        // If not, add it to completeantibiotic array
        setcompleteantibiotic(prev => [
          ...prev,
          { antibiotic: captureantibiotic, sensitivetype: sensitivetype }
        ]);

        // Clear captureantibiotic and sensitivetype inputs after adding

      }
    }
  };


  useEffect(() => {
    console.log('completeantibiotic', completeantibiotic)
    setcaptureantibiotic("");
    setsensitivetype("");
  }, [completeantibiotic])


  const handlemedicalRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].medicalremark = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handletecnicalRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].technicalremark = newValue;
    setTestDetails(updatedTestDetails);
  };

  useEffect(() => {
    axios.get(`${urllink}Billing/get_for_pending_result_department?invoice=${capturedatas?.Billing_Invoice}`)
      .then((response) => {
        console.log(response);
        setDepartment(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas, urllink, selectdepartment]);


  const handlereportedit = (data) =>{
    console.log(data);
    let department = data?.subdepartment
    axios.get(`${urllink}/Billing/get_word_data?department=${department}`, { responseType: 'blob' })
    .then((response) => {
      const content = response.data;
      loadWordFile(content);
    })
    .catch((error) => {
      console.error('Error fetching DOCX file:', error);
    });
    setopenpreview(true)
  }


  const handleSubmitAnalyseReport = () => {
    let validationErrors = []; // Collect validation errors

    // Assuming department and testDetails are populated correctly
    // department.forEach((item) => {
    //     testDetails.filter((p) => p.subdepartment === item).forEach((i) => {
    //         if (i.subdepartment === 'Biochemistry' && (!i.capturevalue || i.capturevalue === '')) {
    //             validationErrors.push(`Please fill ${item} Result value for Biochemistry`);
    //         }
    //         if (i.subdepartment === 'Microbiology' && (!i.Colonycount || i.Colonycount === '')) {
    //             validationErrors.push(`Please fill ${item} Result value for Microbiology`);
    //         }
    //         if (i.subdepartment === 'Haematology' && (!i.capturevalue || i.capturevalue === '')) {
    //             validationErrors.push(`Please fill ${item} Result value for Haematology`);
    //         }
    //     });
    // });

    // console.log('validationErrors', validationErrors);
    // if (validationErrors.length > 0) {
    //     alert(validationErrors.join('\n')); // Show all validation errors at once
    //     return; // Exit if there are any validation errors
    // }

    const postdata = {
        testDetails,
        Billing_Invoice: capturedatas.Billing_Invoice,
        visitid: capturedatas.Visit_Id,
        patient_Id: capturedatas.Patient_Id,
        CreatedBy: userRecord?.username,
        Location: userRecord?.location,
        captureOrganism: captureOrganism,
        content: content || '',
        Photo: samplepicture
    };

    const culturepost = {
        Billing_Invoice: capturedatas?.Billing_Invoice,
        visitid: capturedatas?.Visit_Id,
        patient_Id: capturedatas?.Patient_Id,
        CreatedBy: userRecord?.username,
        Location: userRecord?.location,
        Patient_name: capturedatas?.Patient_Name,
        refering_doc: capturedatas?.Refering_Doctor,
        antibiotic: completeantibiotic // Spread is unnecessary, it's already an array
    };
    const culturetests = testDetails?.some((item) => item.culturetest === 'Yes');

    // Create FormData object and append postdata
    const formData = new FormData();
    for (const key in postdata) {
        if (key === 'Photo' && postdata[key]) {
            formData.append(key, postdata[key]); // Append file directly
        } else if (typeof postdata[key] === 'object' && postdata[key] !== null) {
            formData.append(key, JSON.stringify(postdata[key])); // Stringify JSON objects
        } else {
            formData.append(key, postdata[key]); // Append simple values directly
        }
    }

    console.log(postdata);
    // Perform API calls
  

    if (culturetests === true) {
      axios.post(`${urllink}Phelobotomist/insert_into_culture_report`, culturepost)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  
    axios.post(`${urllink}Phelobotomist/insert_analyse_investigation_result`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        console.log(response);
        dispatchvalue({ type: "Navigationlab", value: "" });
        navigate('/Home/AnalayseReportEntry');
      })
      .catch((error) => {
        console.log(error);
      });
};




  useEffect(()=>{
    let data = testDetails?.filter((p)=>p.subdepartment === selectdepartment)
    setFilteredRows(data)

  }, [selectdepartment, testDetails, department])


  console.log('testDetails', testDetails)

  const culturetests = filteredRows.some((item) => item.culturetest === 'Yes');
  const histopathologytest = filteredRows.some((item) => item.subdepartment === 'Histopathology')
  // const capture = filteredRows.map((i) => i.capturevalue)

  console.log('samplepicture', filteredRows)

  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      // Simulate uploading the file
      setTimeout(() => {
        // Generate a unique URL for the uploaded image
        const uploadedUrl = `https://example.com/uploads/${uuidv4()}`;
        resolve({ default: uploadedUrl });
      }, 2000); // Simulating a 2 second delay, replace this with your actual upload logic
    });
  };

  return (
    <>
          {department.map((dept, deptIndex) => (
                <div key={deptIndex}>
                    <h3>{dept}</h3>
                    <div className="samplecapture_component">
                        <div className="Selected-table-container">
                            {testDetails?.some((p) => p.subdepartment === dept && p.culturetest === 'Yes') && (
                                <>
                                    <table className="selected-medicine-table2">
                                        <thead>
                                            <tr>
                                                <th>Test Name</th>
                                                <th>Specimen</th>
                                                <th>Colony Count</th>
                                                <th>Capture Organism</th>
                                                <th>Antibiotic</th>
                                                <th>Sensitive Types</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {testDetails.filter((p) => p.culturetest === 'Yes').map((detail, index) => (
                                                <tr key={index}>
                                                    <td>{detail.testname}</td>
                                                    <td>{detail.Specimen_Name}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className='Capture_Status_select1'
                                                            onKeyDown={blockInvalidChar}
                                                            value={detail.Colonycount}
                                                            onChange={(e) => handleRemarksChange(index, e.target.value, dept)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <select value={captureOrganism} onChange={(e) => setcaptureOrganism(e.target.value)}>
                                                            <option value="">Select</option>
                                                            {detail.organisn.map((p, i) => (
                                                                <option key={i} value={p}>{p}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select value={captureantibiotic} onChange={(e) => setcaptureantibiotic(e.target.value)}>
                                                            <option value="">Select</option>
                                                            {detail.antibiotic.map((p, i) => (
                                                                <option key={i} value={p}>{p}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select value={sensitivetype} onChange={(e) => setsensitivetype(e.target.value)}>
                                                            <option value="">Select</option>
                                                            <option value="Sensitive">Sensitive</option>
                                                            <option value="Resistant">Resistant</option>
                                                            <option value="Moderate">Moderate</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <button className='RegisterForm_1_btns' style={{marginLeft:'10px'}} onClick={handlecompleteantibiotic}>Save</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {completeantibiotic.length > 0 && (
                                        <div className="Selected-table-container">
                                            <table className="selected-medicine-table2">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Antibiotic</th>
                                                        <th>Sensitive Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {completeantibiotic.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.antibiotic}</td>
                                                            <td>{item.sensitivetype}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </>
                            )}

                            {dept !== 'Histopathology' && !testDetails?.some((p) => p.subdepartment === dept && p.culturetest === 'Yes') && (
                                <table className="selected-medicine-table2">
                                    <thead>
                                        <tr>
                                            <th>Test Name</th>
                                            <th>Specimen</th>
                                            <th>Capture Value</th>
                                            <th>Reference Range</th>
                                            <th>Indicator</th>
                                            <th>Remarks
                                                <table className='remarktable'>
                                                    <thead>
                                                        <tr>
                                                            <th>Technical</th>
                                                            <th>Medical</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {testDetails.filter((detail) => detail.subdepartment === dept && detail.culturetest !== 'Yes').map((detail, index) => (
                                            <tr key={index}>
                                                <td>{detail.testname}</td>
                                                <td>{detail.Specimen_Name}</td>
                                                <td>
                                                    {detail.Calculation === 'Yes' ?
                                                        detail.capturevalue :
                                                        <input
                                                            type={detail.inputtype === 'Numeric' ? "number" : "text"}
                                                            className='Capture_Status_select1'
                                                            value={detail.capturevalue}
                                                            onChange={(e) => handleRemarksChange(index, e.target.value, dept)}
                                                        />
                                                    }
                                                </td>
                                                <td>{detail.paniclow}-{detail.panichigh}{detail.uom}</td>
                                                <td>
                                                    <span>
                                                        {detail.inputtype === 'Numeric' ? (
                                                            Number(detail.capturevalue) >= detail.panichigh ? (
                                                                <span className='indicator' style={{ color: 'red' }}>
                                                                    <TiArrowUpThick />
                                                                </span>
                                                            ) : Number(detail.capturevalue) <= detail.paniclow ? (
                                                                <span className='indicatorlow'>
                                                                    <TiArrowDownThick />
                                                                </span>
                                                            ) : (
                                                                <p>Normal</p>
                                                            )
                                                        ) : (
                                                            detail.capturevalue
                                                        )}
                                                    </span>
                                                </td>
                                                <td>
                                                    <table className='remarktable'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <textarea
                                                                        cols="20"
                                                                        rows="1"
                                                                        value={detail.technicalRemark}
                                                                        className='table_textarea'
                                                                        onChange={(e) => handletecnicalRemarksChange(index, e.target.value)}
                                                                    ></textarea>
                                                                </td>
                                                                <td>
                                                                    <textarea
                                                                        cols="20"
                                                                        rows="1"
                                                                        value={detail.medicalRemark}
                                                                        className='table_textarea'
                                                                        onChange={(e) => handlemedicalRemarksChange(index, e.target.value)}
                                                                    ></textarea>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}

                            {dept === 'Histopathology' && 
                             <table className="selected-medicine-table2">
                             <thead>
                                 <tr>
                                     <th>Test Name</th>
                                     <th>Test Code</th>
                                     <th>Specimen</th>
                                     {/* <th>Sensitive Types</th> */}
                                     <th>Action</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 {testDetails.filter((p) => p.subdepartment === dept).map((detail, index) => (
                                     <tr key={index}>
                                         <td>{detail.testname}</td>
                                         <td>{detail.testcode}</td>
                                         <td>{detail.Specimen_Name}</td>
                                         <td>
                                          <button onClick={()=>handlereportedit(detail)}>
                                            <EditIcon/>
                                          </button>
                                         </td>
                                     </tr>
                                 ))}
                             </tbody>
                         </table>
                            }
                        </div>
                    </div>
                    
                </div>
                
            ))}
            <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
                  Save
                </button>
              </div>

              {openpreview && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setopenpreview(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>

              <div class="RegisForm_1">
            <label>
              Photo <span>:</span>
            </label>
            <div className="RegisterForm_2">
              <input
                type="file"
                id="photo"
                name="photo"
                className="hiden-nochse-file"
                accept="image/*,.pdf"
                // onChange={(e) => handleChange12(e, "photo")}
                required
              />
              <label
                htmlFor="photo"
                className="RegisterForm_1_btns choose_file_update"
              >
                Choose File
              </label>
            </div>
            <span>or </span>
            <div className="RegisterForm_2">
              <button
                onClick={() => setShowFile({ file1: true })}
                className="choose-file-button-55"
              >
                Capture pic
              </button>

              {showFile.file1 && (
                <div
                  className={
                    isSidebarOpen
                      ? "sideopen_showcamera_takepic"
                      : "showcamera_takepic"
                  }
                  onClick={() =>
                    setShowFile({
                      file1: false,
                    })
                  }
                >
                  <div
                    className={
                      isSidebarOpen
                        ? "sideopen_showcamera_1_takepic1"
                        : "showcamera_1_takepic1"
                    }
                    onClick={(e) => e.stopPropagation()}
                  >
                    {formData3.CapturedImage1 ? ( // Display the captured image
                      <img
                        src={URL.createObjectURL(formData3.CapturedImage1)}
                        alt="captured"
                        className="captured-image11"
                      />
                    ) : (
                      <div className="camera-container">
                        <div className="web_head">
                          <h3>Image</h3>
                        </div>
                        <br></br>
                        <div className="RotateButton_canva">
                          <Webcam
                            audio={false}
                            ref={webcamRef1}
                            screenshotFormat="image/jpeg"
                            mirrored={facingMode === "user"}
                            className="web_cam"
                            videoConstraints={videoConstraints}
                          />
                          {!devices.includes(IsmobileorNot) && (
                            <button onClick={switchCamera}>
                              <CameraswitchIcon />
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="web_btn">
                      {isImageCaptured1 ? (
                        <button
                          onClick={handleRecaptureImage1}
                          className="btncon_add"
                        >
                          Recapture
                        </button>
                      ) : (
                        <button
                          onClick={handleCaptureImage1}
                          className="btncon_add"
                        >
                          Capture
                        </button>
                      )}

                      <button
                        onClick={() =>
                          setShowFile({
                            file1: false,
                          })
                        }
                        className="btncon_add"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div ref={contentRef} className='editor_life'>



            <CKEditor
              editor={ClassicEditor}
              data={content}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
            {/* {!showdown && <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
                Save
              </button>
            </div>} */}

          </div>
     
      <div className="Register_btn_con regster_btn_contsai">
        <button
          className="RegisterForm_1_btns"
          onClick={() => setopenpreview(false)}
        >
          Close
        </button>
        <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
                Save
              </button>
      </div>
    </div>
    </div>
    )}
    </>
  );
}

export default Reportcapture;



