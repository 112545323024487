import React, { useState, useEffect } from "react";
import "./Prescription.css";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const Prescription = () => {
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const Location = userRecord?.location;
  const create = userRecord?.username;
  const navigate = useNavigate();

  const [appointmentDate, setAppointmentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [formDataPrescription, setFormDataPrescription] = useState({
    GenericName: "",
    ItemName: "",
    dose: "",
    route: "",
    qty: "",
    instruction: "",
    frequency: "",
    durationNumber: "",
    durationUnit: "",
    itemtype: "",
  });

  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [genericName, setgenericName] = useState([]);
  const [itemName, setitemName] = useState([]);
  const [Uom, setuom] = useState([]);

  const {
    GenericName,
    ItemName,
    dose,
    route,
    qty,
    instruction,
    frequency,
    durationNumber,
    durationUnit,
    itemtype,
  } = formDataPrescription;

  useEffect(() => {
    axios
      .get(`${urllink}quickstockreceive/getgenericname?location=${Location}`)
      .then((response) => {
        // Process the response data as needed
        setgenericName(response.data);
      })
      .catch((error) => {
        console.error("Error fetching generic names:", error);
        // Handle the error, e.g., show an error message to the user
      });
  }, []);

  useEffect(() => {
    const selectedGenericName = formDataPrescription.GenericName;

    axios
      .get(
        `${urllink}quickstockreceive/getitemname_bygenericname?genericName=${selectedGenericName}&location=${Location}`
      )
      .then((response) => {
        setitemName(response.data);
      })
      .catch((error) => {
        console.error("Error fetching item names:", error);
      });
  }, [formDataPrescription.GenericName, Location]);

  // Fetch UOM when the selected item name changes
  useEffect(() => {
    const selectedItemName = formDataPrescription.ItemName;

    axios
      .get(
        `${urllink}quickstockreceive/getDose_By_Itemname?selectedItemname=${selectedItemName}&location=${Location}`
      )
      .then((response) => {
        setuom(response.data);
      })
      .catch((error) => {
        console.error("Error fetching UOM:", error);
      });
  }, [formDataPrescription.ItemName]);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data with the changed field
    setFormDataPrescription({
      ...formDataPrescription,
      [name]: value,
    });
    const { frequency, durationNumber, durationUnit, itemtype, GenericName } = {
      ...formDataPrescription,
      [name]: value,
    };
    if (name === "GenericName" && value === "") {
      // Reset all fields to their initial empty state
      setFormDataPrescription({
        GenericName: "",
        ItemName: "",
        dose: "",
        route: "",
        qty: "",
        instruction: "",
        frequency: "",
        durationNumber: "",
        durationUnit: "",
      });
    }

    if (itemtype == "Tablets" || itemtype == "Tablet") {
      if (["frequency", "durationNumber", "durationUnit"].includes(name)) {
        // Check if frequency, duration number, or duration unit is changed

        // If duration number or unit is not selected, set qty to empty
        if (!durationNumber || !durationUnit) {
          setFormDataPrescription((prevData) => ({
            ...prevData,
            qty: "",
          }));
          return; // Exit the function
        }

        // Split frequency into morning, afternoon, and night frequencies
        const [morning, afternoon, night] = frequency.split("-");

        let times1 = parseInt(morning) + parseInt(afternoon) + parseInt(night);
        let times = 1;
        switch (durationUnit) {
          case "days":
            times = parseInt(durationNumber);
            break;
          case "weeks":
            times = parseInt(durationNumber) * 7;
            break;
          case "months":
            times = parseInt(durationNumber) * 30;
            break;

          default:
            return;
        }

        const qty = times1 * times;

        setFormDataPrescription((prevData) => ({
          ...prevData,
          qty: qty,
        }));
      }
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "GenericName",
      "ItemName",
      "dose",
      "route",
      "qty",
      "instruction",
      "frequency",
      "durationNumber",
      "durationUnit",
    ];

    const emptyFields = requiredFields.filter(
      (field) => !formDataPrescription[field]
    );

    if (emptyFields.length > 0) {
      alert(`Fields ${emptyFields.join(", ")} cannot be empty.`);
      return false;
    }

    return true;
  };

  const addMedicine = () => {
    if (!validateForm()) {
      return;
    }

    const medicineData = {
      id: selectedMedicines.length + 1,
      GenericName,
      ItemName,
      dose,
      route,
      qty,
      instruction,
      frequency,
      durationNumber,
      durationUnit,
      itemtype,
    };

    setSelectedMedicines([...selectedMedicines, medicineData]);

    // Clear form data after adding
    setFormDataPrescription({
      GenericName: "",
      ItemName: "",
      dose: "",
      route: "",
      qty: "",
      instruction: "",
      frequency: "",
      durationNumber: "",
      durationUnit: "",
      itemtype: "",
    });
  };

  const updateMedicine = () => {
    if (!validateForm()) {
      return;
    }

    const updatedMedicines = [...selectedMedicines];
    updatedMedicines[editIndex] = {
      id: selectedMedicines[editIndex].id,
      GenericName,
      ItemName,
      dose,
      route,
      qty,
      instruction,
      frequency,
      durationNumber,
      durationUnit,
      itemtype,
    };

    setSelectedMedicines(updatedMedicines);
    setEditIndex(null);

    // Clear form data after updating
    setFormDataPrescription({
      GenericName: "",
      ItemName: "",
      dose: "",
      route: "",
      qty: "",
      instruction: "",
      frequency: "",
      durationNumber: "",
      durationUnit: "",
      itemtype: "",
    });
  };

  const handleEditMedicine = (index) => {
    // Set form data with selected medicine for editing
    const selectedMedicine = selectedMedicines[index];
    setFormDataPrescription({ ...selectedMedicine });
    setEditIndex(index);
  };

  const handleDeleteMedicine = (index) => {
    const updatedMedicines = selectedMedicines.filter((_, i) => i !== index);
    setSelectedMedicines(updatedMedicines);
    setEditIndex(null);
  };

  const handleSubmit = () => {
    const dataToSend = selectedMedicines.map((p) => ({
      PatientID: formData.PatientID,
      appointmentDate: appointmentDate,
      AppointmentID: formData.SerialNo,
      visitNo: formData.visitNo,
      DoctorName: formData.DoctorName,
      GenericName: p.GenericName,
      ItemName: p.ItemName,
      itemtype: p.itemtype,
      dose: p.dose,
      route: p.route,
      frequency: p.frequency,
      duration: `${p.durationNumber} ${p.durationUnit}`,
      qty: p.qty,
      instruction: p.instruction,
      createdBy: create,
      location: Location,
    }));

    if (dataToSend.length !== 0) {
      const shouldProceed = window.confirm("Do you want Procedure?");

      // Determine the status based on the user's choice
      const statuss = shouldProceed ? "In-Process" : "Completed";

      axios
        .post(`${urllink}doctorsworkbench/insert_prescription`, {
          data: dataToSend,
          procedurestatus: statuss, // Include the status in the request payload
        })
        .then((response) => {
          successMsg("Saved Successfully");

          // Conditionally navigate based on the status
          if (statuss === "Completed") {
            setSelectedMedicines([]);
            navigate("/Treament-QueueList");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      errmsg("No Prescription Data To Save");
    }
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <div className="for">
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="title">
            Generic Name<span>:</span>
          </label>
          <input
            id="medicine"
            name="GenericName"
            value={formDataPrescription.GenericName}
            onChange={handleChange}
            list="GenericName-options"
          />
          <datalist id="GenericName-options">
            <option value="">Select</option>
            {genericName.map((item, index) => (
              <option key={index} value={item.GenericName}></option>
            ))}
          </datalist>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="title">
            Item Name<span>:</span>
          </label>
          <input
            id="medicine"
            name="ItemName"
            value={formDataPrescription.ItemName}
            onChange={handleChange}
            list="ItemName-options"
          />
          <datalist id="ItemName-options">
            <option value="">Select</option>
            {itemName.map((item, index) => (
              <option key={index} value={item.ItemName}></option>
            ))}
          </datalist>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="title">
            Item Type<span>:</span>
          </label>
          <select
            id="itemtype"
            name="itemtype"
            value={formDataPrescription.itemtype}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Uom.map((item, index) => (
              <option key={index} value={item.Pack_type}>
                {item.Pack_type}
              </option>
            ))}
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="title">
            Dose<span>:</span>
          </label>
          <select
            id="dose"
            name="dose"
            value={formDataPrescription.dose}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Uom.map((item, index) => (
              <option key={index} value={item.dose}>
                {item.dose}
              </option>
            ))}
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="title">
            Route<span>:</span>
          </label>
          <select
            id="route"
            name="route"
            value={formDataPrescription.route}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Oral">Oral</option>
            <option value="Injection">Injection</option>
            <option value="External">External</option>
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="notes">
            Frequency<span>:</span>
          </label>
          <select
            id="frequency"
            name="frequency"
            rows="2"
            value={formDataPrescription.frequency}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="0-0-1">0-0-1</option>
            <option value="0-1-1">0-1-1</option>
            <option value="1-1-1">1-1-1</option>
            <option value="1-1-0">1-1-0</option>
            <option value="1-0-1">1-0-1</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="duration">
            Duration<span>:</span>
          </label>
          <input
            id="durationNumber"
            name="durationNumber"
            className="dura_with1"
            value={formDataPrescription.durationNumber}
            onChange={handleChange}
          ></input>
          <select
            id="durationUnit"
            name="durationUnit"
            className="dura_with"
            value={formDataPrescription.durationUnit}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="title">
            Qty<span>:</span>
          </label>
          <input
            id="qty"
            name="qty"
            value={formDataPrescription.qty}
            onChange={handleChange}
            readOnly={
              (formDataPrescription.itemtype === "Tablets" ||
                formDataPrescription.itemtype === "Tablet") &&
              formDataPrescription.itemtype !== ""
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="instruction">
            Instruction<span>:</span>
          </label>
          <textarea
            id="instruction"
            name="instruction"
            rows="2"
            value={formDataPrescription.instruction}
            onChange={handleChange}
          ></textarea>
        </div>
      </div>

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={editIndex !== null ? updateMedicine : addMedicine}
        >
          {editIndex !== null ? "Update " : "Add "}
        </button>
      </div>

      <div className="for">
        <div className="custom-header">
          <h4>Selected Medicine</h4>
        </div>
        <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
                <th id="slectbill_ins">GenericName</th>
                <th id="slectbill_ins">ItemName</th>
                <th id="slectbill_ins">Dose</th>
                <th id="slectbill_ins">Route</th>
                <th id="slectbill_ins">Frequency</th>
                <th id="slectbill_ins">Duration</th>
                <th id="slectbill_ins">Qty</th>
                <th id="slectbill_ins">Instruction</th>
                <th id="slectbill_ins">Action</th>
              </tr>
            </thead>

            <tbody>
              {selectedMedicines.map((medicineInfo, index) => (
                <tr key={index}>
                  <td>{medicineInfo.GenericName}</td>
                  <td>{medicineInfo.ItemName}</td>
                  <td>{medicineInfo.dose}</td>
                  <td>{medicineInfo.route}</td>
                  <td>{medicineInfo.frequency}</td>
                  <td>
                    {medicineInfo.durationNumber} {medicineInfo.durationUnit}
                  </td>
                  <td>{medicineInfo.qty}</td>
                  <td>{medicineInfo.instruction}</td>
                  <td>
                    <button
                      className="delnamebtn"
                      onClick={() => handleEditMedicine(index)}
                    >
                      <EditIcon />
                    </button>
                    <button
                      className="delnamebtn"
                      onClick={() => handleDeleteMedicine(index)}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="button-container_pres">
        <button
          className="btncon_add prs-ad-sub-btn"
          type="button"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Prescription;
