import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
const AdvanceRequest = () => {
  const userRecord=useSelector(state=>state.userRecord?.UserData)
    const [formData, setFormData] = useState({
        employeeId: '',
        employeeName: '',
        designation:'',
        reqdate:'',
        reqAmount:'',
        reason:'',
        location : userRecord.location,
        createdby : userRecord.First_Name
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      }

      useEffect(() => {
        const employeeId = userRecord.EmployeeId;
      
        // Check if employeeId is available (not empty) before making the API request
        if (employeeId && employeeId.length === 9) {
          axios.get(`http://127.0.0.1:8000/HRmanagement/employeedetails_forleave?employeeid=${employeeId}&location=${userRecord.location}`)
            .then((response) => {
              console.log(response.data);
              if (response.data.error){
                alert(response.data.error)
                setFormData({
                  employeeName:  '',
                  designation:  '',
                  employeeId:  '',
                   });
              }
              else{
                
                setFormData({
                  employeeName: response.data?.employeeName || '',
                  designation: response.data?.designation || '',
                  employeeId: response.data?.employeeId || '',
                  location: userRecord.location,
                  createdby : userRecord.First_Name
                   });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("EmployeeId must be 9 characters in length");
        }
        
      
      }, [formData.employeeId,userRecord]);


    const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('http://127.0.0.1:8000/HRmanagement/insert_advance_register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log('response',response);
        alert(response.data.message);
       
      })
      .catch((error) => {
        console.error(error);
      });
    
  }

  console.log(userRecord)
  console.log('formData',formData)
  return (
    
    <div className='appointment'>
        <div className="RegisFormcon ">
          <div className='RegisForm_1 '>
            <label htmlFor="employeeId" >Employee ID <span>:</span></label>
            <input type="text" id="employeeId" name="employeeId"  onChange={handleChange} value={userRecord.EmployeeId}/>
          </div>
        
     
        
          <div className='RegisForm_1 '>
            <label htmlFor="employeeName" >Employee Name <span>:</span></label>
            <input type="text" id="employeeName" name="employeeName"  onChange={handleChange} value={formData.employeeName}/>
          </div>
   
        <div className='RegisForm_1 '>
          <label htmlFor="designation" >Designation <span>:</span></label>
          <input type='text' name='designation' value={formData.designation}/>
        </div>
    
     
          <div className='RegisForm_1 '>
            <label htmlFor="reqdate" >Request Date <span>:</span></label>
            <input type="date" id="reqdate" name="reqdate"  onChange={handleChange} />
          </div>
          
     
          <div className='RegisForm_1 '>
            <label htmlFor="reqAmount" >Request Amount <span>:</span></label>
            <input type="text" id="reqAmount" name="reqAmount"  onChange={handleChange} />
          </div>
          
      
          <div className='RegisForm_1 '>
            <label htmlFor="reason" >Reason <span>:</span></label>
            <textarea type="text" id="reason" name="reason"  onChange={handleChange} />
          </div>
          </div>
      {/* <div className="RegisFormcon ">
          <div className='RegisForm_1 '>
            <label htmlFor="approvedDate " >Approved Date <span>:</span></label>
            <input type="date" id="approvedDate" name="approvedDate" placeholder="Enter Approver Name" onChange={handleChange} />
          </div>
          </div> */}
      {/* <div className="RegisFormcon ">
          <div className='RegisForm_1 '>
            <label htmlFor="approvedAmount" >Approved Amount <span>:</span></label>
            <input type="text" id="approvedAmount" name="approvedAmount" placeholder="Enter Amount" onChange={handleChange} />
          </div>
          </div> */}
      {/* <div className="RegisFormcon ">
          <div className='RegisForm_1 '>
            <label htmlFor="issuedDate" >Issued Date <span>:</span></label>
            <input type="date" id="issuedDate" name="issuedDate" placeholder="Enter Amount" onChange={handleChange} />
          </div>
          </div> */}
      {/* <div className="RegisFormcon ">
          <div className='RegisForm_1 '>
            <label htmlFor="issued" >Issued By <span>:</span></label>
            <input type="text" id="issued" name="issued" placeholder="Enter Issuever Name" onChange={handleChange} />
          </div>
        </div> */}
       
        <div className="Register_btn_con">
        <button className='RegisterForm_1_btns' onClick={handleSubmit}>Submit</button>
      </div>
        </div>
     
  )
}

export default AdvanceRequest;
