import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SideBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GridViewIcon from '@mui/icons-material/GridView';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import RecyclingIcon from '@mui/icons-material/Recycling';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';


import {
  faUsers, faHeadset, faStethoscope, faClipboard, faFileMedical, faMoneyBillAlt, faRightFromBracket, faPenNib, faLayerGroup, faUserDoctor, faPrint,
  faUserNurse, faSackDollar, faUserNinja, faUserTag, faChartBar, faAngleDown, faBuildingColumns,faStore , faSuitcaseMedical ,faThumbsUp ,faIndent , faRegistered
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

function Sidebar({ }) {



  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [mainAccess, setMainAccess] = useState([]);
  const [subAccess, setSubAccess] = useState([]);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  // const handleSidebarToggle = () => {
  //   setSidebarOpen(!isSidebarOpen);
  // };
  const location = useLocation();

  const dispatchvalue  = useDispatch();

  const isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  useEffect(() => {
    dispatchvalue({type:'isSidebarOpen',value:false})
    // Close the sidebar when the route changes
    console.log(location);
    const userRecord = localStorage.getItem("token")
    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      const setAccess1 = (decodedTokenData && decodedTokenData.Access_one.split(',').map(item => item.trim())) || [];
      const setAccess2 = (decodedTokenData && decodedTokenData.Access_two.split(',').map(item => item.trim())) || [];

      setMainAccess(setAccess1);
      setSubAccess(setAccess2);

    };
    // Split the string and then trim each element
  }, [location.pathname]);

  // console.log(subAccess);
  // console.log(mainAccess)
  useEffect(() => {
    // Disable text selection on the entire document
    const disableTextSelection = () => {
      document.body.style.userSelect = 'none';
      document.body.style.MozUserSelect = 'none';
      document.body.style.msUserSelect = 'none';
    };
    disableTextSelection();
    // Enable text selection when the component is unmounted
    return () => {
      document.body.style.userSelect = 'auto';
      document.body.style.MozUserSelect = 'auto';
      document.body.style.msUserSelect = 'auto';
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This effect runs once when the component mounts


  const handleSubMenuClick = (subMenu) => {
    setOpenSubMenu(openSubMenu === subMenu ? null : subMenu);

  };


  const handleLogoutClick = () => {
    navigate('/')
    localStorage.clear()
  };



  return (
    <nav id="inventory_sidebar" className={isSidebarOpen ? 'inventory_sidebar_open' : ''} ref={sidebarRef}>

      <div className="inv_sidebar_header" onMouseEnter={() => dispatchvalue({type:'isSidebarOpen',value:true})} onMouseLeave={() => dispatchvalue({type:'isSidebarOpen',value:false})}>
        <div className="inv_components_sidebar">

          {mainAccess.includes("ClinicMetrics") && <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} id='font_111'>
            <FontAwesomeIcon icon={faStethoscope} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" onClick={(() => { navigate("/Home/ClinicMetrics") })}>Clinic Metrics</span>}
            {/* {isSidebarOpen && (
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`arrow-icon ${openSubMenu === 'subsidebarmenu' ? 'arrow-rotate' : ''}`}
              />
            )} */}
          </div>}

          {/* {mainAccess.includes("FrontOffice") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu1')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUsers} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Front Office</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu1' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div> */}
            {/* {isSidebarOpen && openSubMenu === 'subsidebarmenu1' && <div className="subSidebarmenuhover">
              {subAccess.includes("AppoinmentRequest") && <div onClick={(() => { navigate("/Home/Appoinment-RequestList") })}> {isSidebarOpen && <span className="icon-name" >Appoinment Request</span>}</div>}
              {subAccess.includes("BookingConfimation") && <div onClick={(() => { navigate("/Home/Appoinment-RegisterList") })}> {isSidebarOpen && <span className="icon-name" >Appointment Register</span>}</div>} */}
              {/* {subAccess.includes("Followuplist") && <div onClick={(() => { navigate("/Home/Followuplist") })}>{isSidebarOpen && <span className="icon-name" >Follow-up List</span>}</div>} */}
              {/* {subAccess.includes("OPRegister") && <div onClick={(() => { navigate("/Home/Register-Patient2"); dispatchvalue({type:'registerdata',value:[]}); dispatchvalue({type:'forpatienteedit',value:[]}) })}>{isSidebarOpen && <span className="icon-name" >OP Register</span>}</div>} */}
              {/* {subAccess.includes("ConcernForms") && <div onClick={(() => { navigate("/Home/Register_concern_List") })}>{isSidebarOpen && <span className="icon-name" >Consent Forms</span>}</div>}
              {subAccess.includes("LabReport") && <div onClick={(() => { navigate("/Home/Lab-QueueList") })}>{isSidebarOpen && <span className="icon-name" >Lab Report</span>}</div>}
              {subAccess.includes("patientListUpdate") && <div onClick={(() => { navigate("/Home/Patient-List-update") })}>{isSidebarOpen && <span className="icon-name" >Patient Management</span>}</div>}
              

            </div>}
          </div>} */}


          {/* {mainAccess.includes("Nurse") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu2')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserNurse} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Nurse</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu2' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu2' && <div className="subSidebarmenuhover"> */}
              {/* {subAccess.includes("ConcernForms") && <div onClick={(() => { navigate("/Home/Register_concern_List") })}>{isSidebarOpen && <span className="icon-name" >Concern Forms</span>}</div>} */}
              {/* {subAccess.includes("PatientQueueList") && <div onClick={(() => { navigate("/Home/Treament-QueueList3") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
            </div>}
          </div>} */}

          {/* {mainAccess.includes("DoctorWorkbench") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu3')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserDoctor} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Doctor's WorkBench</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu3' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu3' && <div className="subSidebarmenuhover"> */}
              {/* {subAccess.includes("PatientList") && <div onClick={(() => { navigate("/Home/Patient-List") })}>{isSidebarOpen && <span className="icon-name" >Patient List</span>}</div>} */}
              {/* {subAccess.includes("PatientQueueList") && <div onClick={(() => { navigate("/Home/Treament-QueueList") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
            </div>}
          </div>} */}

          {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu1')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <HeadsetMicIcon icon={faUserTag} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Receptionist</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu1' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu1' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/ReceptionistBilling") })}> {isSidebarOpen && <span className="icon-name" >Billing</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/PatientRequestlistforlab") })}>{isSidebarOpen && <span className="icon-name" >Request List</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/Bill-Cancellation") })}> {isSidebarOpen && <span className="icon-name" >Bill Cancellation</span>}</div>}

              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Patientlistforlab") })}>{isSidebarOpen && <span className="icon-name" >Patient List</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/PaymentHistroy") })}>{isSidebarOpen && <span className="icon-name" >Payment History</span>}</div>}




            </div>}
          </div>}


          {/* {mainAccess.includes("Counselor") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu4')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faHeadset} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Counselor</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu4' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu4' && <div className="subSidebarmenuhover"> */}
              {/* {subAccess.includes("PatientList") && <div onClick={(() => { navigate("/Home/Patient-List2") })}>{isSidebarOpen && <span className="icon-name" >Patient List</span>}</div>}
              {subAccess.includes("AppointmentCalendar") && <div onClick={(() => { navigate("/Home/Appointment-Calendar") })}> {isSidebarOpen && <span className="icon-name" >Appointment Calendar </span>}</div>} */}
              {/* {subAccess.includes("PatientQueueList") && <div onClick={(() => { navigate("/Home/Treament-CounselorQueueList") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
              {subAccess.includes("CounselorQueueList") && <div onClick={(() => { navigate("/Home/Treament-CounselorFullList") })}> {isSidebarOpen && <span className="icon-name" >Counselor Queue List</span>}</div>}
            </div>}
          </div>} */}

          {/* {mainAccess.includes("Counselor") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu4')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faClipboard} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Therapist</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu4' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu4' && <div className="subSidebarmenuhover"> */}
              {/* {subAccess.includes("PatientList") && <div onClick={(() => { navigate("/Home/Patient-List2") })}>{isSidebarOpen && <span className="icon-name" >Patient List</span>}</div>}
              {subAccess.includes("AppointmentCalendar") && <div onClick={(() => { navigate("/Home/Appointment-Calendar") })}> {isSidebarOpen && <span className="icon-name" >Appointment Calendar </span>}</div>} */}
              {/* {subAccess.includes("PatientQueueList") && <div onClick={(() => { navigate("/Home/Treament-QueueList2") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
              
            </div>}
          </div>} */}

          {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu2')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <DeviceThermostatIcon icon={faUserTag} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Phelobotomist</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu2' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu2' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/CaptureSamplelist") })}> {isSidebarOpen && <span className="icon-name" >Sample Waiting List</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/PatientRequestlistforlab") })}>{isSidebarOpen && <span className="icon-name" >Request List</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/SamplelistData") })}>{isSidebarOpen && <span className="icon-name" >Completed List</span>}</div>}
             



            </div>}
          </div>}

          {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu3')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
            <FontAwesomeIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />

              {isSidebarOpen && <span className="icon-name">Lab Technicians</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu3' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu3' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/AnalayseReportEntry") })}> {isSidebarOpen && <span className="icon-name" >Report Entry Que</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/ValidatedQue") })}>{isSidebarOpen && <span className="icon-name" >Validated Que</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/SamplelistData") })}>{isSidebarOpen && <span className="icon-name" >Completed List</span>}</div>} */}
              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/TransactionHistory") })}>{isSidebarOpen && <span className="icon-name" >Transaction History</span>}</div>} */}




            </div>}
          </div>}

          {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu4')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
            <RecyclingIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />

              {isSidebarOpen && <span className="icon-name">Senior Technicians</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu4' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu4' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/VerifyReportEntry") })}> {isSidebarOpen && <span className="icon-name" >Report Verify Que</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/SrLabValidatedQue") })}>{isSidebarOpen && <span className="icon-name" >Validated Que</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/SamplelistData") })}>{isSidebarOpen && <span className="icon-name" >Completed List</span>}</div>} */}
              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/TransactionHistory") })}>{isSidebarOpen && <span className="icon-name" >Transaction History</span>}</div>} */}




            </div>}
          </div>}
          
          {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu5')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
            <LocalHospitalIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />

              {isSidebarOpen && <span className="icon-name">Pathologist</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu5' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu5' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/AuthorizedReportEntry") })}> {isSidebarOpen && <span className="icon-name" >Report Approval Que</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/PathValidatedQue") })}>{isSidebarOpen && <span className="icon-name" >Validated Que</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/SamplelistData") })}>{isSidebarOpen && <span className="icon-name" >Completed List</span>}</div>} */}
              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/TransactionHistory") })}>{isSidebarOpen && <span className="icon-name" >Transaction History</span>}</div>} */}




            </div>}
          </div>}
          {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu6')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
            <FontAwesomeIcon icon={faPrint} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Dispatch</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu6' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu6' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/DispatchEntryList") })}> {isSidebarOpen && <span className="icon-name" >Waiting For Dispatch</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {/* {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/PathValidatedQue") })}>{isSidebarOpen && <span className="icon-name" >Validated Que</span>}</div>} */}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/SamplelistData") })}>{isSidebarOpen && <span className="icon-name" >Completed List</span>}</div>} */}
              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/TransactionHistory") })}>{isSidebarOpen && <span className="icon-name" >Transaction History</span>}</div>} */}




            </div>}
          </div>}

          {/* {mainAccess.includes("WardManagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu4')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserDoctor} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Ward Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu4' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu4' && <div className="subSidebarmenuhover">
                <div onClick={(() => { navigate("/Home/IpRegistrationlist") })}>{isSidebarOpen && <span className="icon-name" >IP Admission List</span>}</div>
                <div onClick={(() => { navigate("/Home/IP-Registration1");dispatchvalue({type:'IpAdmissionEdit',value:null}) })}>{isSidebarOpen && <span className="icon-name" >IP Admission</span>}</div>
                <div onClick={(() => { navigate("/Home/IpNurseQuelist") })}>{isSidebarOpen && <span className="icon-name" >IP Nurse Que List</span>}</div>
                <div onClick={(() => { navigate("/Home/IpDocQuelist") })}>{isSidebarOpen && <span className="icon-name" >IP Doctor Que List</span>}</div>
                <div onClick={(() => { navigate("/Home/Room-Management") })}> {isSidebarOpen && <span className="icon-name" >Room Management</span>}</div> */}
                {/* <div onClick={(() => { navigate("/Home/Ward-Management") })}> {isSidebarOpen && <span className="icon-name" >Ward Management</span>}</div> */}
                {/* <div onClick={(() => { navigate("/Home/OT-Management") })}> {isSidebarOpen && <span className="icon-name" >OT Management</span>}</div>
            </div>}
</div>} */}


    


          {/* {mainAccess.includes("Pharmacy") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu6')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faFileMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Pharmacy</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu6' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu6' && <div className="subSidebarmenuhover">
              {subAccess.includes("PharmacyBilling") && <div onClick={(() => { navigate("/Home/Pharmacy_Billing_List") })}> {isSidebarOpen && <span className="icon-name" >Pharmacy Billing</span>}</div>} */}

              {/* <div onClick={(() => { navigate("/Home/Deu-History-List") })}> {isSidebarOpen && <span className="icon-name" >Due History </span>}</div> */}
              {/* {subAccess.includes("BillCancellation/Refund") && <div onClick={(() => { navigate("/Home/Pharmacy_Billcancelation") })}> {isSidebarOpen && <span className="icon-name" >Bill Cancellation/Refund</span>}</div>}
              {subAccess.includes("BillingHistory") && <div onClick={(() => { navigate("/Home/Billing-History") })}>{isSidebarOpen && <span className="icon-name" >Billing History</span>}</div>}

            </div>}
          </div>} */}


          {/* {mainAccess.includes("Cashier") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu7')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Cashier</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu7' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu7' && <div className="subSidebarmenuhover">
              {subAccess.includes("Billing") && <div onClick={(() => { navigate("/Home/Billing-Invoice") })}> {isSidebarOpen && <span className="icon-name" >Billing </span>}</div>}

              {subAccess.includes("DueHistory") && <div onClick={(() => { navigate("/Home/Deu-History-List") })}> {isSidebarOpen && <span className="icon-name" >Due History </span>}</div>}
              {subAccess.includes("BillCancellation") && <div onClick={(() => { navigate("/Home/Bill-Cancellation") })}> {isSidebarOpen && <span className="icon-name" >Bill Cancellation</span>}</div>}
              {subAccess.includes("BillingHistory") && <div onClick={(() => { navigate("/Home/Billing-History") })}>{isSidebarOpen && <span className="icon-name" >Billing History</span>}</div>}

           
            </div>}
          </div>} */}

          {mainAccess.includes("PettyCash") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu8")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faSackDollar}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Petty Cash</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu8" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu8" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("ExpenseMaster") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Expenses-Master");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Expense Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("CashExpenses") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Cash Expenses </span>
                      )}
                    </div>
                  )}
                  {/* {subAccess.includes("DigitalExpenses") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Digital-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Digital Expenses </span>
                      )}
                    </div>
                  )} */}
                  {/* {subAccess.includes("ExpensesReport") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Expenses Report</span>
                      )}
                    </div>
                  )} */}
                  {/* {subAccess.includes("HandOverSummary") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Hand-Over-Summary");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">HandOver Summary</span>
                      )}
                    </div>
                  )} */}
                  {subAccess.includes("DayReport") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Book-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DayClosing") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Closing");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DayClosing") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DoctorsPayout");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Doctors Payout</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DayClosing") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DoctorsPayoutReport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Doctors Payout Report</span>
                      )}
                    </div>
                  )}
                    {subAccess.includes("DayClosing") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DueBillPayout");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Due Payout </span>
                      )}
                    </div>
                  )}

                </div>
              )}
            </div>
          )}
{!mainAccess.includes("InventoryMasters") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu17')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faSuitcaseMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Lab Inventory Master</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu17' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu17' && <div className="subSidebarmenuhover">

              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabSupplierLists") })}> {isSidebarOpen && <span className="icon-name" >LabSupplier Master </span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabProductMasterList") })}> {isSidebarOpen && <span className="icon-name" >LabProduct Master</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/Productitemmaster") })}> {isSidebarOpen && <span className="icon-name" >Product Type Master</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabPurchaseRaiseList") })}> {isSidebarOpen && <span className="icon-name" >Purchase Raise</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabPurchaseRecieveList") })}> {isSidebarOpen && <span className="icon-name" >GRN List</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabQuickStockMasterCentral") })}> {isSidebarOpen && <span className="icon-name" >Lab QuickStock</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabPurchaseReturnMasterlist") })}> {isSidebarOpen && <span className="icon-name" >Purchase Return</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabSupplierPayCentralStore") })}> {isSidebarOpen && <span className="icon-name" >LabSupplier Pay</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabIndentRaiseList") })}> {isSidebarOpen && <span className="icon-name" >Indent Raise</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabIndentIssueList") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabIndentRecieveList") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabIndentReturnList") })}> {isSidebarOpen && <span className="icon-name" >Indent Return</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabCentralStockList") })}> {isSidebarOpen && <span className="icon-name" >Central StockList</span>}</div>}
              {!subAccess.includes("SLIM22") && <div onClick={(() => { navigate("/Home/LabLocationStock") })}> {isSidebarOpen && <span className="icon-name" >Location StockList</span>}</div>}
              
            </div>}
 </div>}


     

          { mainAccess.includes("Reports") &&   <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu20')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faRegistered} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Reports</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu20' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu20' && <div className="subSidebarmenuhover">
            
            {subAccess.includes("Supplier_Pay_List") && <div onClick={(() => { navigate("/Home/Supplier_Pay_List") })}> {isSidebarOpen && <span className="icon-name" >Supplier Pay List </span>}</div>}
            {subAccess.includes("PurchaseRegister") && <div onClick={(() => { navigate("/Home/PurchaseRegister") })}> {isSidebarOpen && <span className="icon-name" >Purchase Register </span>}</div>}
            {subAccess.includes("SalesRegister") && <div onClick={(() => { navigate("/Home/SalesRegister") })}> {isSidebarOpen && <span className="icon-name" >Sales Register </span>}</div>}

            {subAccess.includes("SalesRegister") && <div onClick={(() => { navigate("/Home/tatreport") })}> {isSidebarOpen && <span className="icon-name" >TAT Report</span>}</div>}
            {subAccess.includes("SalesRegister") && <div onClick={(() => { navigate("/Home/ReferingDoctorReport") })}> {isSidebarOpen && <span className="icon-name" >Refering Doctor List</span>}</div>}
          </div>}
          </div>} 


          {mainAccess.includes("HRManagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu10')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faPenNib} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">HR Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu20' ? 'arrow-rotate' : ''}`}
                />
              )}

            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu10' && <div className="subSidebarmenuhover">
              {!subAccess.includes("HRD") && <div onClick={(() => { navigate("/Home/Hr-DashBoard") })}>{isSidebarOpen && <span className="icon-name" >HR Dashboard</span>}</div>}
              {!subAccess.includes("ERHR1") && <div onClick={(() => { navigate("/Home/Employee-Register"); dispatchvalue({ type: 'foremployeeedit', value: [] }) })}>{isSidebarOpen && <span className="icon-name" >Employee Register</span>}</div>}
              {!subAccess.includes("ELHR2") && <div onClick={(() => { navigate("/Home/Employee-List") })}>{isSidebarOpen && <span className="icon-name" >Employee List</span>}</div>}
              {!subAccess.includes("AHR3") && <div onClick={(() => { navigate("/Home/Employee-Attendance") })}>{isSidebarOpen && <span className="icon-name" >Attendance</span>}</div>}
              {!subAccess.includes("LAHR4") && <div onClick={(() => { navigate("/Home/Employee-LeaveApproval") })}> {isSidebarOpen && <span className="icon-name" >Leave Approval </span>}</div>}
              {!subAccess.includes("AAHR5") && <div onClick={(() => { navigate("/Home/Employee-AdvanceApproval") })}> {isSidebarOpen && <span className="icon-name" >Advance Approval </span>}</div>}
              {!subAccess.includes("PAHR6") && <div onClick={(() => { navigate("/Home/Employee-Performance") })}> {isSidebarOpen && <span className="icon-name" >Performance Appraisal </span>}</div>}
              {!subAccess.includes("PMHR7") && <div onClick={(() => { navigate("/Home/Employee-PerformanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Performance Management </span>}</div>}
              {!subAccess.includes("LMHR8") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {!subAccess.includes("AMHR9") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}
              {!subAccess.includes("PRHR10") && <div onClick={(() => { navigate("/Home/Employee-PayRoll") })}> {isSidebarOpen && <span className="icon-name" >Pay Roll </span>}</div>}
              {!subAccess.includes("DM11") && <div onClick={(() => { navigate("/Home/Duty-Management") })}> {isSidebarOpen && <span className="icon-name" >Duty Management</span>}</div>}
              {!subAccess.includes("HRJR") && <div onClick={(() => { navigate("/Home/Job-Requirements") })}> {isSidebarOpen && <span className="icon-name" >Job Requirements </span>}</div>}
              {!subAccess.includes("HRCTUL") && <div onClick={(() => { navigate("/Home/Consultancy-Typeup-List") })}>{isSidebarOpen && <span className="icon-name" >Consultancy Master</span>}</div>}
              {!subAccess.includes("RQHRLIST") && <div onClick={(() => { navigate("/Home/RequestTo-HrManagementList") })}> {isSidebarOpen && <span className="icon-name" >Request To HR List </span>}</div>}
              {!subAccess.includes("MFCONSUL") && <div onClick={(() => { navigate("/Home/MailFrom-Consultancy") })}> {isSidebarOpen && <span className="icon-name" >Mail From Consultancy </span>}</div>}
              {!subAccess.includes("HRSRL") && <div onClick={(() => { navigate("/Home/Shortlisted-Resume-list") })}> {isSidebarOpen && <span className="icon-name" >Interview Schedule </span>}</div>}

            </div>}
          </div>
}

          {/* {mainAccess.includes("VisitingDoctor") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu11')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserNinja} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Visiting Doctor</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu11' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu11' && <div className="subSidebarmenuhover">


              {subAccess.includes("VisitingDoctorPatients") && <div onClick={(() => { navigate("/Home/Patient-Visiting-Doctor") })}> {isSidebarOpen && <span className="icon-name" >Visiting Doctor Patients </span>}</div>}
            </div>}
          </div>} */}

         {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu12')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserTag} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Employee Request</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu12' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu12' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/Navigation-leave") })}> {isSidebarOpen && <span className="icon-name" >Leave Management</span>}</div>}
            {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
               {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/Navigation-Advance") })}>{isSidebarOpen && <span className="icon-name" >Advance Management</span>}</div>} 
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Shift-Details") })}>{isSidebarOpen && <span className="icon-name" >Shift Management</span>}</div>} */}



           </div>}
          </div>} 

          {mainAccess.includes("UserControl") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu13')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faChartBar} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Masters</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu13' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu13' && <div className="subSidebarmenuhover">
              {subAccess.includes("RoleManagement") && <div onClick={(() => { navigate("/Home/Role-Management") })}>{isSidebarOpen && <span className="icon-name" >Role Management </span>}</div>}
              {subAccess.includes("EmployeeQueueList") && <div onClick={(() => { navigate("/Home/EmployeeQue-List") })}> {isSidebarOpen && <span className="icon-name" >Employee Queue List</span>}</div>}
              {subAccess.includes("UserRegister") && <div onClick={(() => { navigate("/Home/Register-User") })}> {isSidebarOpen && <span className="icon-name" > User Register</span>}</div>}
              {/* {subAccess.includes("RatecardCharges") && <div onClick={(() => { navigate("/Home/Doctor Consulation Charges") })}> {isSidebarOpen && <span className="icon-name" >Ratecard Charges </span>}</div>} */}
              {subAccess.includes("AccountSettings") && <div onClick={(() => { navigate("/Home/Account Settings") })}> {isSidebarOpen && <span className="icon-name" >Account Settings </span>}</div>}
              {subAccess.includes("ClinicDetails") && <div onClick={(() => { navigate("/Home/Clinic Details") })}> {isSidebarOpen && <span className="icon-name" >Clinic Details </span>}</div>}
              {subAccess.includes("UserList") && <div onClick={(() => { navigate("/Home/User-List") })}> {isSidebarOpen && <span className="icon-name" >User List </span>}</div>}
              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}
              {/* {subAccess.includes("VisitDoctorBillinglist") && <div onClick={(() => { navigate("/Home/Visit-Doctor-Billing-List") })}> {isSidebarOpen && <span className="icon-name" >Visit Doctor Billing List</span>}</div>} */}
              {/* {!subAccess.includes("RoomMaster") && <div onClick={(() => { navigate("/Home/Room_Master") })}>{isSidebarOpen && <span className="icon-name" >Room Master </span>}</div>} */}
              {/* {!subAccess.includes("RoomMaster") && <div onClick={(() => { navigate("/Home/Room_Create_  Master") })}>{isSidebarOpen && <span className="icon-name" >Room Master Create </span>}</div>} */}
              
              {/* {!subAccess.includes("Ipratecard") && <div onClick={(() => { navigate("/Home/IPratecard") })}>{isSidebarOpen && <span className="icon-name" >IP Ratecard </span>}</div>} */}
              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/DepartmentsMasters") })}> {isSidebarOpen && <span className="icon-name" >Department Management</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/Units") })}>{isSidebarOpen && <span className="icon-name" >Units Management</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Container") })}>{isSidebarOpen && <span className="icon-name" >Container Management</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Specimen") })}>{isSidebarOpen && <span className="icon-name" >Specimen Management</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Methods") })}>{isSidebarOpen && <span className="icon-name" >Methods Management</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Patient-group") })}>{isSidebarOpen && <span className="icon-name" >Patient-group</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/OrganismMaster") })}>{isSidebarOpen && <span className="icon-name" >Organism Master</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Antibioticmaster") })}>{isSidebarOpen && <span className="icon-name" >Antibiotic Master</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/AntibioticGroupmaster") })}>{isSidebarOpen && <span className="icon-name" >AntibioticGroup Master</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/AssetTypemaster") })}>{isSidebarOpen && <span className="icon-name" >AssetType Master</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/EquipmentsMaster") })}>{isSidebarOpen && <span className="icon-name" >Equipments Master</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/ExternalLabmaster") })}>{isSidebarOpen && <span className="icon-name" >ExternalLab Master</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/ExternalLabmasterList") })}>{isSidebarOpen && <span className="icon-name" >ExternalLab List</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/TestMastersNavigation") })}>{isSidebarOpen && <span className="icon-name" >Test Masters</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/TestmasterList") })}>{isSidebarOpen && <span className="icon-name" >TestMasters List</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/GroupMaster") })}>{isSidebarOpen && <span className="icon-name" >Group Master</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/GroupMasterList") })}>{isSidebarOpen && <span className="icon-name" >Group Master List</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Referdoctormaster") })}>{isSidebarOpen && <span className="icon-name" >Refer Doctor </span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Outsourceratecard") })}>{isSidebarOpen && <span className="icon-name" >Outsource Ratecard</span>}</div>}
              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Ratecardlims") })}>{isSidebarOpen && <span className="icon-name" >Ratecard Master</span>}</div>}


            </div>}
          </div>

          }

{mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu3')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
            <FontAwesomeIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />

              {isSidebarOpen && <span className="icon-name"> Radiology</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu3' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu3' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/RadiologyMaster") })}> {isSidebarOpen && <span className="icon-name" >Radiology Master</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {/* {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/FranchiseMaster") })}>{isSidebarOpen && <span className="icon-name" >Franchise Master</span>}</div>} */}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/SamplelistData") })}>{isSidebarOpen && <span className="icon-name" >Completed List</span>}</div>} */}
              {/* {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/TransactionHistory") })}>{isSidebarOpen && <span className="icon-name" >Transaction History</span>}</div>} */}




        </div>}
          </div>}   

{/* {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu15')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
            <GridViewIcon className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Masters</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu15' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu15' && <div className="subSidebarmenuhover">

             

              






            </div>}
          </div>} */}





    {/* {mainAccess.includes("Accounts") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu14")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  className="inventory_sidebar_icon"
                />
               {isSidebarOpen && <span className="icon-name">Accounts</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${
                      openSubMenu === "subsidebarmenu14" ? "arrow-rotate" : ""
                    }`}
                  />
                )}
              </div>

              {isSidebarOpen && openSubMenu === "subsidebarmenu14" && (
                
                <div className="subSidebarmenuhover">
                  <div onClick={(() => { navigate("/Home/AccountGroupList") })}>{isSidebarOpen && <span className="icon-name" >Account Group List</span>}</div>

                  {subAccess.includes("Addaccounts") && (
                    <div
                      onClick={() => {
                        navigate("/Home/LedgerList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name"> Ledger Entry </span>
                      )}
                    </div>
                  )}
                  <div onClick={(() => { navigate("/Home/ContraVoucherList") })}>{isSidebarOpen && <span className="icon-name" >Contra Voucher</span>}</div>
                  {subAccess.includes("Paymentvoucher") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PaymentVoucherList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Payment Voucher </span>
                      )}
                    </div>
                  )}
                   {subAccess.includes("ReceiptvoucherList") && <div onClick={(() => { navigate("/Home/ReceiptVoucherList") })}>{isSidebarOpen && <span className="icon-name" >Receipt Voucher</span>}</div>}
                  {subAccess.includes("JournalentryList") &&<div onClick={(() => { navigate("/Home/JournalentryList") })}>{isSidebarOpen && <span className="icon-name" >Journal Entry</span>}</div>}
                  {subAccess.includes("DayBook") &&<div onClick={(() => { navigate("/Home/DayBook") })}>{isSidebarOpen && <span className="icon-name" >Day Book</span>}</div>}
                  {subAccess.includes("TrialBalance") &&<div onClick={(() => { navigate("/Home/TrialBalance") })}>{isSidebarOpen && <span className="icon-name" >Trial Balance</span>}</div>}   
                  {subAccess.includes("ProfitandLoss") &&<div onClick={(() => { navigate("/Home/ProfitandLoss") })}>{isSidebarOpen && <span className="icon-name" >Profit & Loss</span>}</div>}
                  {subAccess.includes("CashbookandBankbook") &&<div onClick={(() => { navigate("/Home/CashbookandBankbook") })}>{isSidebarOpen && <span className="icon-name" >Cashbook & Bankbook</span>}</div>}                  
                  {subAccess.includes("BalanceSheet") &&<div onClick={(() => { navigate("/Home/BalanceSheet") })}>{isSidebarOpen && <span className="icon-name" >Balance Sheet</span>}</div>}
                </div>
              )}
            </div>
        )} */}
          <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} onClick={handleLogoutClick} id='font_111'>
            <FontAwesomeIcon icon={faRightFromBracket} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" >Logout</span>}
          </div>

        </div>
      </div>
    </nav >
  );
};

export default Sidebar;
