import React, { useState, useEffect } from "react";
import "./TreatmentComponent.css";
import Axios from "axios";
import Canva from "./Canva";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

function Treatment() {
  const getformData = useSelector(
    (state) => state.userRecord?.workbenchformData
  );
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const isnewSidebarOpen = useSelector(
    (state) => state.userRecord?.isSidebarOpen
  );

  const create = userRecord?.username;
  const Location = userRecord?.location;

  const [selectedFile, setSelectedFile] = useState(null);

  const [diagnosis_list, setdiagnosis_list] = useState([]);
  const [diagnosis_list_1, setdiagnosis_list_1] = useState([]);

  const [ICD_Code, setICD_Code] = useState("");
  const [ICD_Name, setICD_Name] = useState(""); //diagnosis_list
  const [Description, setDescription] = useState(""); //diagnosis_list

  const [showcamera, setshowcamera] = useState(false);
  const [orignalImagecanva, setoriginalImagecanva] = useState(null);
  const [mergedImagecanva, setmergedImagecanva] = useState(null);
  const [Complaint, setComplaint] = useState("");

  const [historyValue, setHistoryValue] = useState("");
  const [examinationValue, setExaminationValue] = useState("");
  const [diagnosisValue, setDiagnosisValue] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  useEffect(() => {
    if (ICD_Code) {
      Axios.get(`${urllink}doctorsworkbench/Diagnosis_list/${ICD_Code}`)
        .then((response) => {
          const data = response.data.data; //remove dublicate write loop
          if (data) {
            setdiagnosis_list(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching treatment data:", error);
        });
    }
  }, [ICD_Code]);

  useEffect(() => {
    // Check if ICD_Name is available
    if (ICD_Name) {
      Axios.get(
        `${urllink}doctorsworkbench/Diagnosis_list_ICD_Name/${ICD_Name}`
      )
        .then((response) => {
          const data = response.data.data;
          if (data) {
            setdiagnosis_list_1(data);
          }
        })
        .catch((error) => {
          console.error("Error fetching treatment data:", error);
        });
    }
  }, [ICD_Name]);

  useEffect(() => {
    if (diagnosis_list.length === 1) {
      const newdata = diagnosis_list.find((p) => p.ICD_10_Code === ICD_Code);
      if (newdata) {
        setICD_Name(newdata.Diagnosis);
      } else {
        setICD_Name("");
      }
    }
  }, [diagnosis_list.length, ICD_Code, diagnosis_list]);

  useEffect(() => {
    if (diagnosis_list_1.length === 1) {
      const newdata = diagnosis_list_1.find((p) => p.Diagnosis === ICD_Name);
      if (newdata) {
        setICD_Code(newdata.ICD_10_Code);
      } else {
        setICD_Code("");
      }
    }
  }, [diagnosis_list_1.length, ICD_Name, diagnosis_list_1]);

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);

    const patientid = getformData.PatientID;
    const visitNo = getformData.visitNo;

    Axios.get(
      `${urllink}doctorsworkbench/get_treatment?patientid=${patientid}&visitid=${visitNo}&location=${Location}`
    )
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const matchedData = response.data.find(
            (item) => item.PatientID === getformData.PatientID
          );

          if (matchedData) {
            setICD_Code(matchedData.ICD_10_Code);
            setICD_Name(matchedData.ICD_Diagnosis);
            setDescription(matchedData.Description);
            setHistoryValue(matchedData.History);
            setExaminationValue(matchedData.Examination);
            setDiagnosisValue(matchedData.Diagnosis);
            setoriginalImagecanva(matchedData.OriginalImageURL);
            setoriginalImagecanva(
              `data:image/jpeg;base64,${matchedData.Original_Image}`
            );
            setmergedImagecanva(
              `data:image/jpeg;base64,${matchedData.Anotated_Medical_Image}`
            );
          } else {
          }
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [appointmentDate, getformData]);

  // Convert a data URI to a Blob

  const handleShowcamera = () => {
    setshowcamera(true);
  };
  const handlehidecamera = () => {
    setshowcamera(false);
  };

  const handleSave = () => {
    // Log Blobs for verification
    const MY_datatosend = {
      Diagnosis_Name: ICD_Name,
      ICD_10_Code: ICD_Code,
      history: historyValue,
      Description: Description,
      examination: examinationValue,
      diagnosis: diagnosisValue,
      PatientID: getformData.PatientID,
      appointmentDate: appointmentDate,
      AppointmentID: getformData.SerialNo,
      visitNo: getformData.visitNo,
      createdBy: create,
      location: Location,
      original_image: dataURItoBlob(orignalImagecanva),
      annotated_medical_image: dataURItoBlob(mergedImagecanva),
    };

    const BackformData = new FormData();

    for (const key in MY_datatosend) {
      if (MY_datatosend.hasOwnProperty(key)) {
        BackformData.append(key, MY_datatosend[key]);
      }
    }

    const url = `${urllink}doctorsworkbench/insert_workbench_treatment`;

    Axios.post(url, BackformData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        successMsg("Saved Succefully");
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error Occured");
      });
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Use fetch to send the formData to your server
      fetch(`${urllink}doctorsworkbench/Diagnosis_csvto_sql_link`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          successMsg("File processed successfully");
        })
        .catch((error) => {
          console.error("Error:", error);

          errmsg("An error occurred while processing the file");
        });
    } else {
      warnmsg("Please select a file first");
    }
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const warnmsg = (warnmessage) => {
    toast.warn(`${warnmessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="treatment_total_container">
        <div className="treatment_container">
          <div className="treatcon_1">
            <div className="treatcon_body">
              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="history">
                  Chief Complaints <span>:</span>
                </label>
                <textarea
                  id="Complaint"
                  name="Complaint"
                  cols="25"
                  rows="3"
                  value={getformData.Complaint}
                  onChange={(e) => setComplaint(e.target.value)}
                ></textarea>
              </div>

              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="history">
                  History <span>:</span>
                </label>
                <textarea
                  id="history"
                  name="history"
                  cols="25"
                  rows="3"
                  value={historyValue}
                  onChange={(e) => setHistoryValue(e.target.value)}
                ></textarea>
              </div>
              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="examination">
                  Examination <span>:</span>
                </label>
                <textarea
                  id="examination"
                  name="examination"
                  cols="25"
                  rows="3"
                  value={examinationValue}
                  onChange={(e) => setExaminationValue(e.target.value)}
                ></textarea>
              </div>
              <div className="treatcon_body_1 with-icd-his">
                <label htmlFor="diagnosis">
                  Diagnosis <span>:</span>
                </label>
                <textarea
                  id="diagnosis"
                  name="diagnosis"
                  cols="25"
                  rows="3"
                  value={diagnosisValue}
                  onChange={(e) => setDiagnosisValue(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>

          {orignalImagecanva && (
            <div className="treatcon_2">
              <div className="treatcon_image">
                <div className="treatcon_image_1">
                  <img
                    src={orignalImagecanva}
                    alt="OriginalImage"
                    onError={(e) => console.error("Error loading image:", e)}
                  />
                </div>
                <div className="treatcon_label">
                  <label htmlFor="name">Original Image</label>
                </div>
              </div>
              <div className="treatcon_image anoted_img">
                <div className="treatcon_image_1">
                  <img
                    src={mergedImagecanva}
                    alt="Anotatedimage"
                    onError={(e) => console.error("Error loading image:", e)}
                  />
                </div>
                <div className="treatcon_label anot-withhh">
                  <label htmlFor="name">Anotated Medical Image</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <br /> */}
      <div className="treatment_buttons">
        <button className="RegisterForm_1_btns" onClick={handleSave}>
          save
        </button>
        <button className="RegisterForm_1_btns" onClick={handleShowcamera}>
          capture
        </button>
      </div>
      {showcamera && (
        <div
          className={isnewSidebarOpen ? "sideopen_showcamera" : "showcamera"}
          onClick={handlehidecamera}
        >
          <div
            className={
              isnewSidebarOpen ? "sideopen_showcamera_1" : "showcamera_1"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <Canva
              setoriginalImagecanva={setoriginalImagecanva}
              setmergedImagecanva={setmergedImagecanva}
              setshowcamera={setshowcamera}
            />
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default Treatment;
