import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function VisitingDoctor1() {
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [ProcedureName, setProcedureName] = useState([]);
  const navigate = useNavigate();

  const [VisitingDoctorName, setVisitingDoctorName] = useState([]);

  const [formDetails, setformDetails] = useState({
    doctorName: "",
    phoneNumber: "",
    email: "",
    location: "",
    specialist: "",
    experience: "",
    ProcedureName: "",
  });

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;

    if (/^\d{0,10}$/.test(inputPhoneNumber)) {
      setformDetails({ ...formDetails, phoneNumber: inputPhoneNumber });
    } else {
      alert("Phone Number must contain 10 digits");
    }
  };

  const handleSubmit = (e) => {
    const form = new FormData();

    for (const key in formDetails) {
      if (formDetails.hasOwnProperty(key)) {
        form.append(key, formDetails[key]);
      }
    }
    form.append("branchlocation", userRecord?.location);
    form.append("createdby", userRecord?.username);
    form.append("patientID", formData.PatientID);
    form.append("patientName", formData.firstName + "" + formData.lastName);
    form.append("visitid", formData.visitNo);
    form.append("AssignedBy", formData.DoctorName);

    e.preventDefault();

    axios
      .post(`${urllink}VisitingDoctor/insert_VisitingDoctor_PatientInfo`, form)
      .then((response) => {
        successMsg("Saved Successfully");
        setformDetails([]);
        navigate("/Home/Treament-QueueList");
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error occured");
      });
  };

  useEffect(() => {
    axios
      .get(`${urllink}VisitingDoctor/getVisitingDoctornames`)
      .then((response) => {
        setVisitingDoctorName(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(
        `${urllink}doctorsworkbench/get_procedurename_fromratecard?location=${userRecord?.location}`
      )
      .then((response) => {
        setProcedureName(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord?.location]);

  const handleDoctorChange = (e) => {
    const selectedDoctor = e.target.value;
    const doctorDetails = VisitingDoctorName.find(
      (doctor) => doctor.VisitingDoctor_Name === selectedDoctor
    );
    if (doctorDetails) {
      setformDetails({
        ...formDetails,
        doctorName: selectedDoctor,
        phoneNumber: doctorDetails.PhoneNumber,
        email: doctorDetails.Email,
        location: doctorDetails.VisitingDoctor_Location,
        specialist: doctorDetails.Specialist,
        experience: doctorDetails.Experience,
      });
    } else {
      setformDetails({
        ...formDetails,
        doctorName: selectedDoctor,
        phoneNumber: "",
        email: "",
        location: "",
        specialist: "",
        experience: "",
      });
    }
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  return (
    <>
      <div className="appointment">
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label>
              Visit Doctor <span> Dr.</span>
            </label>
            <input
              name="doctorName"
              value={formDetails.doctorName}
              onChange={handleDoctorChange}
              required
              list="browsers"
            />
            {VisitingDoctorName && (
              <datalist id="browsers">
                {VisitingDoctorName.map((item, index) => (
                  <option key={index + 1} value={`${item.VisitingDoctor_Name}`}>
                    Specialist: {item.Specialist}
                    {","}
                    <br />
                    Experience: {item.Experience}
                  </option>
                ))}
              </datalist>
            )}
          </div>

          <div className="RegisForm_1">
            <label>
              Phone Number <span>:</span>
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={formDetails.phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Email <span>:</span>
            </label>
            <input
              type="text"
              name="email"
              value={formDetails.email}
              onChange={(e) =>
                setformDetails({ ...formDetails, email: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Location <span>:</span>
            </label>
            <input
              type="text"
              name="location"
              value={formDetails.location}
              onChange={(e) =>
                setformDetails({ ...formDetails, location: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Specialist <span>:</span>
            </label>
            <input
              type="text"
              name="specialist"
              value={formDetails.specialist}
              onChange={(e) =>
                setformDetails({ ...formDetails, specialist: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Experience <span>:</span>
            </label>
            <input
              type="text"
              name="experience"
              value={formDetails.experience}
              onChange={(e) =>
                setformDetails({ ...formDetails, experience: e.target.value })
              }
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProcedureName">
              Select Procedure<span>:</span>
            </label>
            <select
              id="ProcedureName"
              name="ProcedureName"
              value={formDetails.ProcedureName}
              onChange={(e) =>
                setformDetails({
                  ...formDetails,
                  ProcedureName: e.target.value,
                })
              }
            >
              <option value="">Select </option>
              {Array.isArray(ProcedureName) ? (
                ProcedureName.map((procedure, index) => (
                  <option key={index} value={procedure}>
                    {procedure}
                  </option>
                ))
              ) : (
                <option disabled>No procedure available</option>
              )}
            </select>
          </div>
        </div>

        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}

export default VisitingDoctor1;
