import React, { useEffect, useState } from "react";
import "./ClinicMetrics.css";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart, Pie, Cell } from "recharts";
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  XAxis,
  Legend,
} from "recharts";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
import { backdropClasses, colors } from "@mui/material";
import { useSelector } from 'react-redux';

const Input = styled(MuiInput)`
  width: 30px;
  &.MuiInput-underline {
    &:before,
    &:after {
      border: none;
    }
  }
  &:hover.MuiInput-underline:before {
    border-bottom: none;
  }
`;

const StyledSlider = styled(Slider)`
  height: 12px;
  & .MuiSlider-track {
    background-color: var(--ProjectColor);
  }
`;
const ClinicMetrics = () => {
  // const diseasesData = [
  //   { name: "Disease A", percentage: 60 },
  //   { name: "Disease B", percentage: 75 },
  //   { name: "Disease C", percentage: 45 },
  //   { name: "Disease C", percentage: 45 },
  //   { name: "Disease C", percentage: 45 },
  //   { name: "Disease C", percentage: 45 },
  // ];



  const userRecord = useSelector(state=>state.userRecord?.UserData);

  const isSidebarOpen=useSelector(state=>state.userRecord?.isSidebarOpen)


  const [openModel ,setOpenModel] = useState(false)
  const [highlighted] = React.useState("series");
  const [faded] = React.useState("global");
  const [alignment, setAlignment] = React.useState("Weekly");

  const [genderdata, setgenderdata] = useState([null]);
  const [patientoverview, setpatientoverview] = useState([null]);
  const [overalldata, setoveralldata] = useState([null]);
  const [dataline, setdataline] = useState([]);
  const [barChartsParamsappointment, setbarChartsParamsappointment] =
    useState(null);
  // const [data2,setdata2] = useState([])

  const [total, settotal] = useState([]);
  const [booked, setbooked] = useState([]);
  const [canceled, setcanceled] = useState([]);

  const [barChartsParams, setbarChartsParams] = useState([]);

  const [diseasesData, setDiseasesData] = useState([]);
  const [diseases, setDiseases] = useState([]);

  const [totalcollection, settotalcollection] = useState([]);
  const [overallrefundcollection, setoverallrefundcollection] = useState([]);



  const handleChange = (event) => {
    setAlignment(event.target.value);
    console.log(alignment);
  };

  console.log("patientoverview :", patientoverview);

  console.log(alignment);
  console.log(overalldata[0]);
  




  useEffect(() => {
    const location = userRecord?.location;
    axios
      .get(
        `http://127.0.0.1:8000/clinicmetrics/get_patient_count?time_frame=${alignment}&location=${location}`
      )
      .then((response) => {
        console.log(response.data[0]);
        setpatientoverview([response.data[1]]);
        setgenderdata([response.data[2]]);
        setoveralldata(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `http://127.0.0.1:8000/clinicmetrics/get_for_visitors?time_frame=${alignment}&location=${location}`
      )
      .then((response) => {
        console.log(response.data[0]);
        setdataline(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `http://127.0.0.1:8000/clinicmetrics/get_for_appoinments?time_frame=${alignment}&location=${location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data[0][0].xaxis;
        settotal(response.data[0][0].data);
        setbooked(response.data[0][1].data);
        setcanceled(response.data[0][2].data);

        setbarChartsParamsappointment(data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `http://127.0.0.1:8000/clinicmetrics/get_for_disease?time_frame=${alignment}&location=${location}`
      )
      .then((response) => {
        console.log(response.data);
        setDiseasesData(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `http://127.0.0.1:8000/clinicmetrics/get_for_inventry?time_frame=${alignment}&location=${location}`
      )
      .then((response) => {
        console.log(response.data);
        setbarChartsParams({ series: response.data[0] });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `http://127.0.0.1:8000/clinicmetrics/get_for_collection?time_frame=${alignment}&location=${location}`
      )
      .then((response) => {
        console.log(response.data);
        console.log(response.data[0].combined_total_collection);
        settotalcollection(response.data[0].combined_total_collection);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `http://127.0.0.1:8000/clinicmetrics/get_for_refund?time_frame=${alignment}&location=${location}`
      )
      .then((response) => {
        console.log(response.data);
        setoverallrefundcollection(response.data[0].overallrefundcollection);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [alignment, userRecord]);

  console.log(dataline);

  useEffect(() => {
    // Check if diseasesData is iterable (an array)
    if (Array.isArray(diseasesData)) {
      // If it's an array, sort it and update the state
      const sortedDiseases = [...diseasesData].sort(
        (a, b) => b.percentage - a.percentage
      );
      setDiseases(sortedDiseases);
    } else {
      // Handle the case where diseasesData is not an iterable (e.g., not an array)
      // You might want to log an error, set an empty array, or handle it in a way that makes sense for your application.
      console.error("diseasesData is not an iterable array:", diseasesData);
      // For example, you could set an empty array:
      setDiseases([]);
    }
  }, [diseasesData]);

  //for pie chart for gender overview

  // const genderdata = [
  //     { name: 'Male', count: 25 },
  //     { name: 'Female', count: 40 },
  //     // Add more data as needed
  // ];

  //for pie chart
  //   const data = [
  //     { name: "Child", count: 25 },
  //     { name: "Teen", count: 40 },
  //     { name: "Adult", count: 65 },
  //     { name: "Older", count: 15 },
  //     // Add more data as needed
  //   ];
  // for bar chart for the inventory movement
  // const barChartsParams = {
  //   series: [
  //     {
  //       data: [3, 4, 4],
  //       label: "Purchases",
  //       itemnames: ["Motrin Tablets", "Panadol", "Tylenol"],
  //       xaxis: [2021, 2022, 2023],
  //     },
  //     {
  //       data: [4, 3, 6],
  //       label: "Sales",
  //       itemnames: ["Motrin Tablets", "Panadol", "Tylenol"],
  //       xaxis: [2021, 2022, 2023],
  //     },
  //     {
  //       data: [2, 2, 5],
  //       label: "Stock",
  //       itemnames: ["Motrin Tablets", "Panadol", "Tylenol"],
  //       xaxis: [2021, 2022, 2023],
  //     },
  //   ],
  // };
  // for bar chart for the appointment status

  // console.log(barChartsParams)
  // const barChartsParamsappointment = {
  //   series: [
  //     { data: [3, 4, 4, 6, 5], label: "Total" },
  //     { data: [4, 3, 6, 5, 8], label: "Booked" },
  //     { data: [2, 2, 5, 4, 1], label: "Canceled" },
  //   ],
  // };

  //for line chart
  //   const dataline = [
  //     { name: "Category 1", count: 30 },
  //     { name: "Category 2", count: 40 },
  //     { name: "Category 3", count: 15 },
  //     { name: "Category 5", count: 12 },
  //     { name: "Category 4", count: 31 },
  //   ];

  // const handleInputChange = (event, index) => {
  //   const newValue = event.target.value === "" ? 0 : Number(event.target.value);
  //   const updatedDiseases = [...diseases];
  //   updatedDiseases[index].percentage = newValue;
  //   setDiseases(updatedDiseases);
  // };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const count = payload[0].value; // Assuming there's only one payload item
      return (
        <div className="custom-tooltip">
          <p>{`Count: ${count}`}</p>
        </div>
      );
    }

    return null;
  };

  const COLORS = ["orange", "blue", "violet", "green"];

  const CustomTooltipPiechart = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const dataEntry = payload[0].payload;
      return (
        <div className="custom-tooltip">
          {dataEntry.name}: {dataEntry.count}
        </div>
      );
    }

    return null;
  };

  //   const data = [
  //     { name: 'Child', count: patientoverview[0]?.child_count },
  //     { name: 'Teen', count: patientoverview[0]?.teen_count },
  //     { name: 'Adult', count: patientoverview[0]?.adult_count },
  //     { name: 'Older', count: patientoverview[0]?.older_count },
  //   ];

  const data = [
    { name: "Child", count: patientoverview[0]?.child_count },
    { name: "Teen", count: patientoverview[0]?.teen_count },
    { name: "Adult", count: patientoverview[0]?.adult_count },
    { name: "Older", count: patientoverview[0]?.older_count },
  ];


  const handlepaymentview = () =>{
    setOpenModel(!openModel)
  }

  return (
    <>
      <div className="Chart_view_container">
        <div className="h_head">
          <h4>Dashboard</h4>
        </div>
        <div className="chart_body_1_head">
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="Weekly"
              style={{
                width: "100px",
                backgroundColor:
                  alignment === "Weekly" ? "var(--ProjectColor)" : "inherit",
              }}
              className="togglebutton_container"
            >
              Weekly
            </ToggleButton>
            <ToggleButton
              value="Monthly"
              style={{
                backgroundColor:
                  alignment === "Monthly" ? "var(--ProjectColor)" : "inherit",
                width: "100px",
              }}
              className="togglebutton_container"
            >
              Monthly
            </ToggleButton>
            <ToggleButton
              value="Yearly"
              style={{
                width: "100px",
                backgroundColor:
                  alignment === "Yearly" ? "var(--ProjectColor)" : "inherit",
              }}
              className="togglebutton_container"
            >
              Yearly
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="chart_body_1">
          <div className="chart_body_1_main">
            <div className="chart_body_1_main_1">
              <div className="chart_body_1_main_1_container">
                <div className="chart_body_1_child_1">
                  <div className="chart_body_1_child_1_body">
                    <div className="chart_body_1_child_1_body_icon">
                      <PersonAddAlt1Icon />
                    </div>
                    <div className="chart_body_1_child_1_body_count">
                      <h3>{overalldata[4]?.new_patient_count ?? "0"}</h3>
                    </div>
                    <div className="chart_body_1_child_1_body_name">
                      New Patients
                    </div>
                  </div>
                </div>
                <div className="chart_body_1_child_1">
                  <div className="chart_body_1_child_1_body">
                    <div className="chart_body_1_child_1_body_icon">
                      <PersonIcon />
                    </div>
                    <div className="chart_body_1_child_1_body_count">
                      <h3>{overalldata[4]?.old_patient_count ?? "0"}</h3>
                    </div>
                    <div className="chart_body_1_child_1_body_name">
                      Follow-up Patients
                    </div>
                  </div>
                </div>
              </div>

              <div className="chart_body_1_main_1_container">
                <div className="chart_body_1_child_1">
                  <div className="chart_body_1_child_1_body">
                    <div className="chart_body_1_child_1_body_icon">
                      <Diversity1Icon />
                    </div>
                    <div className="chart_body_1_child_1_body_count">
                      <h3>{overalldata[4]?.overall_patient_count ?? "0"}</h3>
                    </div>
                    <div className="chart_body_1_child_1_body_name">
                      Total Patient Count
                    </div>
                  </div>
                </div>
                <div className="chart_body_1_child_1 for_over_pie">
                  <div className="chart_body_1_child_1_body">
                    <div className="chart_body_1_child_1_body_chart">
                      <div className="chart_body_1_child_1_body_count">
                        <h3>
                          <span
                            style={{
                              color: "blue",
                              width: "10px",
                              border: "1px solid orange",
                            }}
                          ></span>{" "}
                          Female
                        </h3>

                        <h3>
                          {" "}
                          <span
                            style={{
                              color: "orange",
                              width: "5px",
                              borderRadius: "2px",
                              border: "1px solid blue",
                            }}
                          ></span>
                          Male{" "}
                        </h3>
                      </div>
                      <div className="overview_piechart">
                        <PieChart width={200} height={50}>
                          {genderdata && genderdata.length > 0 ? (
                            <Pie
                              dataKey="value"
                              data={Object.entries(genderdata[0] || {}).map(
                                ([key, value]) => ({
                                  name: key,
                                  value: +value,
                                })
                              )}
                              cx="50%"
                              cy="50%"
                              outerRadius={80}
                              fill="#8884d8"
                            >
                              {Object.keys(genderdata[0] || {}).map(
                                (key, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                )
                              )}
                            </Pie>
                          ) : (
                            <text
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fontSize="12"
                            >
                              No data available for Pie Chart
                            </text>
                          )}
                          <Tooltip formatter={(value) => value} />
                        </PieChart>
                      </div>
                    </div>
                    <div className="chart_body_1_child_1_body_name">
                      Gender Overview
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart_body_1_main_2">
              <div className="chart_body_1_main_2_body">
                <div className="chart_body_1_main_2_body_head">
                  <h3>Patient Overview</h3>
                </div>
                <div className="chart_body_1_main_2_body_body">
                  <div className="chart_body_1_main_2_body_body1">
                    <div className="chart_child_count_1">
                      <div
                        className="chart_child_color"
                        style={{ backgroundColor: COLORS[0] }}
                      ></div>
                      <div className="chart_child_name">
                        {data[0]?.name || ""}
                      </div>
                      <div className="chart_child_count">
                        {data[0].count || "0"}
                      </div>
                    </div>
                    <div className="chart_child_count_1">
                      <div
                        className="chart_child_color"
                        style={{ backgroundColor: COLORS[1] }}
                      ></div>
                      <div className="chart_child_name">
                        {data[1]?.name || ""}
                      </div>
                      <div className="chart_child_count">
                        {data[1]?.count || "0"}
                      </div>
                    </div>
                  </div>
                  <div className="chart_body_1_main_2_body_body1">
                    <div className="chart_child_count_1">
                      <div
                        className="chart_child_color"
                        style={{ backgroundColor: COLORS[2] }}
                      ></div>
                      <div className="chart_child_name">
                        {data[2]?.name || ""}
                      </div>
                      <div className="chart_child_count">
                        {data[2]?.count || "0"}
                      </div>
                    </div>
                    <div className="chart_child_count_1">
                      <div
                        className="chart_child_color"
                        style={{ backgroundColor: COLORS[3] }}
                      ></div>
                      <div className="chart_child_name">
                        {data[3]?.name || ""} {data[3]?.count || "0"}
                      </div>
                      <div className="chart_child_count"></div>
                    </div>
                  </div>
                  <div className="chart_body_1_main_2_body_pie">
                    <PieChart width={200} height={200}>
                      {data && data.length > 0 ? (
                        <Pie
                          dataKey="count"
                          data={(data || []).map((entry) => ({
                            ...entry,
                            count: parseInt(entry.count),
                          }))}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {(data || []).map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      ) : (
                        <text
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fontSize="12"
                        >
                          No data available for Pie Chart
                        </text>
                      )}
                      <Tooltip content={<CustomTooltipPiechart />} />
                    </PieChart>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart_body_1_main_3">
              <div className="chart_body_1_main_3_body_head">
                <h3>Daily Visitors</h3>
              </div>
              <div className="chart_body_1_main_3_body">
                <LineChart
                  width={380}
                  height={200}
                  data={dataline}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  className="chart_linechart"
                  style={{with:'100%',height:'100%'}}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis className="yaxis_linechart" />
                  <Tooltip />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="count" // Use 'count' as the dataKey to represent the Y-axis values
                    name="Visitors" // Display name in the legend
                    stroke="var(--ProjectColor)"
                    activeDot={{ r: 5 }}
                  />
                </LineChart>
              </div>
            </div>

          </div>
        </div>
        <br></br>
        <div className="chart_body_2 new_chart_body_2">
          <div className="barchart_container">
            <div className="chart_body_1_main_3_body_head">
              <h3>Appointment Status</h3>
            </div>
            <div className="barchart_1">
              <Stack
                direction={{ xs: "column", xl: "row" }}
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  {barChartsParamsappointment ? (
                    <BarChart
                      xAxis={[
                        { scaleType: "band", data: barChartsParamsappointment },
                      ]}
                      series={[
                        {
                          data: booked,
                          label: "Booked",
                          highlightScope: { highlighted, faded },
                          shadow: true,
                        },
                        {
                          data: canceled,
                          label: "Canceled",
                          highlightScope: { highlighted, faded },
                          shadow: true,
                        },
                        {
                          data: total,
                          label: "Total",
                          highlightScope: { highlighted, faded },
                          shadow: true,
                        },
                      ]}
                      width={700}
                      height={350}
                      readOnly
                    />
                  ) : (
                    <div>No data available for Bar Chart</div>
                  )}
                </Box>
              </Stack>
            </div>
          </div>
          <div
            className="barchart_2 new_chart_body_2"
            style={{ justifyContent: "center", gap: "10px" ,width:'300px' , textAlign:'center' , alignItems:'center'}}
          >
            <div className="chart_body_1_main_3_body_head">
              <h3>Disease</h3>
            </div>
            <Box sx={{ width: 300 }}>
              {diseases ? (
                diseases.map((disease, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center ",
                      width: "280px",
                      textAlign:'center'
                    }}
                  >
                    <div className="new_box_success">{disease.name}</div>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs>
                        <StyledSlider
                          value={disease.percentage}
                          aria-labelledby={`input-slider-${index}`}
                          sx={{ "& .MuiSlider-thumb": { display: "none" } }}
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          value={`${disease.percentage}%`}
                          size="small"
                          // onChange={(event) => handleInputChange(event, index)}
                          // onBlur={handleInputChange}
                          inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: "text",
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))
              ) : (
                <div>No data available for Diseases</div>
              )}
            </Box>
          </div>
        </div>
        <div className="chart_body_2 new_chart_body_2">
          <div className="barchart_container">
            <div className="chart_body_1_main_3_body_head">
              <h3> Inventory Movement</h3>
            </div>
            <div className="barchart_1">
              <Stack
                direction={{ xs: "column", xl: "row" }}
                spacing={1}
                sx={{ width: "100%" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  {barChartsParams && barChartsParams.series && (
                    <BarChart
                      xAxis={[
                        {
                          scaleType: "band",
                          data: barChartsParams.series[0].xaxis, // Assuming xaxis is present in the first series
                        },
                      ]}
                      series={barChartsParams.series.map((series) => ({
                        data: series.data,
                        label: series.label,
                        // itemnames: series.itemnames,
                        highlightScope: { highlighted, faded },
                        shadow: true,
                      }))}
                      width={700}
                      height={350}
                    />
                  )}
                </Box>
              </Stack>
            </div>
          </div>
          <div className="chart_body_1_main_1_container1">
            <div className="chart_body_1_main_3_body_head">
              <h3>Revenue</h3>
            </div>
            <div className="chart_body_1_child_1">
              <div className="chart_body_1_child_1_body" onClick={handlepaymentview}>
                <div className="chart_body_1_child_1_body_icon">
                  <Diversity1Icon />
                </div>
                <div className="chart_body_1_child_1_body_count">
                  <h3>{totalcollection}</h3>
                </div>
                <div className="chart_body_1_child_1_body_name">
                  Total Collection
                </div>
              </div>
            </div>
            <div className="chart_body_1_child_1">
              <div className="chart_body_1_child_1_body">
                <div className="chart_body_1_child_1_body_icon">
                  <PersonAddAlt1Icon />
                </div>
                <div className="chart_body_1_child_1_body_count">
                  <h3>{overallrefundcollection}</h3>
                </div>
                <div className="chart_body_1_child_1_body_name">
                  Total Refund
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModel && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModel(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
                
       
                <div className="Register_btn_con regster_btn_contsai">
                
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModel(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
    </>
  );
};

export default ClinicMetrics;
