import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Mystore } from './DataStore/Store';
import {Provider} from 'react-redux';

import reportWebVitals from './reportWebVitals';
import { HashRouter as Router} from 'react-router-dom';
import Jjjjjj from './Jjjjjj';

export const urllinkdata=`http://127.0.0.1:8000/`
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Mystore}>
  <Router>
    <App />
    {/* <Jjjjjj/> */}
  </Router>
  </Provider> 
);


reportWebVitals();
