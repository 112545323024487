import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
// import { Helmet } from 'react-helmet';
// import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
// import './Neww.css';
import Button from '@mui/material/Button';
import ForwadIcon from "@mui/icons-material/Forward";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import  faForward  from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {useSelector} from 'react-redux'
import { GiProgression } from "react-icons/gi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import '../Report/smartreport.css'
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const getColorName = (value, min, max) => {
  const percentage = ((value - min) / (max - min)) * 100;
  if (percentage <= 20) {
    return "Panic";
  } else if (percentage <= 40) {
    return "Abnormal - Y";
  } else if (percentage <= 60) {
    return "Normal";
  } else if (percentage <= 80) {
    return "Abnormal - O";
  } else {
    return "Panic";
  }
};



const DispatchList= () => {


  const userRecord = useSelector(state=>(state.userRecord?.UserData))
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [summa, setsumma] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const dispatchvalue = useDispatch()
    const [openModel, setOpenModel] = useState(false)
    const isSidebarOpen=useSelector(state=>state.userRecord?.isSidebarOpen)
    const [smartreport, setsmartreport] = useState([])
    const [reportvalue, setreportvalue] = useState({
      minvalue :'',
      maxvalue :'',
      capturevalue :''
    })
  const urllink=useSelector(state=>state.userRecord?.UrlLink)

    
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
    const [selectedrow,setselectedrow]  = useState()
    const pageSize = 10;
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const showdown = filteredRows.length;


    const yourStyles={
        position: 'absolute',
        inset: '100px',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(97 90 90 / 75%)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '0px'
      }
    const closeModal = () => {
        setModalIsOpen(false);
        setModalContent('');
      };
    useEffect(() => {
      axios.get(`${urllink}Billing/dispatchdataforque?Location=${userRecord?.location}`)
          .then((response) => {
            console.log(response.data)
              const data = response.data.map((row,index) => ({
                  id: index+1, // Assuming you want to use Patient_Id as id
                  ...row,
                  status : "Status"
              }));
              setsumma(data);
              console.log(data)
              
          })
          .catch((error) => {
              console.error('Error fetching data:', error);
          });
         
  }, [urllink, userRecord?.location]);
  
    

    useEffect(() => {
        const lowerCaseNameQuery = searchQuery.toLowerCase();
        const lowerCasePhoneQuery = searchQuery1.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCasePatientName = row.Patient_Name ? row.Patient_Name.toLowerCase() : '';
            const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : '';
            return lowerCasePatientName.includes(lowerCaseNameQuery) && lowerCasePhone.includes(lowerCasePhoneQuery);
        });
        setFilteredRows(filteredData);
    }, [searchQuery, searchQuery1, summa]);
    
    const handleSearchChange = (event, type) => {
        const value = event.target.value;
        if (type === 'name') {
            setSearchQuery(value);
        } else if (type === 'phone') {
            setSearchQuery1(value);
        }
    };

    
    const handleSampleCapture = (params) => {
        console.log(params)
        // Directoly setting selectedrow to the new value
       const data = params
        
        // const data =filteredRows.find((p)=>+p.id===+params.row.id)
      

        dispatchvalue({type: 'Capturesample', value: data});
        
        navigate('/Home/CompleteReports')
        
        // navigate('/SampleCapture')
    };
    
    console.log('selectedrow', selectedrow);
    
    const  handlesmartreport =(params) =>{

       console.log(params)
        
       axios.get(`${urllink}Phelobotomist/get_data_for_smart_report?invoice=${params.Billing_Invoice}&Patientid=${params.Patient_Id}&Visitid=${params.Visit_Id}`)
       .then((response)=>{
        console.log(response)
        let data = response.data
        let error = response.error
        if (error || data.length === 0){
          setOpenModel(false)
        }else{
          setOpenModel(!openModel)

          setsmartreport(response.data)

        }

       })
       .catch((error)=>{
        console.log(error)
       })
    };




    
    const dynamicColumns = [
        { field: 'Billing_Invoice', headerName: 'Invoice', width: 100 },
        { field: 'Visit_Id', headerName: 'Visit Id', width: 80 },
        { field: 'UpdatedAt', headerName: 'Date', width: 100 },
        { field: 'Patient_Name', headerName: 'Patient Name', width: 150 },
        { field: 'Age', headerName: 'Age', width: 80 },
        { field: 'Gender', headerName: 'Gender', width: 100 },
        { field: 'Phone', headerName: 'Phone', width: 120 },
        // { field: 'Patient_Group', headerName: 'Patient Group', width: 150 },
        { field: 'Refering_Doctor', headerName: 'Reference Doctor', width: 130 },
        // { field: 'Test_Name', headerName: 'Test Name', width: 150 },
        // { field: 'TestCount', headerName: 'Test Count', width: 80 },
        // { field: 'Test_value', headerName: 'Test Value', width: 150 },
  

        
        {
            field: 'actions',
            headerName: 'Options',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={()=>handleSampleCapture(params.row)}>
                        <ForwadIcon/>
                    </Button>
                </>
            ),
        },
      //   {
      //     field: 'smartaction',
      //     headerName: 'Smart Report',
      //     width: 100,
      //     renderCell: (params) => (
      //         <>
      //             <Button className='cell_btn' onClick={()=>handlesmartreport(params.row)}>
      //                 <GiProgression />
      //             </Button>
      //         </>
      //     ),
      // },
        {
            field: 'status', // Ensure this matches the key in your data
            headerName: 'Status',
            width: 100,
            renderCell: (params) => {
                // Handle possibly undefined Service_Statuses
                const serviceStatuses = params.row.Service_Statuses || [];
                return (
                    <Tooltip title={
                        <>
                           <div>Capture Status : {serviceStatuses[0].Capture}</div>
                           <div>Analaysis Status : {serviceStatuses[0].Analaysis}</div>
                           <div>Verified Status : {serviceStatuses[0].Verify}</div>
                           <div>Approve Status : {serviceStatuses[0].Approve}</div>
                        </>
                    } arrow>
                        <span>{params.row.status}</span>
                    </Tooltip>
                );
            },
 },
];

useEffect(()=>{
  smartreport.forEach((item)=>{
    getColorName(item.Percentage)
  })

},[smartreport])

    return (
        <>
            <div className="appointment">
                <div className="h_head">
                    <h3>Dispatch Que List</h3>
                    
                </div>
                <div className="con_1 ">
                    <div className="inp_1">
                        <label htmlFor="input">Patient Name :</label>
                        <input type="text" value={searchQuery} onChange={(e) => handleSearchChange(e, 'name')} placeholder='Enter the Patient Name' />
                    </div>
                    <div className="inp_1">
                        <label htmlFor="input">Phone :</label>
                        <input type="text" value={searchQuery1} onChange={(e) => handleSearchChange(e, 'phone')} placeholder='Enter the Phone Number' />
                    </div>
                    
                </div>
                <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                            columns={dynamicColumns}
                            pageSize={pageSize}
                            pageSizeOptions={[pageSize]}
                            onPageChange={(newPage) => setPage(newPage)}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className='data_grid'
                        />
                        {showdown > 0 && filteredRows.length > pageSize && (
                            <div className='IP_grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </ThemeProvider>
                    {filteredRows.length === 0 && (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal}style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          
            <iframe
              title="PDF Viewer"
              src={modalContent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
              }}
            />

            
          
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "var(--ProjectColor)",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>


      {openModel && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModel(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
             
              {smartreport?.map((item, index) => {
  // Assuming percentage, min, max, value, colorName, and colors are computed based on item or other state/props
  const percentage = ((item.Capturevalue - item.Paniclow) / (item.Panichigh - item.Paniclow)) * 100;
  const min = item.Paniclow;
  const max = item.Panichigh;
  const value = item.Capturevalue;
  const colors = ["red", "yellow", "green", "orange", "red"];
  const colorName = getColorName(value, min, max); // Using getColorName function
  // const colorName = ; // Replace with actual color name logic

  return (
    <div className="white-blood-cell-count-container" key={index}>
      <div className="color-box-container">
        <div className="ncdjjdu88">
          <h3>{item.Testname}</h3>
          <input
            type="range"
            min={min}
            max={max}
            value={value}
            className="slider"
            // onChange={handleSliderChange2} // Uncomment and implement this if needed
          />
          <FileDownloadIcon
            className="arrow-icon34f"
            style={{ left: `${percentage}%`, transform: "translateX(-50%)" }}
          />
        </div>
        <div
          className="color-box"
          style={{
            background: `linear-gradient(to right, ${colors[0]} 0%, ${colors[1]} 25%, ${colors[2]} 50%, ${colors[3]} 75%, ${colors[4]} 100%)`,
            width: "100%",
            position: "relative",
            height: "30px",
          }}
        ></div>
        <div className="range-values">
          <span className="start-value">{min} /uL</span>
          <span className="end-value">{max} /uL</span>
        </div>
        <span
          className="current-value"
          style={{ left: `${percentage}%`, transform: "translateX(-50%)" }}
        >
          {value}
        </span>
      </div>
      <div className="info">
        <span className="color-name">{colorName}</span>
      </div>
    </div>
  );
})}
      
                <div className="Register_btn_con regster_btn_contsai">
                
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModel(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

        </>
    );
}

export default DispatchList;
