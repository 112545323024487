import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const theme = createTheme({
  components: {
    MuiDataTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'center',
          },
          "& .MuiTableCell-body": {
            textAlign: 'center',
          },
        },
      },
    },
  },
});

const RatecardLims = () => {
  const [department, setDepartment] = useState('');
  const [franchaisename, setFranchaisename] = useState('');
  const [frandata, setFrandata] = useState([]);
  const [percentValues, setPercentValues] = useState({});
  const urllink = useSelector(state => state.userRecord?.UrlLink);
  const [summa, setsumma] = useState(false)
  const franchaise = [
    'Basic_Price',
    'Special_Price',
    'Tamil',
    'Bharathi',
    'Jeeva',
    'Mohan',
    'Vishva',
    'fran1',
    'fran2',
    'fran3',
    'fran4',
    'fran5',
    'fran6',
    'fran7',
    'fran8',
    'fran9',
    'fran10',
    'fran11',
    'fran12',
    'fran13',
    'fran14',
    'fran15',
    'fran16',
    'fran17',
    'fran18',
    'fran19',
    'fran20',
    'fran21',
    'fran22',
    'fran23',
    'fran24',
    'fran25',
    'fran26',
    'fran27',
    'fran28',
    'fran29',
    'fran30',
    'fran31',
    'fran32',
    'fran33',
    'fran34',
    'fran35',
    'fran36',
    'fran37',
    'fran38',
    'fran39',
    'fran40',
    'fran41',
    'fran42',
    'fran43',
    'fran44',
    'fran45'
  ];

  useEffect(() => {
    axios.get(`${urllink}usercontrol/get_for_ratecard?ratecard=${franchaisename}`)
      .then((response) => {
        setFrandata(response.data.map((row, index) => ({
          ...row,
          id: index + 1
        })));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [urllink, franchaisename, summa]);

  const handlePercentChange = (event, rowIndex) => {
    const newPercent = event.target.value;
    const newPercentValues = { ...percentValues, [rowIndex]: newPercent };
    setPercentValues(newPercentValues);

    // Perform the calculation and update the Schemecost
    const updatedData = frandata.map((row, index) => {
      if (index === rowIndex) {
        const basicPrice = parseFloat(row.basicprice);
        const percent = parseFloat(newPercent || 0);
        const schemePrice = basicPrice + (basicPrice * (percent / 100));
        return { ...row, Schemecost: schemePrice.toFixed(2) };
      }
      return row;
    });

    setFrandata(updatedData);
  };

  const handleUpdate = (rowData) => {
    console.log('rowData', rowData)
    const postdata = {
        ...rowData,
        franchaisename
    }
    axios.post(`${urllink}usercontrol/update_new_ratecard`, postdata)
    .then((response)=>{
        console.log(response)
        setsumma(!summa)
        setPercentValues("")
    })
    .catch((error)=>{
        console.error(error);
    })
  };

  const columns = [
    { name: 'id', label: 'Test Id' },
    { name: 'testname', label: 'Test Name' },
    { name: 'basicprice', label: 'Basic Price' },
    { name: 'percent', label: '%', options: { customBodyRender: (value, tableMeta) => (
      <input
        type="number"
        value={percentValues[tableMeta.rowIndex] || ''}
        onChange={event => handlePercentChange(event, tableMeta.rowIndex, tableMeta.rowData )}
        style={{ width: '80px' }}
      />
    )}},
    { name: 'Schemecost', label: 'Scheme Price' },
    { name: 'action', label: 'Update', options: { customBodyRender: (value, tableMeta) => (
      <button onClick={() => handleUpdate(tableMeta.rowData)}>Update</button>
    )}}
  ];

  return (
    <div className="new-patient-registration-form">
      <div className="ShiftClosing_Container">
        <div className="ShiftClosing_header">
          <h3>Ratecard Management</h3>
        </div>
      </div>

      <div className="con_1">
        <div className="inp_1">
          <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Department Name :</label>
          <select className='deprtsele' name="Department" value={department} onChange={(e) => { setDepartment(e.target.value) }} id="">
            <option value="">Select</option>
            <option value="Laboratory">Laboratory</option>
            <option value="Room management">Room Management</option>
            <option value="ot">Ot</option>
          </select>
        </div>
        <div className="inp_1">
          <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Franchaise Name :</label>
          {department === 'Laboratory' &&
            <>
              <input
                type="text"
                id="franchaisename"
                name="franchaisename"
                list='franchaise'
                value={franchaisename}
                style={{ width: "150px" }}
                onChange={(e) => setFranchaisename(e.target.value)}
              />
              <datalist id="franchaise">
                {franchaise.map((item, index) => (
                  <option
                    key={index}
                    value={item}
                  >
                    {item}
                  </option>
                ))}
              </datalist>
            </>
          }
        </div>
      </div>

      <div className='grid_1'>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Rate Card List"}
              data={frandata}
              columns={columns}
              options={{
                selectableRows: 'none',
                print: false,
                download: true,
                search: true,
                viewColumns: false,
                filter: true,
                pagination: true,
                responsive: "standard",
                tableBodyMaxHeight: '300px',
                tableBodyHeight: 'auto',
                scroll: 'none'
              }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>
    </div>
  );
}

export default RatecardLims;
