import React, { useState, useEffect } from "react";
import "./Organization.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
function AgeSetUp() {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [SelectedFile, setSelectedFile] = useState(null);
  const [ageData, setAgeData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  const [testname, setTestName] = useState();
  const [ageSetupData, setAgeSetupData] = useState({
    gender: "",
    fromAge: "",
    ageType: "day",
    toAge: "",
    toAgeType: "day",
    fromvalue: "",
    tovalue: "",
    panicLow: "",
    panicHigh: "",
  });

  const dispatchvale = useDispatch();

  dispatchvale({ type: "Agesetup", value: ageSetupData });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAgeSetupData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    fetchAgeSetUpData();
  }, []);
  const handleEdit = (row) => {
    setAgeSetupData({
      // method_id: selectedid,
      gender: row.Gender,
      fromAge: row.FromAge,
      ageType: row.FromAgeType,
      toAge: row.Toage,
      toAgeType: row.ToAgeType,
      fromvalue: row.From_Value,
      tovalue: row.To_Value,
      panicLow: row.PanicLow,
      panicHigh: row.PanicHigh,
    });
    setTestName(row.TestName);
    setIsEditMode(true);
    setSelectedid(row.S_No); // Assuming `id` is the identifier
  };

  const handleUpdateMethod = () => {
    const updatedata = {
      method_id: selectedid, // Assuming `id` is the identifier
      gender: ageSetupData.gender,
      fromAge: ageSetupData.fromAge,
      ageType: ageSetupData.ageType,
      toAge: ageSetupData.toAge,
      toAgeType: ageSetupData.toAgeType,
      fromvalue: ageSetupData.fromvalue,
      tovalue: ageSetupData.tovalue,
      panicLow: ageSetupData.panicLow,
      panicHigh: ageSetupData.panicHigh,
      testname: testname,
    };

    axios
      .post("http://127.0.0.1:8000/usercontrol/updateagesetup", updatedata)
      .then((response) => {
        console.log(response.data);
        setIsEditMode(false);
        setSelectedid(null);
        setAgeSetupData({
          gender: "",
          fromAge: "",
          ageType: "day",
          toAge: "",
          toAgeType: "day",
          fromvalue: "",
          tovalue: "",
          panicLow: "",
          panicHigh: "",
        });
        setTestName("");
        fetchAgeSetUpData();
      })

      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  const fetchAgeSetUpData = () => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/get_agesetup_data")
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setAgeData(data);
      })
      .catch((error) => {
        console.error("Error fetching agesetup data:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/gettestname`)
      .then((response) => {
        console.log(response.data);
        const data = response.data.test_name;
        setTestName(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);
    // Additional handling based on the name attribute
    if (name === "Documents") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  const handleSubmit = () => {
    // Basic validation check for mandatory fields
    if (
      !testname ||
      !ageSetupData.gender ||
      !ageSetupData.fromAge ||
      !ageSetupData.toAge ||
      !ageSetupData.fromvalue ||
      !ageSetupData.tovalue ||
      !ageSetupData.panicLow ||
      !ageSetupData.panicHigh
    ) {
      console.log("Mandatory fields are missing");
      // Consider using a more user-friendly way to notify about the error, such as setting an error state and displaying it in the UI
      return;
    }

    const postData = {
      ...ageSetupData,
      testname, // Ensure this matches the backend expected field name, adjust if necessary
    };

    axios
      .post(`${urllink}usercontrol/insertagesetup`, postData)
      .then((response) => {
        console.log(response.data);
        successMsg(response.data.message);
        setAgeSetupData({
          gender: "",
          fromAge: "",
          ageType: "day",
          toAge: "",
          toAgeType: "day",
          fromvalue: "",
          tovalue: "",
          panicLow: "",
          panicHigh: "",
        });
        fetchAgeSetUpData();
      })
      .catch((error) => {
        // errmsg(error)
        console.log(error);
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      if (type === "Documents") {
        axios
          .post(
            `${urllink}usercontrol/insert_csv_file_for_referencerange`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg("File Processed Successfully");

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            // errmsg(error);
          });
      }
    }
  };
  const totalPages = Math.ceil(ageData.length / pageSize);
  return (
    <div className="Clinic_det_new">
      <div className="bill_table_invoice">
        <div className="new_user_items">
          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="selectTest">
                Test Name<span>:</span>
              </label>
              <input
                type="text"
                id="testname"
                name="selectTest"
                placeholder="Enter Test Name"
                className="new_clinic_form_inp"
                required
                value={testname}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="selectTest">
                Gender<span>:</span>
              </label>
              <select
                id="gender"
                name="gender"
                required
                className="new_clinic_form_inp"
                value={ageSetupData.gender}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Baby">Baby</option>
                <option value="Both">Both</option>
              </select>
            </div>
          </div>

          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="fromAge">
                From Age<span>:</span>
              </label>
              <input
                type="number"
                id="fromAge"
                name="fromAge"
                placeholder="Enter Age"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.fromAge}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="ageType">
                Type<span>:</span>
              </label>
              <select
                id="ageType"
                name="ageType"
                required
                className="new_clinic_form_inp"
                value={ageSetupData.ageType}
                onChange={handleInputChange}
              >
                <option value="day">Day(s)</option>
                <option value="month">Month(s)</option>
                <option value="year">Year(s)</option>
              </select>
            </div>
          </div>

          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="toAge">
                To Age<span>:</span>
              </label>
              <input
                type="number"
                id="toAge"
                name="toAge"
                placeholder="Enter Age"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.toAge}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="toAgeType">
                Type<span>:</span>
              </label>
              <select
                id="toAgeType"
                name="toAgeType"
                required
                className="new_clinic_form_inp"
                value={ageSetupData.toAgeType}
                onChange={handleInputChange}
              >
                <option value="day">Day(s)</option>
                <option value="month">Month(s)</option>
                <option value="year">Year(s)</option>
              </select>
            </div>
          </div>
          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicLow">
                From Value<span>:</span>
              </label>
              <input
                type="number"
                id="fromvalue"
                name="fromvalue"
                placeholder="Enter From Value"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.fromvalue}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicHigh">
                To Value<span>:</span>
              </label>
              <input
                type="number"
                id="tovalue"
                name="tovalue"
                placeholder="Enter To Value"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.tovalue}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="new_clinic_form">
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicLow">
                Panic Low<span>:</span>
              </label>
              <input
                type="number"
                id="panicLow"
                name="panicLow"
                placeholder="Enter Panic Low"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.panicLow}
                onChange={handleInputChange}
              />
            </div>
            <div className="new_form_pack">
              <label className="new_form_first" htmlFor="panicHigh">
                Panic High<span>:</span>
              </label>
              <input
                type="number"
                id="panicHigh"
                name="panicHigh"
                placeholder="Enter Panic High"
                className="new_clinic_form_inp"
                required
                value={ageSetupData.panicHigh}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="Register_btn_con">
            <button
              className="btn_1"
              onClick={isEditMode ? handleUpdateMethod : handleSubmit}
            >
              {isEditMode ? "Update" : <AddIcon />}
            </button>
          </div>

          <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
            <h4>Table</h4>
            {ageData.length > 0 ? (
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Test Name</th>
                      <th>Gender</th>
                      <th>From Age</th>
                      <th>Age Type</th>
                      <th>To Age</th>
                      <th>Age Type</th>
                      <th>From Value</th>
                      <th>To Value</th>
                      <th>Panic Low</th>
                      <th>Panic High</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ageData
                      .slice(page * pageSize, (page + 1) * pageSize)
                      .map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1 + page * pageSize}</td>
                          <td>{row.TestName}</td>
                          <td>{row.Gender}</td>
                          <td>{row.FromAge}</td>
                          <td>{row.FromAgeType}</td>
                          <td>{row.Toage}</td>
                          <td>{row.ToAgeType}</td>
                          <td>{row.From_Value}</td>
                          <td>{row.To_Value}</td>
                          <td>{row.PanicLow}</td>
                          <td>{row.PanicHigh}</td>
                          <td>
                            <button onClick={() => handleEdit(row)}>
                              <EditIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="IP_norecords">
                <p>No data available</p>
              </div>
            )}
          </div>

          {ageData.length > pageSize && (
            <div className="grid_foot">
              <button
                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}

          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default AgeSetUp;
