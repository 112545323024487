import React, { useState, useEffect } from 'react';
import Barcode from 'react-barcode';
import './Report.css'
import axios from 'axios';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { toPng } from 'html-to-image';
import { useNavigate } from 'react-router-dom'
import { fontSize } from '@mui/system';
import Docsign from '../assets/verified.jpg'
import Pathsign from '../assets/approved.jpg'
import Button from '@mui/material/Button';



import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';


function CancelAppointmentDialog({ open, onClose, onConfirm ,cancelReason,setCancelReason}) {

  

  const handleConfirm = () => {
    // Call the onConfirm callback with the cancellation reason
    onConfirm(cancelReason);
    // Close the dialog
    onClose();
    
  };

  const handleClose=()=>{
    setCancelReason('')
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reprint Register Reason</DialogTitle>
      <DialogContent>
        <TextField
          label="Reprint Reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}




function Reports() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector(state => state.userRecord?.UrlLink);
  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen);
  const [Dispatch, setdispatch] = useState('');
  const [summa, setsumma] = useState([]);
  const [summa1, setsumma1] = useState([]);
  const [cancelReason, setCancelReason] = useState('');
  const [selectedId, setselectedId] = useState(null);
  const [trues, settrues] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState()
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  const [show, setshow] = useState(false)
  console.log('capturedatas............................', capturedatas)
  const navigate = useNavigate()
  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: '',
    ClinicGST: '',
    ClinicAddress: '',
    ClinicCity: '',
    ClinicState: '',
    ClinicCode: '',
    ClinicMobileNo: '',
    ClinicLandLineNo: '',
    ClinicMailID: '',
    InvoiceNo: ''
  });

  const [report, setreport] = useState({
    Collected: '',
    Received: '',
    Reported: '',
    Head: "",
    Detail: ""
  })



  useEffect(() => {
    axios.get(`${urllink}Phelobotomist/get_for_preview_examinations?Visitid=${capturedatas?.Visit_Id}&patientid=1&Billinginvoice=${capturedatas?.Billing_Invoice}`)
      .then((response) => {
        console.log(response);
        const groupsData = response.data;
        console.log('groupsData', groupsData);
        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        groupsData.forEach(group => {
          const analyseResultDate = new Date(group.Updated_At.Sample_Capture_in_Phelobotomist);
          const analyseSeniorDate = (group.Updated_At.AnalyseInvestigationSenior);
          const approvePathologistDate = new Date(group.Updated_At.Approve_Test_Pathologist);

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes();

          if (day.length < 2) day = '0' + day;
          if (month.length < 2) month = '0' + month;
          if (hours.length < 2) hours = '0' + hours;
          if (minutes.length < 2) minutes = '0' + minutes;

          return [day, month, year].join('/') + ' ' + [hours, minutes].join(':');
        };

        setreport(prev => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map(group => {
          return {
            method: group.Method,
            Department: group.Department,
            groupName: group.Group_Name,
            Tests: group.Tests.map(test => ({
              Test_Name: test.Test_Name || "",
              Method_Name: test.Method_Name || "",
              Department: test.Department || "",
              Sample: test.Specimen || "",
              Content: test.EditContent || '',
              Samplepicture: test.Samplepic ? `data:image/jpeg;base64,${test.Samplepic}` : null

            })),


          };
        });

        setsumma1(reportsData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });


  }, [])


  useEffect(()=>{
        axios.get(`${urllink}Phelobotomist/get_for_dispatch_report_status?invoice=${capturedatas?.Billing_Invoice}`)
        .then((response)=>{
          console.log(response);
          setdispatch(response.data);
        })
        .catch((error)=>{
          console.log(error);
        })
  },[urllink, capturedatas, trues])



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${urllink}Phelobotomist/getdataforreport?Billing_Invoice=${capturedatas.Billing_Invoice}`);
        const groupsData = response.data;
        console.log('groupsData', groupsData);

        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        groupsData.forEach(group => {
          const analyseResultDate = new Date(group.Updated_At.AnalyseInvestigationResult);
          const analyseSeniorDate = group.Updated_At.AnalyseInvestigationSenior !== "N/A" ? new Date(group.Updated_At.AnalyseInvestigationSenior) : new Date(0);
          const approvePathologistDate = new Date(group.Updated_At.Approve_Test_Pathologist);

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = '' + d.getDate(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes();

          if (day.length < 2) day = '0' + day;
          if (month.length < 2) month = '0' + month;
          if (hours.length < 2) hours = '0' + hours;
          if (minutes.length < 2) minutes = '0' + minutes;

          return [day, month, year].join('/') + ' ' + [hours, minutes].join(':');
        };

        setreport(prev => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map(group => ({
          method: group.Method,
          Department: group.Department,
          groupName: group.Group_Name,
          culturetest: group.Culturetest,
          tests: group.Tests.map(test => ({
            Test_Name: test.Test_Name || "",
            CaptureValue: test.CaptureValue || "",
            UOM: test.UOM || "",
            Method_Name: test.Method_Name || "",
            Department: test.Department || "",
            Sample: test.Sepcimen || "",
            colony_count: test.Colony_Count || "",
            Capture_Organism: test.Capture_Organism || "",
            Head: group.Method === 'Group' ? test.Head : "",
            Detail: group.Method === 'Group' ? test.Detail : '',
          })),
          reference: group.ReferenceRange?.map(test => ({
            From_Value: test.From_Value,
            To_Value: test.To_Value,
            gender: test.Gender,
            Paniclow: test.PanicLow,
            PanicHigh: test.PanicHigh,
            Test_Name: test.TestName,
          })),
          interpretation: group.Interpretations ? {
            Head: group.Interpretations.Head,
            Detail: group.Interpretations.Detail,
          } : null,
          Sensitive: group.Sensitive?.map(test => ({
            antibiotic: test.antibiotic,
            sensitivetype: test.sensitivetype,
          })),
        }));

        setsumma(reportsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [capturedatas.Billing_Invoice, urllink]);
  const [PdfBlob, setPdfBlob] = useState(null)
  console.log('PdfBlobsumaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaammmmm', summa)

  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      navigate('/Home/DispatchEntryList')
    }
  }, [capturedatas]);

  useEffect(() => {
    axios
      .get(
        `${urllink}usercontrol/getClinic?location=ALL`
      )
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.door_no + "," + data.area + "," + data.street,
            ClinicGST: data.Gst_no,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phone_no,
            ClinicLandLineNo: data.landline_no,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios.get(`${urllink}Billing/get_billing_patient_barcode?Patientid=${capturedatas?.Patient_Id}&Patientname=${capturedatas.Patient_Name}`)
      .then((response) => {
        console.log(response)
        setpatinetbillingbarcode(response.data.Patient_Barcode)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])


  const handlebeforereprint = () =>{
    setselectedId(capturedatas?.Billing_Invoice)
    setOpenDialog(true);
  }
 
  const handlereprint = useReactToPrint({
    content: () => document.getElementById('reactprintcontent'),
    onBeforePrint: () => {
      console.log('Before');
      setshow(true);
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log('After');
      const printdata = document.getElementById('reactprintcontent');
      console.log('printdata', printdata);

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdfWidth = contentWidth + 2 * 5;
          const pdfHeight = contentHeight + 2 * 10;
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [pdfWidth, 1120],
          });

          const barcodeImagecon1 = await toPng(printdata.querySelector('#get_imagecontent_1'));
          const barcodeImagecon2 = await toPng(printdata.querySelector('#get_imagecontent_2'));

          const barcodeImage1 = await new Promise((resolve, reject) => {
            const barcodeImg1 = new Image();
            barcodeImg1.onload = () => resolve(barcodeImg1);
            barcodeImg1.onerror = reject;
            barcodeImg1.src = barcodeImagecon1;
          });

          const barcodeImage2 = await new Promise((resolve, reject) => {
            const barcodeImg2 = new Image();
            barcodeImg2.onload = () => resolve(barcodeImg2);
            barcodeImg2.onerror = reject;
            barcodeImg2.src = barcodeImagecon2;
          });

          // Append barcode images to their respective containers
          const barcodeContainer1 = printdata.querySelector('#get_imagecontent_1');
          barcodeContainer1.innerHTML = ''; // Clear previous content
          barcodeContainer1.appendChild(barcodeImage1);

          const barcodeContainer2 = printdata.querySelector('#get_imagecontent_2');
          barcodeContainer2.innerHTML = ''; // Clear previous content
          barcodeContainer2.appendChild(barcodeImage2);

          pdf.html(printdata, {
            x: 5, // Set x-coordinate for content
            y: 5, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output('blob');
              setPdfBlob(generatedPdfBlob);
              // pdf.save(`${capturedatas.Patient_Name}_labReport.pdf`);
              setshow(false);
              console.log('generatedPdfBlob', generatedPdfBlob);

              const formdata = new FormData();
              const postdata = {
                patientname: capturedatas.Patient_Name,
                patient_Id: capturedatas.Patient_Id,
                Visit_Id: capturedatas.Visit_Id,
                refering_doc: capturedatas.Refering_Doctor,
                invoice: capturedatas.Billing_Invoice,
                patinetbillingbarcode: patinetbillingbarcode,
                samplecode: capturedatas.Barcode,
                location : userRecord?.location,
                createdby : userRecord?.username,
                cancelReason: cancelReason
              };

              // Append each field in postdata to the formdata
              for (const key in postdata) {
                if (postdata.hasOwnProperty(key)) {
                  formdata.append(key, postdata[key]);
                }
              }

              // Append the PDF blob to the formdata
              formdata.append('report', generatedPdfBlob, `${capturedatas.Patient_Name}_labReport.pdf`);

              console.log(postdata);

              axios.post(`${urllink}Phelobotomist/insert_reort_table`, formdata, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error);
                });
            },
          });
        } else {
          throw new Error('Unable to get the target element');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
  });

  console.log('summa...............................................', summa.length)

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('reactprintcontent'),
    onBeforePrint: () => {
      console.log('Before');
      setshow(true);
    },
    onAfterPrint: async () => {
      setPdfBlob(null);
      console.log('After');
      const printdata = document.getElementById('reactprintcontent');
      console.log('printdata', printdata);

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const contentHeight = printdata.offsetHeight;
          const pdfWidth = contentWidth + 2 * 5;
          const pdfHeight = contentHeight + 2 * 10;
          const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [pdfWidth, 1120],
          });

          const barcodeImagecon1 = await toPng(printdata.querySelector('#get_imagecontent_1'));
          const barcodeImagecon2 = await toPng(printdata.querySelector('#get_imagecontent_2'));

          const barcodeImage1 = await new Promise((resolve, reject) => {
            const barcodeImg1 = new Image();
            barcodeImg1.onload = () => resolve(barcodeImg1);
            barcodeImg1.onerror = reject;
            barcodeImg1.src = barcodeImagecon1;
          });

          const barcodeImage2 = await new Promise((resolve, reject) => {
            const barcodeImg2 = new Image();
            barcodeImg2.onload = () => resolve(barcodeImg2);
            barcodeImg2.onerror = reject;
            barcodeImg2.src = barcodeImagecon2;
          });

          // Append barcode images to their respective containers
          const barcodeContainer1 = printdata.querySelector('#get_imagecontent_1');
          barcodeContainer1.innerHTML = ''; // Clear previous content
          barcodeContainer1.appendChild(barcodeImage1);

          const barcodeContainer2 = printdata.querySelector('#get_imagecontent_2');
          barcodeContainer2.innerHTML = ''; // Clear previous content
          barcodeContainer2.appendChild(barcodeImage2);

          pdf.html(printdata, {
            x: 5, // Set x-coordinate for content
            y: 5, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output('blob');
              setPdfBlob(generatedPdfBlob);
              // pdf.save(`${capturedatas.Patient_Name}_labReport.pdf`);
              setshow(false);
              console.log('generatedPdfBlob', generatedPdfBlob);

              const formdata = new FormData();
              const postdata = {
                patientname: capturedatas.Patient_Name,
                patient_Id: capturedatas.Patient_Id,
                Visit_Id: capturedatas.Visit_Id,
                refering_doc: capturedatas.Refering_Doctor,
                invoice: capturedatas.Billing_Invoice,
                patinetbillingbarcode: patinetbillingbarcode,
                samplecode: capturedatas.Barcode,
                location : userRecord?.location,
                createdby : userRecord?.username
              };

              // Append each field in postdata to the formdata
              for (const key in postdata) {
                if (postdata.hasOwnProperty(key)) {
                  formdata.append(key, postdata[key]);
                }
              }

              // Append the PDF blob to the formdata
              formdata.append('report', generatedPdfBlob, `${capturedatas.Patient_Name}_labReport.pdf`);

              console.log(postdata);

              axios.post(`${urllink}Phelobotomist/insert_reort_table`, formdata, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then((response) => {
                  console.log(response);
                  settrues(!trues)
                })
                .catch((error) => {
                  console.log(error);
                });
            },
          });
        } else {
          throw new Error('Unable to get the target element');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
  });


  const PdfViewer = ({ pdfBlob }) => {
    // Check if pdfBlob is a Blob object
    if (pdfBlob instanceof Blob) {
      // Convert Blob object to data URL
      const url = URL.createObjectURL(pdfBlob);

      // Render the PDF using iframe
      return (
        <iframe
          title="PDF Viewer"
          src={url}
          style={{
            width: "100%",
            height: "435px",
            border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
          }}
        />
      );
    }

    // If pdfBlob is not a Blob object, assume it's already a valid data URL and render it directly
    return (
      <img
        src={pdfBlob}
        alt="Concern Form"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "20px",
        }}
      />
    );
  };
  function getInnerHeightOfPDF() {
    const pdfget = new jsPDF({
      orientation: 'portrait', // or 'landscape'
      unit: 'px', // pixels
      format: [595, 842] // A4 size in pixels (width, height)
    });
    const innerHeightInPoints = pdfget.internal.pageSize.getHeight();
    pdfget.deletePage();
    const innerHeightInPixels = innerHeightInPoints * (1 / 72) * 96;
    return innerHeightInPixels - 10;
  }

  const innerHeightOfPDF = getInnerHeightOfPDF();


  return (
    <>
    <div className='appointment  dfewewufew76' id='reactprintcontent' >
      {summa[0]?.tests[0].Department === "Histopathology" &&

        <div className='Report_master1' id={`reactprintcontent`}>
          <div className='Report_master' id={`reactprintcontent`} >
            <div className="Report_master" >
              <div className="New_billlling_invoice_head new_report_con">
                <div className="new_billing_logo_con">
                  <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                </div>
                <div className="new_billing_address_1 ">
                  <span>{ClinicDetials.ClinicName}</span>
                  <div>
                    <span>{ClinicDetials.ClinicAddress},</span>
                    <span>{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
                  </div>
                  <div>
                    <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
                    <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
                    <span>{ClinicDetials.ClinicMailID}</span>
                  </div>
                </div>
              </div>
              <div className="Register_btn_con" style={{ color: 'var(--labelcolor)', fontWeight: 600 }}>
                Test Report
              </div>
              <div className="new_billing_address new_report_address">
                <div className="new_billing_address_2">
                  {(
                    <div className='Register_btn_con_barcode'>
                      <div id='get_imagecontent_1'>
                        <Barcode
                          value={patinetbillingbarcode || ''}
                          lineColor="black"
                          height={40}
                          width={1.2}
                          displayValue={true}
                          fontSize={12}
                        />
                      </div>
                    </div>
                  )}
                  <div className="new_billing_div">
                    <label>Patient Name <span>:</span></label>
                    <span>{capturedatas.Patient_Name}</span>
                  </div>
                  <div className="new_billing_div">
                    <label>Visit ID <span>:</span></label>
                    <span>{capturedatas.Visit_Id}</span>
                  </div>
                  <div className="new_billing_div">
                    <label>Age/Gender <span>:</span></label>
                    <span>{capturedatas.Age}/{capturedatas.Gender}</span>
                  </div>
                  <div className="new_billing_div">
                    <label>Phone <span>:</span></label>
                    <span>{capturedatas.Phone}</span>
                  </div>
                  <div className="new_billing_div">
                    <label>Reference Doctor <span>:</span></label>
                    <span>{capturedatas.Refering_Doctor}</span>
                  </div>
                </div>
                <div className="new_billing_address_2">
                  {(
                    <div className='Register_btn_con_barcode'>
                      <div id='get_imagecontent_2'>
                        <Barcode
                          value={capturedatas.Barcode || ''}
                          lineColor="black"
                          height={40}
                          width={1.2}
                          displayValue={true}
                          fontSize={12}
                        />
                      </div>
                    </div>
                  )}
                  <div className="new_billing_div">
                    <label>Invoice No <span>:</span></label>
                    <span>{capturedatas.Billing_Invoice}</span>
                  </div>
                  <div className="new_billing_div">
                    <label>Collected  <span>:</span></label>
                    <span>{report.Collected}</span>
                  </div>
                  <div className="new_billing_div">
                    <label>Received <span>:</span></label>
                    <span>{report.Received}</span>
                  </div>
                  <div className="new_billing_div">
                    <label>Reported <span>:</span></label>
                    <span>{report.Reported}</span>
                  </div>
                  <div className="new_billing_div">
                    <label> Barcode<span>:</span></label>
                    <span>{capturedatas.Barcode}</span>
                  </div>
                </div>
              </div>

              {summa1.map((group) => (
                <>
                  <div className='culture_report_data'>
                    <div className='culture_test_header'>
                      <p>{group.Tests[0]?.Department}</p>
                      <p>{group.groupName}</p>
                    </div>

                    <div className='culturetest_div'>
                      <p className='headerculture'>Nature of specimen :</p>
                      <p>{group.Tests[0]?.Sample}</p>
                    </div>



                  </div>



                  {/* {document.write(group.Tests[0]?.Content)} */}
                  <div className='completed_report_1111'>
                   
                      <img  src={group.Tests[0]?.Samplepicture} alt='Image description' style={{width:'200px',height:'200px',float:'right',margin:'0 150px 0 0'}}/>
                   
                  <p style={{width:'100%'}} className='completed_report' dangerouslySetInnerHTML={{ __html: group.Tests[0]?.Content }}/>
                  </div>

                   
                  
                  
                </>
              ))}
            </div>
            <div className="report_qr histopathologycode"  >
              <div className="Register_btn_con_Doc_sign">
                <img src="" alt="" srcset="" />
                <p>Verifier Sign</p>
              </div>
              {<div className='Register_btn_con_QRcode'>
                <QRCode
                  value="http//localhost:3000/DispatchResult"
                  size={106}
                  level="H"
                  bgColor="#ffffff"
                  fgColor="#000000"
                />
              </div>}
              <div className="Register_btn_con_Doc_sign">
                <p>Approver Sign</p>
              </div>


            </div>
          </div>
        </div>

      }


      {summa[0]?.tests[0].Department !== "Histopathology" && summa?.map((group, index) => (
        <div className='Report_master1' id={`reactprintcontent-${index + 1}`} key={index}>

          <div className="Report_master" style={{ height: innerHeightOfPDF }}>
            <div className="New_billlling_invoice_head new_report_con">
              <div className="new_billing_logo_con">
                <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
              </div>
              <div className="new_billing_address_1 ">
                <span>{ClinicDetials.ClinicName}</span>
                <div>

                  <span >{ClinicDetials.ClinicAddress},</span>
                  <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
                </div>
                <div>
                  <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
                  <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
                  <span>{ClinicDetials.ClinicMailID}</span>
                </div>

              </div>

            </div>
            <div className="Register_btn_con" style={{ color: 'var(--labelcolor)', fontWeight: 600 }}>
              Test Report
            </div>


            <div className="new_billing_address new_report_address">
              <div className="new_billing_address_2">
                {index === 0 && <div className='Register_btn_con_barcode'>
                  <div id='get_imagecontent_1'>
                    <Barcode
                      value={patinetbillingbarcode || ''}
                      lineColor="black"
                      height={40}
                      width={1.2}
                      displayValue={true} // Hide the barcode value
                      fontSize={12}
                    />
                  </div>
                </div>}
                <div className="new_billing_div">
                  <label>Patient Name <span>:</span></label>
                  <span>{capturedatas.Patient_Name}</span>
                </div>
                <div className="new_billing_div">
                  <label>Visit ID <span>:</span></label>
                  <span>{capturedatas.Visit_Id}</span>
                </div>
                <div className="new_billing_div">
                  <label>Age/Gender <span>:</span></label>
                  <span>{capturedatas.Age}/{capturedatas.Gender}</span>
                </div>
                <div className="new_billing_div">
                  <label>Phone <span>:</span></label>
                  <span>{capturedatas.Phone}</span>
                </div>
                <div className="new_billing_div">
                  <label>Reference Doctor <span>:</span></label>
                  <span>{capturedatas.Refering_Doctor}</span>
                </div>

              </div>

              <div className="new_billing_address_2">
                {index === 0 && <div className='Register_btn_con_barcode'>
                  <div id='get_imagecontent_2'>
                    <Barcode
                      value={capturedatas.Barcode || ''}
                      lineColor="black"
                      height={40}
                      width={1.2}
                      displayValue={true} // Hide the barcode value
                      fontSize={12}
                    />
                  </div>
                </div>}
                <div className="new_billing_div">
                  <label>Invoice No <span>:</span></label>
                  <span>{capturedatas.Billing_Invoice}</span>
                </div>

                <div className="new_billing_div">
                  <label>Collected  <span>:</span></label>
                  <span>{report.Collected}</span>
                </div>
                <div className="new_billing_div">
                  <label>Received <span>:</span></label>
                  <span>{report.Received}</span>
                </div>
                <div className="new_billing_div">
                  <label>Reported <span>:</span></label>
                  <span>{report.Reported}</span>
                </div>
                <div className="new_billing_div">
                  <label> Barcode<span>:</span></label>
                  <span>{capturedatas.Barcode}</span>
                </div>
              </div>
            </div>

            <div className="Inpterpretation_head">
              {group.method === 'Group' && <p>{group.Department}</p>}
              {/* <p>{group.Department}</p> */}
              {group.culturetest === 'Yes' ? null : <p>{group.groupName}</p>}
            </div>
            {group.culturetest === 'Yes' ?
              <div className='culture_report_data'>
                <div className='culture_test_header'>
                  <p>{group.Department}</p>
                  <p>{group.groupName}</p>
                </div>

                <div className='culturetest_div'>
                  <p className='headerculture'>Nature of specimen</p>
                  <p>{group.tests[0]?.Sample}</p>
                </div>
                <div className='culturetest_div'>
                  <p className='headerculture'>Gram Stain</p>
                  <p>{group.tests[0]?.Capture_Organism}</p>
                </div>
                <div className='culturetest_div'>
                  <p className='headerculture'>Organism Isolated</p>
                  <p>{group.tests[0]?.Capture_Organism}</p>
                </div>
                <div className='culturetest_div'>
                  <p className='headerculture'> Colony Count</p>
                  <p>{group.tests[0]?.colony_count} {group.tests[0]?.UOM}</p>
                </div>
                <div className="new_billing_Report_detials">
                  <table className="report_table">
                    <thead>
                      <tr>
                        <th>
                          Antibiotic
                        </th>
                        <th>
                          Sensitive Type
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.Sensitive?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.antibiotic}</td>
                          <td><p >{item.sensitivetype}</p></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>

              : <div className="new_billing_Report_detials">
                <table className="report_table">

                  <thead >
                    <tr>
                      <th >Test Name</th>
                      <th >Result</th>
                      <th >Unit</th>
                      <th>Flag</th>
                      <th>Biological Reference</th>
                      <th >Method</th>
                    </tr>
                  </thead>
                  {group.culturetest === 'Yes' ? <tbody>
                    <tr>
                      <td>
                        <p></p>
                      </td>
                    </tr>
                  </tbody> : <tbody>
                    {group.tests?.map((test, index) => {
                      // Determine if the captured value exceeds the panic high value or is less than the panic low value
                      const exceedsPanicHigh = test.CaptureValue >= group.reference?.PanicHigh;
                      const belowPanicLow = test.CaptureValue <= group.reference?.PanicLow;

                      // Define the style object based on the conditions
                      const valueStyle = {
                        fontWeight: (exceedsPanicHigh || belowPanicLow) ? 'bold' : 'normal',
                        fontSize: 15
                      };

                      // Filter reference array based on Test_Name
                      const filteredReference = group.reference?.filter(ref => ref.Test_Name === test.Test_Name);
                      console.log('filteredReference', filteredReference)
                      // Determine the panic level
                      let panicLevel;
                      if (exceedsPanicHigh) {
                        panicLevel = "H"; // High
                      } else if (belowPanicLow) {
                        panicLevel = "L"; // Low
                      } else {
                        panicLevel = "Normal";
                      }

                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="">
                                {group.method === 'Group' ? (<>
                                  <p>{test.Test_Name}</p>
                                  <i>Sample-{test.Sample}</i>
                                </>) : (
                                  <>
                                    <h4>{test.Department}</h4>
                                    <p>{test.Test_Name}</p>
                                    <i>Sample-{test.Sample}</i>
                                  </>)}
                              </div>
                            </td>
                            <td style={valueStyle}>{test.CaptureValue}</td>
                            <td>{test.UOM}</td>
                            <td>
                              <h3>{panicLevel}</h3>
                            </td>
                            <td>
                              {filteredReference?.map((ref, refIndex) => (
                                <div className="" key={refIndex}>
                                  <span>{`${ref.gender}: ${ref.From_Value}-${ref.To_Value}-${test.UOM}`}</span>
                                </div>
                              ))}
                            </td>
                            <td>{test.Method_Name}</td>

                          </tr>
                          {(test.Head || test.Detail) && (
                            <tr>
                              <td colSpan={8}>
                                <h4>{test.Head}</h4>
                                <p>{test.Detail}</p>
                              </td>
                            </tr>
                          )}

                        </>
                      );
                    })}
                  </tbody>}
                </table>
              </div>}



            <div className="interpretation_container">
              <div className="Inpterpretation_head">
                <p>{group.interpretation?.Head}</p>
              </div>
              <div className="Interpretation_Detail">
                <p>{group.interpretation?.Detail}</p>
              </div>
            </div>
            {/* </div> */}


            <div className="report_qr bottomcode">
              <div className="Register_btn_con_Doc_sign">
                <img src={Docsign} alt="" srcset="" className='docsign' />
                <p>Verifier Sign</p>
              </div>
              {index === 0 && <div className='Register_btn_con_QRcode'>
                <QRCode
                  value="http//localhost:3000/DispatchResult"
                  size={106}
                  level="H"
                  bgColor="#ffffff"
                  fgColor="#000000"
                />
              </div>}
              <div className="Register_btn_con_Doc_sign">
                <img src={Pathsign} alt="" srcset="" className='docsign' />

                <p>Approver Sign</p>
              </div>


            </div>


          </div>

        </div>
      ))}

      {summa.length === 0 &&
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '10px', }}>
          <p style={{ color: 'red' }}> Your Report is not Ready Now</p>

          <button className='RegisterForm_1_btns  Rhugt_report' onClick={() => navigate('/Home/DispatchEntryList')}>Back</button>
        </div>}

      {!show && summa.length !== 0 ? <div className='Register_btn_con'>
        <button onClick={Dispatch === 'Dispatched'? handlebeforereprint : handlePrint} className='RegisterForm_1_btns  Rhugt_report'>{Dispatch === 'Dispatched'? "Reprint":"Print"}</button>
      </div> : null}
      {/* {PdfBlob && <PdfViewer pdfBlob={PdfBlob}/>} */}


    </div>
    <CancelAppointmentDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handlereprint}
        setCancelReason={setCancelReason}
        cancelReason={cancelReason}
      />
</>
  );
}

export default Reports;

