import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./IpNurseVitals.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const IpDrugAdminister = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [summa, setsumma] = useState([]);
  const [TabletShow, setTabletShow] = useState(null);
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id) {
      axios
        .get(
          `http://127.0.0.1:8000/ipregistration/get_Drug_Administration_datas_by_distinct?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}`
        )
        .then((response) => {
          const data = response.data;
          console.log("222", data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [IpNurseQueSelectedRow, IpNurseQueSelectedRow?.Booking_Id]);
  const dynamicColumns = [
    {
      field: "id",
      headerName: "S_No",
      width: 40,
    },
    ...Object.keys({
      Date: "",
      Department: "",
      DoctorName: "",
      Status: "",
    }).map((labelname, index) => {
      const formattedLabel = formatLabel(labelname);
      const labelWidth = getTextWidth(formattedLabel);

      return {
        field: labelname,
        headerName: formattedLabel,
        width: ["Date", "Department", "DoctorName", "Status"].includes(
          labelname
        )
          ? labelWidth + 100
          : labelWidth + 30,
      };
    }),
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handlefullview(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ];
  const handlefullview = (params) => {
    const row = params.row;
    axios
      .get(
        `http://127.0.0.1:8000/ipregistration/get_Drug_Administration_datas?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}&Date=${row.Date}&DoctorName=${row.DoctorName}&Department=${row.Department}`
      )
      .then((response) => {
        const data = response.data;
        console.log(data);
        // Function to convert time to AM/PM format
        // Function to convert time to AM/PM format
        const convertToAMPM = (time) => {
          const numTime = parseInt(time);
          return numTime >= 1 && numTime <= 11
            ? numTime + " AM"
            : numTime === 12
            ? "12 PM"
            : numTime - 12 + " PM";
        };

        // Extract unique times from FrequencyTime arrays and convert them to AM/PM format
        const freqdata = [
          ...new Set(data.flatMap((p) => p.FrequencyTime.split(","))),
        ]
          .map((time) => convertToAMPM(time))
          .sort((a, b) => {
            // Extract AM/PM and numerical value from time string
            const [aNum, aPeriod] = a.split(" ");
            const [bNum, bPeriod] = b.split(" ");

            // Compare periods (AM comes before PM)
            if (aPeriod !== bPeriod) {
              return aPeriod.localeCompare(bPeriod);
            }

            // If periods are the same, sort numerically
            return parseInt(aNum) - parseInt(bNum);
          });

        console.log(freqdata);

        const medicine = data.map((p) => ({
          ...p,
          MedicineName: p.MedicineName + " " + p.Dosage,
          Frequencyval: p.FrequencyTime.split(",").map((time) => ({
            time: parseInt(time),
            frequency: 1,
          })),
        }));
        console.table(medicine);

        setTabletShow({
          frequencyTime: freqdata,
          medicinedata: medicine,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
const handleinputchange=(e,sss,ddd)=>{
console.log(sss,ddd)
}
  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={summa.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="Ip_data_grid"
              />
              {showdown > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && summa.length !== 0 ? null : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>

      <ToastContainer />
      {TabletShow && (
        <div className="DrugAdministrationTable">
        <table>
          <thead>
            <tr>
              <th>MedicineName/Time</th>
              {TabletShow &&
                TabletShow?.frequencyTime.map((time) => (
                  <th key={time}>{time}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {TabletShow &&
              TabletShow?.medicinedata.map((med) => (
                <tr key={med.MedicineName}>
                  <td>{med.MedicineName}</td>
                  {TabletShow?.frequencyTime.map((time) => {
                    const newwtime = time.split(" ");
                    let ttt = 0;
                    if (newwtime[1] === "PM") {
                      ttt = +newwtime[0] + 12;
                    } else {
                      ttt = +newwtime[0];
                    }
                    const isChecked = med.Frequencyval.some(
                      (f) => +f.time === ttt
                    );
                    const vallllll = med.Frequencyval.find(
                      (f) => +f.time === ttt
                    );
                    // console.log(med.Frequencyval[0].time, 'ppp');
                    console.log(vallllll, "ttt");
                    return (
                      <td key={time}>
                        {isChecked ? <input type="checkbox" onChange={(e)=>handleinputchange(e,med,time)}/> : "-"}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
        </div>
      )
      }
    </>
  );
};

export default IpDrugAdminister;
