import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import "./RoomsAvail.css";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { values } from "lodash";

function CustomTooltip({ content, children }) {
  return (
    <Tooltip title={content} placement="top" arrow>
      {children}
    </Tooltip>
  );
}
function RoomsAvail() {
  const dispatchvalue = useDispatch();
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const showroom = useSelector((state) => state.InPatients?.showroom);
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [floorFilter, setFloorFilter] = useState("All");
  const [roomTypeFilter, setRoomTypeFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("Available");
  const [Bookedcheck, setBookedcheck] = useState(false);
  // const [isOccupied, setIsOccupied] = useState(false);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);
  const [roomCounts, setRoomCounts] = useState([]);

  useEffect(() => {
    if (userRecord && userRecord?.location) {
      axios
        .get(
          `http://127.0.0.1:8000/ipregistration/get_room_count_data_total?Location=${userRecord?.location}`
        )
        .then((response) => {
          const data = response.data;
          setRoomCounts(data);
          console.log("data", data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRecord, userRecord?.location]);
  const handleBookClick = (index, room) => {
    if (showroom) {
      dispatchvalue({
        type: "selectRoom",
        value: {
          RoomId: room.S_No,
          BuildingName: room?.Building,
          BlockName: room?.Block,
          FloorName: room?.Floor,
          WardType: room?.Ward,
          RoomType: room?.RoomType,
          RoomNo: room?.Room,
          BedNo: room?.BedNo,
          Charge: room?.Charge,
          Location: room?.Location,
          Status: room?.Status,
          BookingStatus: room?.BookingStatus,
        },
      });
      const data = {
        S_No: room.S_No,
        Status: "BookingProcess",
      };
      axios
        .post(
          `http://127.0.0.1:8000/ipregistration/update_get_Room_Master_Data_booking`,
          data
        )
        .then((response) => {
          console.log(response.data);
          setBookedcheck(!Bookedcheck);
        })
        .catch((error) => {
          console.log(error);
        });
      dispatchvalue({ type: "showroom", value: false });
    } else {
      dispatchvalue({ type: "selectRoom", value: null });
      dispatchvalue({ type: "showroom", value: false });
    }
  };

  const handleCancelClick = (index, room) => {
    const data = {
      S_No: room.S_No,
      Status: "Available",
    };
    axios
      .post(
        `http://127.0.0.1:8000/ipregistration/update_get_Room_Master_Data_booking`,
        data
      )
      .then((response) => {
        console.log(response.data);
        setBookedcheck(!Bookedcheck);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOccupyClick = (index) => {};

  const handleVacateClick = (index) => {};

  const roomCards = filteredData.map((room, index) => {
    // Check if 'attender' is an array and join it if it is, otherwise display an empty string
    const attendersText = Array.isArray(room.attenders)
      ? room.attenders.join(", ")
      : room.attenders;

    const tooltipContent = (
      <div>
        Patient: {room.bookedBy}
        <br />
        Attenders: {attendersText}
        <br />
        Contact: {room.contactNumber}
        <br />
        Booked Date: {room.Admitdate}
      </div>
    );

    return (
      <CustomTooltip
        content={room.BookingStatus === "Booked" ? tooltipContent : ""}
        key={index}
      >
        <Card sx={{ maxWidth: 350 }}>
          <div className="tooltip-trigger">
            <CardContent className="Rooms_avail_card_container">
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                className="Rooms_avail_card_container_container"
              >
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Building <span>:</span>
                    </label>
                    <div>{room.Building}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Block Name<span>:</span>
                    </label>
                    <div>{room.Block}</div>
                  </div>
                </div>
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Floor Name <span>:</span>
                    </label>
                    <div>{room.Floor}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Ward Type <span>:</span>
                    </label>
                    <div>{room.Ward}</div>
                  </div>
                </div>
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Room Type <span>:</span>
                    </label>
                    <div>{room.RoomType}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Room No <span>:</span>
                    </label>
                    <div>{room.Room}</div>
                  </div>
                </div>
                <div className="Rooms_avail_card_one">
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Bed No <span>:</span>
                    </label>
                    <div>{room.BedNo}</div>
                  </div>
                  <div className="Rooms_avail_card_neww">
                    <label htmlFor="">
                      Charge <span>:</span>
                    </label>
                    <div>{room.Charge}</div>
                  </div>
                </div>
              </Typography>
              <Typography variant="h5" className="Rooms_avail_card_icon">
                <FontAwesomeIcon
                  icon={faBed}
                  style={{
                    color:
                      room.BookingStatus === "BookingProcess"
                        ? "yellow"
                        : room.BookingStatus === "Booked"
                        ? "orange"
                        : room.BookingStatus === "Occupied"
                        ? "red"
                        : room.BookingStatus === "Maintance"
                        ? "blue"
                        : "green",
                  }}
                  className={`Rooms_avail_carditems_availableIcon`}
                />
              </Typography>
            </CardContent>
            <CardActions className="Rooms_avail_card_btns">
              {showroom ? (
                <>
                  {room.BookingStatus === "BookingProcess" && (
                    <Button
                      size="small"
                      onClick={() => handleCancelClick(index, room)}
                    >
                      Cancel
                    </Button>
                  )}

                  {room.BookingStatus === "Booked" && (
                    <>
                      <Button
                        size="small"
                        onClick={() => handleCancelClick(index, room)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleOccupyClick(index, room)}
                      >
                        Occupy
                      </Button>
                    </>
                  )}

                  {room.BookingStatus === "Occupied" && (
                    <>
                      <Button
                        size="small"
                        onClick={() => handleVacateClick(index, room)}
                      >
                        Vacate
                      </Button>
                      <Button size="small">Transfer</Button>
                    </>
                  )}

                  {room.BookingStatus === "Available" && (
                    <>
                      <Button
                        size="small"
                        onClick={() => handleBookClick(index, room)}
                      >
                        Book
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleOccupyClick(index, room)}
                      >
                        Occupy
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {room.BookingStatus === "BookingProcess" && (
                    <>
                      <Button size="small" style={{ color: "yellow" }}>
                        Booking Under Process
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleCancelClick(index, room)}
                      >
                        cancel
                      </Button>
                    </>
                  )}
                  {room.BookingStatus === "Booked" && (
                    <Button size="small" style={{ color: "orange" }}>
                      Booked
                    </Button>
                  )}
                  {room.BookingStatus === "Occupied" && (
                    <Button size="small" style={{ color: "red" }}>
                      Occupied
                    </Button>
                  )}
                  {room.BookingStatus === "Maintance" && (
                    <Button size="small" style={{ color: "blue" }}>
                      Under Maintenance
                    </Button>
                  )}
                  {room.BookingStatus === "Available" && (
                    <Button size="small" style={{ color: "green" }}>
                      Available
                    </Button>
                  )}
                </>
              )}
            </CardActions>
          </div>
        </Card>
      </CustomTooltip>
    );
  });

  useEffect(() => {
    // Fetch room data using Axios when the component mounts
    console.log(userRecord);
    if (userRecord && userRecord?.location) {
      axios
        .get(
          `http://127.0.0.1:8000/ipregistration/get_Room_Master_Data?BookingStatus=${statusFilter}&Status=Active&Location=${userRecord?.location}`
        ) // Replace with your API endpoint
        .then((response) => {
          const data = response.data;
          setInitialData(data);
        })
        .catch((error) => {
          console.error("Error fetching room data:", error);
        });
    }
  }, [Bookedcheck, statusFilter, userRecord, userRecord?.location]);

  // useEffect(() => {
  //   // Copy the initial data to prevent it from changing
  //   const dataCopy = [...RoomsDatas];
  //   setInitialData(dataCopy);
  //   setFilteredData(dataCopy)
  // }, []);
  useEffect(() => {
    // Filter the data based on the selected filters
    let filteredRooms = initialData.filter((room) => {
      if (
        (floorFilter === "All" || room.floor === parseInt(floorFilter)) &&
        (roomTypeFilter === "All" || room.roomType === roomTypeFilter)
      ) {
        return true;
      }
      return false;
    });

    setFilteredData(filteredRooms);
  }, [initialData, floorFilter, roomTypeFilter]);

  return (
    <>
      <div className="yuuuuy">
        <div className="Ip_room_avail_container">
          <div className="head_rom_mage">
            <h3>Room Management</h3>
          </div>
          <div className="Inv_form_div_container_pr">
            <div className="Inv_form_row_pr">
              <div className="Inv_form_div_pr">
                <div className="eddw">
                  <label>
                    Floor <span>:</span>
                  </label>
                </div>
                <select
                  name="floorFilter"
                  id="floorFilter"
                  value={floorFilter}
                  onChange={(e) => setFloorFilter(e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="1">Floor 1</option>
                  <option value="2">Floor 2</option>
                  <option value="3">Floor 3</option>
                  <option value="4">Floor 4</option>
                </select>
              </div>
              <div className="Inv_form_div_pr">
                <div className="eddw">
                  <label>
                    Room Type <span>:</span>
                  </label>
                </div>
                <select
                  name="roomTypeFilter"
                  id="roomTypeFilter"
                  value={roomTypeFilter}
                  onChange={(e) => setRoomTypeFilter(e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="AC">Ac</option>
                  <option value="Non-AC">Non - AC</option>
                  <option value="ICU">ICU</option>
                  <option value="General">General</option>
                </select>
              </div>
              <div className="Inv_form_div_pr">
                <div className="eddw">
                  <label>
                    Status <span>:</span>
                  </label>
                </div>
                <select
                  name="statusFilter"
                  id="statusFilter"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="All">All</option>
                  <option value="Available">Available</option>
                  <option value="BookingProcess">Process</option>
                  <option value="Booked">Booked</option>
                  <option value="Occupied">Occupied</option>
                  <option value="Maintance">Under Maintance</option>
                </select>
              </div>
            </div>
          </div>

          <div className="Rooms_avail_table">
            <table>
              <thead>
                <tr>
                  {roomCounts &&
                    roomCounts.length > 0 &&
                    roomCounts.map((room, index) => (
                      <th key={index}>
                        {Object.keys(room)[0]} : {Object.values(room)[0]}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {roomCounts &&
                  roomCounts.length > 0 &&
                  Object.keys(roomCounts[0])
                    .filter((key, indx) => indx !== 0)
                    .map((key) => (
                      <tr key={key}>
                        {roomCounts.map((item, index) => (
                          <td key={index}>
                            <div>
                              <span>{key}</span> : <span>{item[key]}</span>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>

          <div
            className="Rooms_avail_card"
            style={{ width: showroom ? "83%" : "92%" }}
          >
            {roomCards}
          </div>
        </div>
      </div>
    </>
  );
}

export default RoomsAvail;
