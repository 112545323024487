import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import "./Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import TestMaster from "./TestMaster";
import AgeSetUp from "./AgeSetUp";
import ReferanceRange from "./ReferanceRange";
import Remarks from "./Remarks";
import AddInterpretation from "./AddInterpretation";
import Commercial from "./Commercial";
import RuleBased from "./RuleBased";
import ReflexMaster from "./ReflexMaster";
import Outsourceratecard from "./Outsourceratecard";

function TestMasterNavigation({ userRecord, isSidebarOpen, Data }) {
  console.log(Data);

  const [activeTab, setActiveTab] = useState("TestMaster");
  const [isToggled, setIsToggled] = useState(false);
  const [formData, setFormData] = useState({
    SerialNo: "",
    PatientID: "",
    AppointmentID: "",
    visitNo: "",
    firstName: "",
    lastName: "",
    AppointmentDate: "",
  });

  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!event.target.closest(".new-kit")) {
        closeToggle();
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  });

  useEffect(() => {
    // Function to fetch data using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://127.0.0.1:5000/appointmentmanagement/get_appointments_Navigation?patientid=${Data.serialid}&location=${userRecord.location}`
        );
        console.log(response.data);

        const matchingAppointment = response.data[0];
        console.log(matchingAppointment);

        setFormData({
          SerialNo: matchingAppointment.appointment_patientregisterID,
          PatientID: matchingAppointment.PatientID,
          AppointmentID: matchingAppointment.AppointmentID,
          visitNo: matchingAppointment.VisitID,
          firstName: matchingAppointment.FirstName,
          lastName: matchingAppointment.LastName,
          DoctorName: matchingAppointment.DoctorName,
          Status: matchingAppointment.Status,
          AppointmentDate: matchingAppointment.AppointmentDate,
        });
        //       } else {
        //         // Data retrieval failed
        //         console.error('Data retrieval failed:', response.data.message);
        //       }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    //   // Call the fetchData function when the component mounts
    fetchData();
  }, [Data]);

  return (
    <>

      <div className="appointment">
        <div className="h_head">
          <h4>Test Master</h4>
        </div>

  
        <br />
        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("TestMaster")}>
                Test Master
              </button>
              |
              <button onClick={() => handleTabChange("AgeSetUp")}>
                Age SetUp
              </button>
              |
              {/* <button onClick={() => handleTabChange("ReferanceRange")}>
                Referance Range
              </button>
              | */}
              <button onClick={() => handleTabChange("Commercial")}>
                Commercial
              </button>
              |
              {/* <button onClick={() => handleTabChange("RuleBased")}>
                Outsource Ratecard
              </button>
              | */}
              <button onClick={() => handleTabChange("ReflexMaster")}>
                Reflex Master
              </button>
              |
              <button onClick={() => handleTabChange("AddInterpretation")}>
                Add Interpretation
              </button>
              {/* |
              <button onClick={() => handleTabChange("Remarks")}>
                Remarks
              </button> */}
              {/* <div class="Lab_dropdown">
                <button class="Lab_button">Reflex Master</button>
                <div class="Lab_dropdown_content">
                  <button onClick={() => handleTabChange("ReflexMaster")}>
                  Reflex Master
                  </button>
                 
                </div>
              </div> */}
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("TestMaster")}>
                      Test Master
                    </button>
                    |
                    <button onClick={() => handleTabChange("AgeSetUp")}>
                      Age SetUp
                    </button>
                    |
                    <button onClick={() => handleTabChange("ReferanceRange")}>
                      Referance Range
                    </button>
                    |
                    {/* <button onClick={() => handleTabChange("Commercial")}>
                      Commercial
                    </button>
                    | */}
                    {/* <button onClick={() => handleTabChange("RuleBased")}>
                    Outsource Ratecard
                    </button>
                    | */}
                    <div class="Lab_dropdown">
                      <button class="Lab_button">Lab</button>
                      <div class="Lab_dropdown_content">
                        <button onClick={() => handleTabChange("ReflexMaster")}>
                          Reflex Master
                        </button>
                        |
                        <button
                          onClick={() => handleTabChange("AddInterpretation")}
                        >
                          Add Interpretation
                        </button>
                        {/* |
                        <button onClick={() => handleTabChange("Remarks")}>
                          Remarks
                        </button> */}
                      </div>
                    </div>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "TestMaster" && (
        <TestMaster formData={formData} userRecord={userRecord} />
      )}
      {activeTab === "AgeSetUp" && (
        <AgeSetUp formData={formData} userRecord={userRecord} />
      )}
      {/* {activeTab === "ReferanceRange" && (
        <ReferanceRange
          formData={formData}
          userRecord={userRecord}
          isSidebarOpen={isSidebarOpen}
        />
      )} */}
      {activeTab === "Commercial" && (
        <Commercial formData={formData} userRecord={userRecord} />
      )}
      {/* {activeTab === "RuleBased" && (
        <Outsourceratecard formData={formData} userRecord={userRecord} />
      )} */}
      {activeTab === "ReflexMaster" && (
        <ReflexMaster formData={formData} userRecord={userRecord} />
      )}
      {activeTab === "AddInterpretation" && (
        <AddInterpretation formData={formData} userRecord={userRecord} />
      )}
      {/* {activeTab === "Remarks" && (
        <Remarks formData={formData} userRecord={userRecord} />
      )} */}
    </>
  );
}

export default TestMasterNavigation;



