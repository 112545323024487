import React, { useState, useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function LabIndentRaiseMaster() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [formDataPurchaseInvoice, setFormDataPurchaseInvoice] = useState({
    ProductCategory: "",
    IndentRaiseInvoice: "",
    RequestTo: 'CentralStore',
    RequestFrom: userRecord?.location,
    RequestDate: format(new Date(), "yyyy-MM-dd"),
    Reason: "",
    Location: userRecord?.location,
    CreatedBy: userRecord?.username,
  });
  const [formDataPurchaseInvoicePrint, setFormDataPurchaseInvoicePrint] =
    useState(null);
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  // const [PrintPdf, setPrintPdf] = useState(null);
  const [formDataPurchase, setFormDataPurchase] = useState({
    ItemCode: "",
    ItemName: "",
    GenericName: "",
    Strength: "",
    UOM: "",
    PackIn: "",
    PackQuantity: "",
    OrderQuantity: "",
    ApprovedQuantity: "",
    Status: "Waiting",
  });

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
  });
  const [IndexEdit, setIndexEdit] = useState(null);
  const currentDate = format(new Date(), "dd-MM-yyyy");

  const [ItemCodedetailes, setItemCodedetailes] = useState([]);



  useEffect(() => {
    if (Object.keys(userRecord).length !== 0) {
      setFormDataPurchaseInvoice(prev => ({
        ...prev,
        RequestFrom: userRecord.location || '', 
        Location: userRecord.location || '', 
        CreatedBy: userRecord.username || '', 
      }));
    }
  }, [userRecord]);

 

  useEffect(() => {
      if(formDataPurchaseInvoice.ProductCategory !==''){
        axios
        .get(
          `${urllink}LabInventory/get_medical_purchase_Raise_indent_invoice_no?ProductCategory=${formDataPurchaseInvoice.ProductCategory}`
        )
        .then((response) => {
          setFormDataPurchaseInvoice((prevData) => ({
            ...prevData,
            IndentRaiseInvoice: response.data.next_id,
          }));
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error fetching data:", error);
        });
      
      
        let GetLinkProduct =''
  
        if(formDataPurchaseInvoice.ProductCategory === 'Medical'){
          GetLinkProduct = 'get_Medical_ProductMaster_data'
        }else if(formDataPurchaseInvoice.ProductCategory === 'NonMedical'){
          GetLinkProduct = 'get_NonMedical_ProductMaster_data'
        }else if(formDataPurchaseInvoice.ProductCategory === 'Stationary'){
          GetLinkProduct = 'get_Stationary_ProductMaster_data'
        }

        axios.get(
          `${urllink}LabInventory/${GetLinkProduct}`
        )
        .then((response) => {
          
          const data = response.data.filter((ele) => {
            return ele.Status === "Active";
          });
          console.log('yaaa',data)
          setItemCodedetailes(data);
        })
        .catch((error) => {
          console.error("Error fetching patients data:", error);
        });
      
      
      }  

    
  }, [formDataPurchaseInvoice.ProductCategory]);

  useEffect(() => {
    // clinic detials
    axios
      .get(`${urllink}usercontrol/getClinic`)
      .then((response) => {
        const data = response.data[0];

        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.door_no + "," + data.area + "," + data.street,
            ClinicGST: data.Gst_no,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phone_no,
            ClinicLandLineNo: data.landline_no,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        const data = response.data;
        setClinicDetials((prev) => ({
          ...prev,
          ClinicName: data.Clinic_Name,
          ClinicLogo: `data:image/png;base64,${data.Clinic_Logo}`,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "ItemCode") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemCode === value;
      });
      if (Set_datas) {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemName: Set_datas.ItemName,
          GenericName: Set_datas.GenericName,
          Strength: Set_datas.Strength,
          UOM: Set_datas.UOM,
          PackIn: Set_datas.ProductType,
          PackQuantity: Set_datas.ProductQuantity,
          OrderQuantity: Set_datas.OrderQuantity,
          ApprovedQuantity: Set_datas.ApprovedQuantity,
        }));
      } else {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemName: "",
          GenericName: "",
          Strength: "",
          UOM: "",
          PackIn: "",
          PackQuantity: "",
          OrderQuantity: "",
          ApprovedQuantity: "",
        }));
      }
    } else if (name === "ItemName") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemName === value;
      });

      if (Set_datas) {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemCode: Set_datas.ItemCode,
          GenericName: Set_datas.GenericName,
          Strength: Set_datas.Strength,
          UOM: Set_datas.UOM,
          PackIn: Set_datas.ProductType,
          PackQuantity: Set_datas.ProductQuantity,
          OrderQuantity: Set_datas.OrderQuantity,
          ApprovedQuantity: Set_datas.ApprovedQuantity,
        }));
      } else {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ItemCode: "",
          GenericName: "",
          Strength: "",
          UOM: "",
          PackIn: "",
          PackQuantity: "",
          OrderQuantity: "",
          ApprovedQuantity: "",
        }));
      }
    } else {
      if (name === "OrderQuantity") {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
          ApprovedQuantity: value,
        }));
      } else {
        setFormDataPurchase((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    if (name === "ProductCategory") {
      setFormDataPurchaseInvoice((prevData) => ({
        ...prevData,
        [name]: value,
        Reason: "",
      }));

      handleCancel();
      setCartItems([]);
    } else {
      setFormDataPurchaseInvoice((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAddToCart = () => {
    const existingItem = cartItems.find(
      (item) => item.ItemCode === formDataPurchase.ItemCode
    );

    if (existingItem) {
      alert(
        `Item with code ${formDataPurchase.ItemCode} already exists in the cart.`
      );
    } else {
      let updatedata;
      let requiredFields;
      if (
        formDataPurchaseInvoice.ProductCategory === "Medical" 
      ) {
        updatedata = {
          ItemCode: formDataPurchase.ItemCode,
          ItemName: formDataPurchase.ItemName,
          GenericName: formDataPurchase.GenericName,
          Strength: formDataPurchase.Strength,
          UOM: formDataPurchase.UOM,
          PackIn: formDataPurchase.PackIn,
          PackQuantity: formDataPurchase.PackQuantity,
          OrderQuantity: formDataPurchase.OrderQuantity,
          ApprovedQuantity: formDataPurchase.ApprovedQuantity,
          Status: formDataPurchase.Status,
        };
        requiredFields = [
          "ItemCode",
          "ItemName",
          "GenericName",
          "Strength",
          "UOM",
          "PackIn",
          "PackQuantity",
          "OrderQuantity",
        ];
      } else {
        updatedata = {
          ItemCode: formDataPurchase.ItemCode,
          ItemName: formDataPurchase.ItemName,
          GenericName: formDataPurchase.GenericName,
          Strength: formDataPurchase.Strength,
          UOM: formDataPurchase.UOM,
          PackIn: formDataPurchase.PackIn,
          PackQuantity: formDataPurchase.PackQuantity,
          OrderQuantity: formDataPurchase.OrderQuantity,
          ApprovedQuantity: formDataPurchase.ApprovedQuantity,
          Status: formDataPurchase.Status,
        };
        requiredFields = [
          "ItemCode",
          "ItemName",
          "PackIn",
          "PackQuantity",
          "OrderQuantity",
        ];
      }
      const missingFields = requiredFields.filter(
        (field) => !formDataPurchase[field]
      );
      if (missingFields.length === 0) {
        setCartItems((prev) => [...prev, updatedata]);
        handleCancel();
      } else {
        alert(
          `Please fill out all required fields: ${missingFields.join(", ")}`
        );
      }
    }
  };

  const handleDeleteCartItem = (itemCode) => {
    setCartItems((prev) => prev.filter((item) => item.ItemCode !== itemCode));
  };
  const handleEditCartItem = (itemCode) => {
    const selectedItem = cartItems.find((item) => item.ItemCode === itemCode);
    setIndexEdit(selectedItem);

    if (selectedItem) {
      // Set the form data with the selected item's data
      setFormDataPurchase({
        ItemCode: selectedItem.ItemCode,
        ItemName: selectedItem.ItemName,
        GenericName: selectedItem.GenericName,
        Strength: selectedItem.Strength,
        UOM: selectedItem.UOM,
        PackIn: selectedItem.PackIn,
        PackQuantity: selectedItem.PackQuantity,
        OrderQuantity: selectedItem.OrderQuantity,
        ApprovedQuantity: selectedItem.ApprovedQuantity,
        Status: selectedItem.Status,
      });
    }
  };

  const handleUpdateCartItem = () => {
    const updatedItem = {
      ItemCode: formDataPurchase.ItemCode,
      ItemName: formDataPurchase.ItemName,
      GenericName: formDataPurchase.GenericName,
      Strength: formDataPurchase.Strength,
      UOM: formDataPurchase.UOM,
      PackIn: formDataPurchase.PackIn,
      PackQuantity: formDataPurchase.PackQuantity,
      OrderQuantity: formDataPurchase.OrderQuantity,
      ApprovedQuantity: formDataPurchase.ApprovedQuantity,
      Status: formDataPurchase.Status,
    };

    // Check if the updated item has all required fields
    const requiredFields = ["ItemCode", "ItemName", "PackIn", "PackQuantity"];
    const missingFields = requiredFields.filter((field) => !updatedItem[field]);

    if (missingFields.length === 0) {
      // Find the index of the item in the cart with the matching item code
      const indexOfItemToUpdate = cartItems.findIndex(
        (item) => item.ItemCode === formDataPurchase.ItemCode
      );

      // Update the cart with the modified item
      setCartItems((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = updatedItem;
        return updatedCart;
      });

      handleCancel();
      setIndexEdit(null);
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleSubmit = () => {
    setFormDataPurchaseInvoicePrint({ ...formDataPurchaseInvoice });

    const updatedItem = {
      IndentRaiseInvoice: formDataPurchaseInvoice.IndentRaiseInvoice,
      RequestTo: formDataPurchaseInvoice.RequestTo,
      RequestFrom: formDataPurchaseInvoice.RequestFrom,
      Reason: formDataPurchaseInvoice.Reason,
      RequestDate: formDataPurchaseInvoice.RequestDate,
      CreatedBy: formDataPurchaseInvoice.CreatedBy,
      Location: formDataPurchaseInvoice.Location,
      ProductCategory: formDataPurchaseInvoice.ProductCategory,
      cartItems: cartItems, /// array
    };

    const requiredFields = [
      "ProductCategory",
      "IndentRaiseInvoice",
      "RequestTo",
      "RequestFrom",
      "Reason",
    ];
    const missingFields = requiredFields.filter(
      (field) => !formDataPurchaseInvoice[field]
    );

    if (missingFields.length === 0) {
      if (cartItems.length !== 0) {
        if (
          formDataPurchaseInvoice.ProductCategory === "Medical"
        ) {
          axios
            .post(
              `${urllink}LabInventory/insert_medical_purchase_Raise_indent_data`,
              updatedItem
            )
            .then((response) => {
              clearFormInputs();
              navigate("/Home/LabIndentRaiseList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        } else if (formDataPurchaseInvoice.ProductCategory === "NonMedical" || formDataPurchaseInvoice.ProductCategory === "Stationary" ) {
          axios
            .post(
              `${urllink}LabInventory/insert_NonMedical_purchase_Raise_indent_data`,
              updatedItem
            )
            .then((response) => {
              clearFormInputs();
              navigate("/Home/LabIndentRaiseList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        } 
      } else {
        alert("No Items to Purchase");
      }
    } else {
      alert(`Missing fields: ${missingFields.join(", ")}`);
    }

    console.table(cartItems);
  };

  const handleCancel = () => {
    setFormDataPurchase({
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      PackIn: "",
      PackQuantity: "",
      OrderQuantity: "",
      ApprovedQuantity: "",
      Status: "Waiting",
    });
  };

  const clearFormInputs = () => {
    setFormDataPurchaseInvoice((prev) => ({
      ...prev,
      ProductCategory: "",
      IndentRaiseInvoice: "",
      RequestTo: "",
      RequestFrom: "",
      Reason: "",
      CreatedBy: "",
    }));

    setFormDataPurchase({
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      PackIn: "",
      PackQuantity: "",
      OrderQuantity: "",
      ApprovedQuantity: "",
      Status: "Waiting",
    });
  };

  const forPrintData = () => {
    return (
      <div className="billing-invoice" id="For_print_pdf">
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            <span>{ClinicDetials.ClinicName}</span>
            <div>
              <span>{ClinicDetials.ClinicAddress},</span>
              <span>
                {ClinicDetials.ClinicCity +
                  "," +
                  ClinicDetials.ClinicState +
                  "," +
                  ClinicDetials.ClinicCode}
              </span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
              <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>
          </div>
        </div>
        <div
          className="Register_btn_con"
          style={{ color: "hsl(33,100%,50%)", fontWeight: 600 }}
        >
          Bill Cancelation
        </div>
        <div className="new_billing_address">
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Product Type <span>:</span>
              </label>
              <span>{formDataPurchaseInvoicePrint.ProductCategory}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Raised From<span>:</span>
              </label>
              <span>{formDataPurchaseInvoicePrint.SupplierCode}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Raised To <span>:</span>
              </label>
              <span>{formDataPurchaseInvoicePrint.SupplierName}</span>
            </div>
          </div>
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Invoice No <span>:</span>
              </label>
              <span>{formDataPurchaseInvoicePrint.IndentRaiseInvoice}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Date <span>:</span>
              </label>
              <span>{currentDate}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Reason<span>:</span>
              </label>
              <span>{formDataPurchaseInvoicePrint.Reason}</span>
            </div>
          </div>
        </div>

        {(formDataPurchaseInvoicePrint.ProductCategory ===
          "Medical" ) && (
            <div className="new_billing_invoice_detials">
              <table>
                <thead style={{ backgroundColor: "hsl(33,100%,50%)" }}>
                  <tr>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Generic Name</th>
                    <th>Strength</th>
                    <th>UOM</th>
                    <th>Pack Type</th>
                    <th>Pack Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr key={index}>
                      <td>{item.ItemCode}</td>
                      <td>{item.ItemName}</td>
                      <td>{item.GenericName}</td>
                      <td>{item.Strength}</td>
                      <td>{item.UOM}</td>
                      <td>{item.PackIn}</td>
                      <td>{item.PackQuantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

        {(
          formDataPurchaseInvoicePrint.ProductCategory !==
          "Medical" 
        ) && (
            <div className="new_billing_invoice_detials">
              <table>
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Pack Type</th>
                    <th>Pack Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr key={index}>
                      <td>{item.ItemCode}</td>
                      <td>{item.ItemName}</td>
                      <td>{item.PackIn}</td>
                      <td>{item.PackQuantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </div>
    );
  };

  return (
    <>
      {isPrintButtonVisible ? (
       <div className="appointment">
          <div className="h_head">
            <h4 className="Stoct_Product_master_head_h3">
              <ShoppingCartIcon /> Indent Raise L
            </h4>
          </div>
          <br />

          <div className="RegisFormcon">
              <div className="RegisForm_1">
              <label htmlFor="ProductType">
                Product Category <span>:</span>
              </label>
              <select
                name="ProductCategory"
                value={formDataPurchaseInvoice.ProductCategory}
                onChange={handleInputChange1}
              >
                <option value="">-Select-</option>
                <option value="Medical">Medical</option>
                <option value="NonMedical">Non Medical</option>
                <option value="Stationary">Stationary</option>
              </select>
            </div>
            <div className="RegisForm_1">
              <label htmlFor="ProductType">Invoice No </label>
              <input
                type="text"
                name="IndentRaiseInvoice"
                value={formDataPurchaseInvoice.IndentRaiseInvoice}
                onChange={handleInputChange1}
                required
                readOnly
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="code">
                Request From <span>:</span>
              </label>
              <input
                type="text"
                name="RequestFrom"
                value={formDataPurchaseInvoice.RequestFrom}
                onChange={handleInputChange1}
                autoComplete="off"
                readOnly
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="itemName">
                Request To <span>:</span>
              </label>
              <input
                type="text"
                name="RequestTo"
                value={formDataPurchaseInvoice.RequestTo}
                onChange={handleInputChange1}
                autoComplete="off"
                readOnly
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="ProductType">
                Request Date <span>:</span>
              </label>
              <input
                type="date"
                name="RequestDate"
                value={formDataPurchaseInvoice.RequestDate}
                onChange={handleInputChange1}
                required
                readOnly
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="ProductType">
                Reason <span>:</span>
              </label>
              <input
                type="text"
                name="Reason"
                value={formDataPurchaseInvoice.Reason}
                onChange={handleInputChange1}
                required
              />
            </div>
          </div>
          <br />
          <div className="Add_items_Purchase_Master">
            <span>Add Items</span>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="ProductType">
                Item Code<span>:</span>
              </label>
              <input
                type="text"
                name="ItemCode"
                list="ItemCode_list"
                value={formDataPurchase.ItemCode}
                onChange={handleInputChange}
                required
                autoComplete="off"
              />
              <datalist id="ItemCode_list">
                {ItemCodedetailes.map((item, index) => (
                  <option key={index} value={item.ItemCode}></option>
                ))}
              </datalist>
            </div>
            <div className="RegisForm_1">
              <label htmlFor="ProductType">
                Item Name <span>:</span>
              </label>
              <input
                type="text"
                name="ItemName"
                list="ItemCode_name"
                value={formDataPurchase.ItemName}
                onChange={handleInputChange}
                required
              />
              <datalist id="ItemCode_name">
                {ItemCodedetailes.map((item, index) => (
                  <option key={index} value={item.ItemName}></option>
                ))}
              </datalist>
            </div>
            {(formDataPurchaseInvoice.ProductCategory === "Medical") && (
              <>
                <div className="RegisForm_1">
                  <label htmlFor="code">
                    Generic Name <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="GenericName"
                    value={formDataPurchase.GenericName}
                    onChange={handleInputChange}
                    required
                    readOnly
                  />
                </div>
              
                <div className="RegisForm_1">
                  <label htmlFor="itemName">
                    Strength <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="Strength"
                    value={formDataPurchase.Strength}
                    onChange={handleInputChange}
                    readOnly
                  />
                </div>
          
                  <div className="RegisForm_1">
                    <label htmlFor="generic">
                      UOM <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="UOM"
                      value={formDataPurchase.UOM}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                  </>
              )}
                  <div className="RegisForm_1">
                    <label htmlFor="strength">
                      Pack Type<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="PackIn"
                      value={formDataPurchase.PackIn}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="uom">
                      Pack Quantity <span>:</span>
                    </label>
                    <input
                      type="number"
                      name="PackQuantity"
                      value={formDataPurchase.PackQuantity}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="uom">
                      Order Quantity <span>:</span>
                    </label>
                    <input
                      type="number"
                      name="OrderQuantity"
                      value={formDataPurchase.OrderQuantity}
                      onChange={handleInputChange}
                    />
                  </div>

            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleCancel}>
                Clear
              </button>
              {!IndexEdit ? (
                <button className="RegisterForm_1_btns" onClick={handleAddToCart}>
                  Add
                </button>
              ) : (
                <button
                  className="RegisterForm_1_btns"
                  onClick={handleUpdateCartItem}
                >
                  Update
                </button>
              )}
            </div>
            {cartItems.length !== 0 && (
              <div className="Stoct_Product_master_form_table_container">
                <div className="Stoct_Product_master_form_table_container_11">
                  {(formDataPurchaseInvoice.ProductCategory ===
                    "Medical" ) && (
                      // formDataPurchaseInvoice.ProductCategory === 'Surgical'
                      <div className="Stoct_Product_master_form_table_container_11">
                        <table>
                          <thead>
                            <tr>
                              <th>Item Code</th>
                              <th>Item Name</th>
                              <th>Generic Name</th>
                              <th>Strength</th>
                              <th>UOM</th>
                              <th>Pack</th>
                              <th>Pack Quantity</th>
                              <th>Order Quantity</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((item, index) => (
                              <tr key={index}>
                                <td>{item.ItemCode}</td>
                                <td>{item.ItemName}</td>
                                <td>{item.GenericName}</td>
                                <td>{item.Strength}</td>
                                <td>{item.UOM}</td>
                                <td>{item.PackIn}</td>
                                <td>{item.PackQuantity}</td>
                                <td>{item.OrderQuantity}</td>
                                <td className="table_btns_action">
                                  <button className="cell_btn"
                                    onClick={() =>
                                      handleEditCartItem(item.ItemCode)
                                    }
                                  >
                                    <EditIcon />
                                  </button>

                                  <button className="cell_btn"
                                    onClick={() =>
                                      handleDeleteCartItem(item.ItemCode)
                                    }
                                  >
                                    <DeleteIcon />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  {formDataPurchaseInvoice.ProductCategory !==
                    "Medical" && (
                      // formDataPurchaseInvoice.ProductCategory !== 'Surgical'
                      <div className="Stoct_Product_master_form_table_container_11">
                        <table>
                          <thead>
                            <tr>
                              <th>Item Code</th>
                              <th>Item Name</th>
                              <th>Pack</th>
                              <th>Pack Quantity</th>
                              <th>Order Quantity</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((item, index) => (
                              <tr key={index}>
                                <td>{item.ItemCode}</td>
                                <td>{item.ItemName}</td>
                                <td>{item.PackIn}</td>
                                <td>{item.PackQuantity}</td>
                                <td>{item.OrderQuantity}</td>
                                <td className="table_btns_action">
                                  <button className="cell_btn"
                                    onClick={() =>
                                      handleEditCartItem(item.ItemCode)
                                    }
                                  >
                                    <EditIcon />
                                  </button>

                                  <button className="cell_btn"
                                    onClick={() =>
                                      handleDeleteCartItem(item.ItemCode)
                                    }
                                  >
                                    <DeleteIcon />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              </div>
            )}

            {cartItems.length !== 0 && (
              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            )}
          </div>
          <br />

        </div>
      ) : (
        forPrintData()
      )}
    </>
  );
}

export default LabIndentRaiseMaster;
