import React, { useState, useEffect } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TestMaster.css';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from 'react-redux';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

function AddInterpretation() {
  const urllink=useSelector(state=>state.userRecord?.UrlLink)
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [header,setheader] = useState('');
  const [comments,setComments] = useState('');
  const [testname,setTestName] = useState('');
  const [addDatas, setAddDatas] = useState([]);
  const [addData, setAddData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  useEffect(()=>{
    axios.get(`${urllink}usercontrol/gettestname`)
    .then((response)=>{
        console.log(response.data)
        const data= response.data.test_name
        setTestName(data);
    })
    .catch((error)=>{
        console.log(error)
    })
},[])


   const handlesubmit=()=>{
    const postdata = {
      testname: testname,
      header: header,
      comments: comments
    }

    if(!header || !comments){
      alert('please enter all inputs');
    }
    else{
      axios.post(`${urllink}usercontrol/insertinterpretation`,postdata)
      .then((response)=>{
        console.log(response);
        successMsg(response.data.message);
        setComments('');
        setheader('');
        fetchAddInterpretationData();
      })
      .catch((error)=>{
        console.log(error);
      })
    }
    


   }
   const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
   React.useEffect(() => {
    fetchAddInterpretationData();
  }, []);
  const fetchAddInterpretationData = () => {
    axios
      .get("http://127.0.0.1:8000/usercontrol/getaddinterpretationdata")
      .then((response) => {
        const data = response.data;
        console.log("fetchAddInterpretationData", data);

        setAddDatas(data);
      })
      .catch((error) => {
        console.error("Error fetching AddInterpretationData  data:", error);
      });
  };
  const handleEdit = (row) => {
   
    setheader(row.Header_interpretation);
    setComments(row.Comments);
    setTestName(row.Test_Name);
    setIsEditMode(true);
    setSelectedid(row.Test_Id); // Assuming `id` is the identifier
  };
  const handleUpdateMethod = () => {
    const updatedata = {
      method_id: selectedid, // Assuming `id` is the identifier
      header: header,
      comments: comments,
      testname: testname,
    };

    axios
      .post(
        "http://127.0.0.1:8000/usercontrol/updateaddinterpretationdata",
        updatedata
      )
      .then((response) => {
        console.log(response.data);
        successMsg(response.data.message);
        setIsEditMode(false);
        setSelectedid(null);
        setComments("");
        setheader("");
       
        setTestName("");
        fetchAddInterpretationData();
      })

      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const totalPages = Math.ceil(addDatas.length / pageSize);
  return (

    <>
      <div className="appointment">
        <div className="RegisFormcon">
         <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="selectTest">
            Test Name<span>:</span>
          </label>
          <input
            type="text"
            id="testname"
            name="selectTest"
            placeholder="Enter Test Name"
            className="new_clinic_form_inp"
            required
            value={testname}
            onChange={handleInputChange}
            readOnly
          />
        </div> 
          <div className="RegisForm_1">
            <label htmlFor="validation">
              Header For interpretation <span>:</span>
            </label>
            <textarea
              id="remarks"
              name="remarks"
              value={header}
              onChange={(e) => {
                setheader(e.target.value);
              }}
              className="custom-textarea"
            ></textarea>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="remarks">
              Comments<span>:</span>
            </label>
            <textarea
              id="remarks"
              name="remarks"
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
              className="custom-textarea"
            ></textarea>
          </div>
        </div>

        <div className="Register_btn_con">
        <button
          className="btn_1"
          onClick={isEditMode ? handleUpdateMethod : handlesubmit}
        >
          {isEditMode ? "Update" : <AddIcon />}
        </button>
        </div>


<div style={{ width: "100%", display: "grid", placeItems: "center" }}>
  <h4>Table</h4>
  <div className="Selected-table-container">
    <table className="selected-medicine-table2">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Test Name</th>
          <th>Header interpretation</th>
          <th>Comments</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        {addDatas.slice(page * pageSize, (page + 1) * pageSize).map((row, index) => (
          <tr key={index}>
            <td>{index + 1 + page * pageSize}</td>
            <td>{row.Test_Name}</td>
            <td>{row.Header_interpretation}</td>
            <td>{row.Comments}</td>
            <td>
              <button onClick={() => handleEdit(row)}>
                <EditIcon />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

{addDatas.length > pageSize && (
  <div className="grid_foot">
    <button
      onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
      disabled={page === 0}
    >
      Previous
    </button>
    Page {page + 1} of {totalPages}
    <button
      onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
      disabled={page === totalPages - 1}
    >
      Next
    </button>
  </div>
)}

{addDatas.length === 0 && (
  <div className="IP_norecords">
    <span>No Records Found</span>
  </div>
)}
<ToastContainer />
      
      </div>
    </>
  );
  
  
}

export default AddInterpretation;
