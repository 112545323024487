import React, { useState } from 'react';
import '../Pettycash/Pettycash.css';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { useSelector } from 'react-redux';


function RoleManagement( ) {

  const [roleName, setRoleName] = useState('');
  const [locationName, setLocationName] = useState('');
  const[department,setDepartment]=useState('');
  const[departmentrole,setDepartmentRole]=useState('')

  const urllink=useSelector(state=>state.userRecord?.UrlLink)

  const [roleData, setRoleData] = React.useState([]);
  const [locationData, setLocationData] = React.useState([]);
  const [departmentData, setDepartmentData] = React.useState([]);




  React.useEffect(() => {
    // Fetch role data from the backend and update the state
    fetchRoleData();
    fetchLocationData();
    fetchDepartmentData();
    // fetchdepartmentforrole();
  }, []);

  const fetchRoleData = () => {
    // Use Axios to make a GET request
    axios.get(`${urllink}usercontrol/getRoleData`)
      .then((response) => {
        const data = response.data;
        console.log(data)
        setRoleData(data);
      })
      .catch((error) => {
        console.error('Error fetching role data:', error);
      });
  };
  // const fetchdepartmentforrole = () => {
  //   // Use Axios to make a GET request
  //   axios.get('${urllink}usercontrol/getDepartment')
  //     .then((response) => {
  //       const data = response.data;
  //       console.log(data)
  //       setDepartmentRole(data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching role data:', error);
  //     });
  // };

  const fetchLocationData = () => {
    axios.get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        console.log(data)

        setLocationData(data);
      })
      .catch((error) => {
        console.error('Error fetching location data:', error);
      });
  };

  const fetchDepartmentData = () => {
    axios.get(`${urllink}usercontrol/getDepartment`)
      .then((response) => {
        const data = response.data;
        console.log(data)

        setDepartmentData(data);
      })
      .catch((error) => {
        console.error('Error fetching Department data:', error);
      });
  };


  const handleSubmitOne = (e) => {
    e.preventDefault();

    const uppercaseRoleName = roleName.toUpperCase();


    if (uppercaseRoleName !== '' && departmentrole !== 'select') {
      // Create a FormData object with the role name
      const formData = new FormData();
      formData.append('roleName', uppercaseRoleName);
      formData.append('department', departmentrole);


      // Use Axios to make a POST request
      axios.post(`${urllink}usercontrol/insertRole`, formData)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error);
          }

          // Clear the form field after successful submission
          setRoleName('');
          setDepartmentRole('select');

          // Fetch the updated role data
          fetchRoleData();
        })
        .catch((error) => {
          console.error('Error:', error);
          errmsg('Error occurred while inserting role.');
        });
    } else {
      userwarn('Please enter the role name and select the department');
    }
  };
  const handleEditClick = (data) => {
    if (data.status === 'Inactive') {
      const status = 'Active'
      const role_id = data.role_id
      Update_status_fun(role_id, status)
    }
    else {
      const status = 'Inactive'
      const role_id = data.role_id
      Update_status_fun(role_id, status)
    }

  };


  const Update_status_fun = (role_id, status) => {
    console.log('Updating status:', status);
    console.log('Role ID:', role_id);
    
    axios
      .post(`${urllink}usercontrol/statusupdate`, {
        status,
        role_id
      })
      .then((res) => {
        console.log(res.data)
        fetchRoleData();
        successMsg('Status Update Successfully');
      })
      .catch((err) => console.log(err));
  }




  const handleSubmitTwo = (e) => {
    e.preventDefault();
    const uppercaseLocationName = locationName.toUpperCase();

    if (uppercaseLocationName !== '') {
      // Create a FormData object with the location name
      const formData = new FormData();
      formData.append('locationname', uppercaseLocationName);

      axios.post(`${urllink}usercontrol/insertlocation`, formData)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error)
          }
          // Clear the form field after successful submission
          setLocationName('');

          // Fetch the updated location data
          fetchLocationData();
        })
        .catch((error) => {
          console.error('Error:', error);
          errmsg('Error occurred while inserting location.');
        });
    } else {
      userwarn('please enter the location name')
    }
  };

  const handleLocClick = (data) => {
    if (data.status === 'Inactive') {
      const status = 'Active'
      const location_id = data.location_id
      Update_loc_fun(location_id, status)
    }
    else {
      const status = 'Inactive'
      const location_id = data.location_id
      Update_loc_fun(location_id, status)
    }

  };


  const Update_loc_fun = (location_id, status) => {

    console.log(status)
    axios
      .post(`${urllink}usercontrol/statusupdatelocation`, {
        status,
        location_id
      })
      .then((res) => {
        console.log(res.data)
        fetchLocationData();
        successMsg('Status Update Successfully');
      })
      .catch((err) => console.log(err));
  }

  const handleSubmitThree = (e) => {
    e.preventDefault();
    const uppercaseDepartmentName = department.toUpperCase();

    if (uppercaseDepartmentName !== '') {
      // Create a FormData object with the location name
      const formData = new FormData();
      formData.append('department', uppercaseDepartmentName);

      axios.post(`${urllink}usercontrol/insertdepartment`, formData)
        .then((response) => {
          // Handle the response as needed
          console.log(response.data);

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error)
          }
          // Clear the form field after successful submission
          setDepartment('');

          // Fetch the updated location data
          fetchDepartmentData();
        })
        .catch((error) => {
          console.error('Error:', error);
          errmsg('Error occurred while inserting location.');
        });
    } else {
      userwarn('please Enter Department name')
    }
  };


  const handleDeptClick = (data) => {
    if (data.status === 'Inactive') {
      const status = 'Active'
      const dept_id = data.dept_id
      Update_dep_fun(dept_id, status)
    }
    else {
      const status = 'Inactive'
      const dept_id = data.dept_id
      Update_dep_fun(dept_id, status)
    }

  };


  const Update_dep_fun = (dept_id, status) => {

    console.log(status)
    axios
      .post(`${urllink}usercontrol/statusupdatefor_dept`, {
        status,
        dept_id
      })
      .then((res) => {
        console.log(res.data)
        fetchLocationData();
        successMsg('Status Update Successfully');
      })
      .catch((err) => console.log(err));
  }



  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: 'toast-container-over-header',
      style: { marginTop: '50px' },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: '50px' },
    });
  };
  const errmsg = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: '50px' },
    });
  };





  return (
    <>
      <div className='ShiftClosing_over'>
        <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>User Management</h3>
          </div>




          <div className='BodyofExpensesMaster'>
            <div className='FirstpartOFExpensesMaster'>
              <h2>Department</h2>

              <div className="con_1 ">
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Department :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="department"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    placeholder="Enter Department Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={handleSubmitThree}>
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th >Department</th>
                        <th >Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {departmentData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.Dept_id}</td>
                          <td>{row.Department_name}</td>
                          <td>

                            <button onClick={() => handleDeptClick(row)}
                              className='Addnamebtn_pt2'>{row.status}</button>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>

            </div>

                     


            <div className='FirstpartOFExpensesMaster'>
              <h2>Location</h2>

              <div className="con_1 ">
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Location Name :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="locationName"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    placeholder="Enter Location Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={handleSubmitTwo}>
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th >Location Name</th>
                        <th >Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locationData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.location_id}</td>
                          <td>{row.location_name}</td>
                          <td>

                            <button onClick={() => handleLocClick(row)}
                              className='Addnamebtn_pt2'>{row.status}</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                <ToastContainer/>
                </div>

              </div>

            </div>
            



          </div>
        </div>
        <div className="ShiftClosing_Container">
        <div className='FirstpartOFExpensesMaster'>
          <h2 style={{ textAlign: 'center' }}>Designation</h2>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Department :</label>
                  <select
                  name="department"
                  value={departmentrole}
                  onChange={(e) => setDepartmentRole(e.target.value)}
                  className='deprtsele'
                  required
                >
                  <option value="select">Select Department</option>
                  {departmentData.map((department) => (
                    <option
                      key={department.Dept_id}
                      value={department.Department_name}
                    >
                      {department.Department_name}
                    </option>
                  ))}
                </select>

                </div>
                <div className="inp_1">
                  <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Designation :</label>
                  <input
                    type="text"
                    id="FirstName"
                    name="roleName"
                    value={roleName}
                    onChange={(e) => setRoleName(e.target.value)}
                    placeholder="Enter Designation Name"
                    style={{ width: "150px" }}
                  />
                </div>
                <button className="btn_1" onClick={handleSubmitOne}>
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Department</th>
                        <th >Designation</th>
                        <th >Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roleData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.role_id}</td>
                          <td>{row.Department}</td>
                          <td>{row.role_name}</td>
                          <td>

                            <button onClick={() => handleEditClick(row)}
                              className='Addnamebtn_pt2'>{row.status}</button>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>

            </div>  
        </div>
      </div>
    </>
  );
}

export default RoleManagement;
