import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

export default function VisitingDoctorBillingList() {
  const Navigate = useNavigate();

  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  
  const Location = userRecord?.location
  const forPatientData = useSelector((state) => state.userRecord?.forPatientData);
  // const userRecord = useSelector((state) => state.userRecord?.UserData);


  const [columns] = React.useState([
    { field: "id", headerName: "Patient ID", width: 100 },
    { field: "patientname", headerName: "Patient Name", width: 180 },
    { field: "doctorname", headerName: "Visiting Doctor Name", width: 180 },
    { field: "phone", headerName: "Patient Phone No", width: 180 },
    { field: "dateofvisit", headerName: "Date Of Visit", width: 180 },
    { field: "branchlocation", headerName: "Branch Location", width: 180 },

    // {
    //   field: "view ",
    //   headerName: "View",
    //   width: 100,
    //   renderCell: (params) => (
    //     <>
    //       <Button className="cell_btn" onClick={() => handleList(params)}>
    //         <VisibilityIcon />
    //       </Button>
    //     </>
    //   ),
     
    // },
    {
        field: "action ",
        headerName: "Action",
        width: 100,
        renderCell: (params) => (
            <>
              <Button className='cell_btn' onClick={() => handleDirectProceed(params)}>
                <ArrowForwardIcon />
              </Button>
            </>
          ),
       
      },
  


   
  ]);

  const [PatientData, setPatientData] = React.useState([]);
  console.log(PatientData)
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [location, setLocation] = useState('');
  const [locationoptions, setlocationOptions] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const handlePageChange = (params) => {
    setPage(params.page);
  };


  useEffect(() => {
    fetchPatientData(Location,userRecord);
  }, [userRecord,Location]);

  const fetchPatientData = () => {
    fetch(`http://127.0.0.1:8000/VisitingDoctor/get_visitingdoctor_billinglist?location=${Location}`)
      .then((response) => response.json())
      .then((data) => {
        if (data ) {
          console.log(data)
          const rows = data.map((userdata) => ({
            id: userdata.PatientID,
            patientname: userdata.PatientName,
            phone: userdata.Patientphonenumber,
            dateofvisit: userdata.Date_Of_visit,
            doctorname: userdata.VisitingDoctor_Name,
            branchlocation: userdata.BranchLocation
          }));
          console.log(rows)
          setPatientData(rows);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };


  const handleDirectProceed =(params) =>{
    console.log(params.row)
    const patientid = params.row.id
    const location = params.row.branchlocation

    axios.get(`http://127.0.0.1:8000/VisitingDoctor/get_visitingdoctorprocedure?patientid=${patientid}&location=${location}`)
    .then((response)=>{
      console.log(response.data)
      const visitingdoctorprocedure = response.data[0]
      dispatchvalue({ type: "visitingdoctorprocedure", value: visitingdoctorprocedure });

    })
    .catch((error)=>{
      console.log(error)
    })

    Navigate('/Home/Visit-Doctor-Billing')
  }



  useEffect(() => {
    fetchlocationOptions();
  }, []);

  const fetchlocationOptions = () => {
    axios
      .get('http://127.0.0.1:8000/usercontrol/getlocationdata')
      .then(response => {
        const data = response.data;
        if (data) {
          setlocationOptions(data);
        }
      })
      .catch(error => {
        console.error('Error fetching Location options:', error);
      });
  };

  const handlelocationchange = (event) => {
    const value = event.target.value.trim();
    setLocation(value);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === 'FirstName') {
      setSearchQuery(value);
    } else if (id === 'PhoneNo') {
      setSearchQuery1(value);
    } else if (id === 'location') {
      setSearchQuery2(value.toLowerCase());
    }
  };

  useEffect(() => {
    console.log(PatientData)
    const filteredData = PatientData.filter((row) => {
        const lowerCaseSupplierName = (row.Doctorname || '').toLowerCase();
        const lowerCasePhoneNo = (row.phone || '').toString().toLowerCase();
    //   const lowerCaseLocation = row.branchlocation.toLowerCase(); // Use row.location.location_name

      const lowerCaseQuery = searchQuery.toLowerCase();
      const lowerCaseQuery1 = searchQuery1.toLowerCase();
    //   const lowerCaseQuery2 = searchQuery2.toLowerCase();
  
      const matchesFirstName = lowerCaseSupplierName.includes(lowerCaseQuery);
      const matchesPhoneNo = lowerCasePhoneNo.includes(lowerCaseQuery1);
    //   const matchesLocation = lowerCaseLocation.includes(lowerCaseQuery2);
  
      return (
        (matchesFirstName || !searchQuery) &&
        (matchesPhoneNo || !searchQuery1)
        // (matchesLocation || !searchQuery2)
      );
    });
  
    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, searchQuery2, PatientData]);

  return (
    <>
      <Helmet>
        <title>Patient List</title>
      </Helmet>
      <div className="appointment">
        <div className="h_head">
          <h3>Visiting Doctor Patients</h3>
        </div>
        <form>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">First Name :</label>
              <input
                type="text"
                id="FirstName"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Phone No :</label>
              <input
                type="text"
                id="PhoneNo"
                value={searchQuery1}
                onChange={handleSearchChange}
                placeholder="Enter the Phone No"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Location :</label>
              <select
                id="location"
                name="location"
                className="new-custom-input-phone vital_select"
                onChange={handlelocationchange}
                required
              >
                <option>Select</option>
                {locationoptions.map(loc => (
                  <option key={loc.location_id} value={loc.location_name}>
                    {loc.location_name}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn_1" type="submit">
              <SearchIcon />
            </button>
          </div>
        </form>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                columns={columns}
                pageSize={10}
                getRowId={(row) => row.id}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>

          {filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
