import React, { useState } from 'react'

const RadiologyMaster = () => {
    const [ radiologymaster, setradiologymaster] = useState({
        testname :'',
        testcode :'',
        Department :'',
        methodname :'',
        mintime:'',
        emergencytime:'',
        testcost:'',
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setradiologymaster(prevState => ({
          ...prevState,
          [name]: value,
        }));
      };
     

    const productData =[
        'MRI',
        'CT',
        "X-Ray"
    ]
  return (
    <div className="appointment">
    <div className="h_head">
      <h4>Radiology Master</h4>
    </div>
    <div className="RegisFormcon">
      
          <div className="RegisForm_1">
            <label className="" htmlFor="testname">
             Test Name <span>:</span>
            </label>
            <input
              type="text"
              id="testname"
              name="testname"
              // placeholder="Enter Equipment Name"
              // className="new_clinic_form_inp111"
              required
              value={radiologymaster.testname}
              onChange={handleChange}
            />
          </div>

      
          <div className="RegisForm_1">
            <label className="" htmlFor="testcode">
              Test Code<span>:</span>
            </label>
            <input
              type="text"
              id="testcode"
              name="testcode"
              // placeholder="Enter Equipment Type"
              // className="new_clinic_form_inp111"
              required
              value={radiologymaster.testcode}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="Department">
              Department <span>:</span>
            </label>
            <input
              type="text"
              id="Department"
              name="Department"
              // placeholder="Enter Equipment Category"
              // className="new_clinic_form_inp111"
              required
              value={radiologymaster.Department}
              onChange={handleChange}
            />
          </div>

          <div className="RegisForm_1">
            <label className="" htmlFor="methodname">
              Method Name <span>:</span>
            </label>
            <select
              id="methodname"
              name="methodname"
              required
              // className="new_clinic_form_inp111"
              value={radiologymaster.methodname}
              onChange={handleChange}
            >
              <option value="">Select</option>
               {productData?.map((type,index)=>(
                <option key={index} value={type} >{type}</option>
                ))}
            </select>
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="mintime">
              Minimum Processing Time<span>:</span>
            </label>
            <input
              type="number"
              id="mintime"
              name="mintime"
              // placeholder="Enter Equipment Age"
              // className="new_clinic_form_inp111"
              required
              value={radiologymaster.mintime}
              onChange={handleChange}
            />
          </div>
 
          <div className="RegisForm_1">
            <label className="" htmlFor="emergencytime">
              Emergency Processing Time <span>:</span>
            </label>
            <input
              type="number"
              id="emergencytime"
              name="emergencytime"
              // className="new_clinic_form_inp111"
              required
              value={radiologymaster.emergencytime}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="testcost">
           Test Cost<span>:</span>
            </label>
            <input
              type="number"
              id="testcost"
              name="testcost"
              // className="new_clinic_form_inp111"
              required
              value={radiologymaster.testcost}
              onChange={handleChange}
            />
          </div>
          
      
        
     
    </div>

    <div className='Register_btn_con'>
          <button className='new_btn1_1' >Save</button>
        </div>
  </div>
  )
}

export default RadiologyMaster