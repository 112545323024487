import React, { useEffect, useState } from "react";
import "./ProductMaster.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

function LabProductMaster() {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const selectedProductIndex = useSelector(
    (state) => state.userRecord?.selectedProductIndex
  );


  const [isInactive, setIsInactive] = useState(false);

  const [isTestconsumption, setIsTestconsumption] = useState(false);


  const navigate = useNavigate();

  const [prodData, setProdData] = useState([]);


  const [formDataProduct, setFormDataProduct] = useState({
    ProductCategory: "",
    ProductType: "",
    ProductQuantity: "",
    ItemCode: "",
    ItemName: "",
    GenericName: "",
    Strength: "",
    UOM: "",
    Status: "Active",
    Testconsumption:'No',    
    TCStrength:"",  
    TCUOM:'Ml',
    Minimumquantity:"",
    Maximquantity:"",
  });

  
  useEffect(() => {
    setFormDataProduct((prevData) => ({
      ...prevData,
      ProductType: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
      Testconsumption:'No',  
      TCStrength:"",  
      TCUOM:'Ml',
      Minimumquantity:"",
      Maximquantity:"",
    }));
    setIsInactive(false);
    setIsTestconsumption(false)
  }, [formDataProduct.ProductCategory]);

  useEffect(() => { 
    axios
      .get(`${urllink}LabInventory/getProductData`)
      .then((response) => {
        const data = response.data;
        if(data.length !==0){
          setProdData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  },[urllink]);
  
  useEffect(()=>{
    console.log('selectedProductIndex',selectedProductIndex)
     if (selectedProductIndex && Object.keys(selectedProductIndex).length !==0) {
      
      setFormDataProduct({...selectedProductIndex})
      if (selectedProductIndex.Status !== "Active") {
        setIsInactive(true);
      } else {
        setIsInactive(false);
      }

      if (selectedProductIndex.Testconsumption === "Yes") {
        setIsTestconsumption(true);
      } else {
        setIsTestconsumption(false);
      }
    }
    else{
      
      if(formDataProduct.ProductCategory === 'Stationary' && formDataProduct.ProductCategory !== ''){
        axios
      .get(
        `${urllink}LabInventory/get_Stationary_Product_code`
      )
      .then((response) => {
        setFormDataProduct((prevData) => ({
          ...prevData,
          ItemCode: response.data.nextStationaryProductCode,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      }else if(formDataProduct.ProductCategory === 'NonMedical'  && formDataProduct.ProductCategory !== ''){
        axios
      .get(
        `${urllink}LabInventory/get_NonMedical_Product_code`
      )
      .then((response) => {
        setFormDataProduct((prevData) => ({
          ...prevData,
          ItemCode: response.data.nextMedicalProductCode,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

      }else if(formDataProduct.ProductCategory === 'Medical'  && formDataProduct.ProductCategory !== ''){
        axios
      .get(
        `${urllink}LabInventory/get_Medical_Product_code?Productcategory=${formDataProduct.ProductCategory}`
      )
      .then((response) => {
        setFormDataProduct((prevData) => ({
          ...prevData,
          ItemCode: response.data.nextMedicalProductCode,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
    else{
      setFormDataProduct((prevData) => ({
        ...prevData,
        ItemCode:'',
      }));
    }
      
      
   }
  },[selectedProductIndex,formDataProduct.ProductCategory])
  
  const handleInactiveChange = () => {
    setIsInactive(!isInactive);
    setFormDataProduct((prevData) => ({
      ...prevData,
      Status: !isInactive ? "Inactive" : "Active",
    }));
  };


  const handletestconsumption = () => {
    setIsTestconsumption(!isTestconsumption);
    setFormDataProduct((prevData) => ({
      ...prevData,
      Testconsumption: !isTestconsumption ? "Yes" : "No",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1)
    setFormDataProduct((prevData) => ({
      ...prevData,
      [name]: capitalizedValue,
    }));
  };

  const handleSaveOrUpdate = () => {
    console.log(formDataProduct);
    const newData = {
      ...formDataProduct,
      createdby: userRecord?.username,
      Location: userRecord?.location,
    };
    let requiredFields;

    if (newData.ProductCategory === "Medical") {
      requiredFields = [
        "ProductCategory",
        "ItemName",
        "ItemCode",
        "GenericName",
        "Strength",
        "UOM",
        "Minimumquantity",
        "Maximquantity",
        "ProductType",
      ];
    }else{
      requiredFields = [
        "ProductCategory",
        "ItemName",
        "ItemCode",
        "Minimumquantity",
        "Maximquantity",
        "ProductType",
      ];
    }

    if (newData.Testconsumption === "Yes") {
      requiredFields.push('TCStrength','TCUOM')
    }
    const missingFields = requiredFields.filter(
      (field) => !formDataProduct[field]
    );

    if (missingFields.length === 0) {
      if (Object.keys(selectedProductIndex).length !==0) {
        axios
          .post(
            `${urllink}LabInventory/update_Medical_ProductMaster_data`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            clearFormInputs();
            dispatchvalue({ type: "selectedProductIndex", value: {} });

            // After successfully updating data, navigate to Pharmacy_MasterList
            navigate("/Home/LabProductMasterList");
          })
          .catch((error) => {
            console.error("Error post Supplier data:", error);
          });
      } else {
        axios
          .post(
            `${urllink}LabInventory/insert_Medical_ProductMaster_data`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            clearFormInputs();
            navigate("/Home/LabProductMasterList");
          })
          .catch((error) => {
            console.error("Error post Supplier data:", error);
          });
      }
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

 
  const clearFormInputs = () => {
    setFormDataProduct((prev)=>({
      ...prev,
      ProductCategory: "",
      ProductType: "",
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
      Testconsumption:'No',  
      TCStrength:"",  
      TCUOM:'Ml',
      Minimumquantity:"",
      Maximquantity:"",
    }));
    setIsInactive(false);
    setIsTestconsumption(false)
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Product Master L</h4>
      </div>
     <br />
        
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="ProductType">Product Category <span>:</span></label>
              <select
                value={formDataProduct.ProductCategory}
                name="ProductCategory"
                onChange={handleInputChange}
                disabled={Object.keys(selectedProductIndex).length !==0}
              >
                <option value="">-Select-</option>
                <option value="Medical">Medical</option>
                <option value="NonMedical">Non Medical</option>
                <option value="Stationary">Stationary</option>
              </select>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="code">Item Code <span>:</span></label>
              <input
                type="text"
                name="ItemCode"
             
                value={formDataProduct.ItemCode}
                required
                readOnly
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="itemName">Item Name <span>:</span></label>
              <input
                type="text"
                name="ItemName"
           
                value={formDataProduct.ItemName}
                onChange={handleInputChange}
              />
            </div>

            {(formDataProduct.ProductCategory === "Medical") && (
            <div className="RegisForm_1">
              <label htmlFor="generic">Generic Name<span>:</span></label>
              <input
                type="text"
                name="GenericName"
          
                value={formDataProduct.GenericName}
                onChange={handleInputChange}
              />
            </div>)}
             

            <div className="RegisForm_1">
              <label htmlFor="uom">Product Type<span>:</span></label>
              <select
                    id="ProductType"
                    name="ProductType"
                    value={formDataProduct.ProductType}
                    onChange={handleInputChange}
                    required
                  >
                    <option value={""}>Select</option>
                    {prodData.map((loc) => (
                      <option key={loc.prod_id} value={loc.productType}>
                        {loc.productType}
                      </option>
                    ))}
                  </select>
            </div>

            {(formDataProduct.ProductCategory === "Medical"
             ) && (
              <>
                <div className="RegisForm_1">
                  <label htmlFor="strength">Strength<span>:</span></label>
                  <input
                    type="number"
                    name="Strength"
                    value={formDataProduct.Strength}
                    onChange={handleInputChange}
                    onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="strength">UOM<span>:</span></label>
                  <input
                    type="text"
                    name="UOM"
                    value={formDataProduct.UOM}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}

            <div className="RegisForm_1">
              <label htmlFor="uom">Product Quantity<span>:</span></label>
              <input
                type="number"
                name="ProductQuantity"             
                value={formDataProduct.ProductQuantity}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
              <label>Minimum quantity<span>:</span></label>
              <input
                type="number"
                name="Minimumquantity"             
                value={formDataProduct.Minimumquantity}
                onChange={handleInputChange}
              />
            </div>

            <div className="RegisForm_1">
              <label>Maximum quantity<span>:</span></label>
              <input
                type="number"
                name="Maximquantity"             
                value={formDataProduct.Maximquantity}
                onChange={handleInputChange}
              />
            </div>

            {(formDataProduct.ProductCategory === "Medical")
             && <div className="RegisForm_1">
              <label>
              Lab Test Consumption<span>:</span>
                </label>
                <input
                  type="checkbox"
                  name="Yes"
                  checked={isTestconsumption}
                  onChange={handletestconsumption}
                />
             
            </div>}

            {(formDataProduct.ProductCategory === "Medical")&& 
            formDataProduct.Testconsumption === "Yes" && 
             <>
             <div className="RegisForm_1">
                  <label>Test Consumption Qty<span>:</span></label>
                  <input
                    type="number"
                    name="TCStrength"
                    value={formDataProduct.TCStrength}
                    onChange={handleInputChange}
                    autoComplete="off"
                    onKeyDown={(e)=>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>Test Consumption UOM<span>:</span></label>
                  <input
                    type="text"
                    name="TCUOM"
                    value={formDataProduct.TCUOM}
                    onChange={handleInputChange}
                    readOnly
                  />
                </div>
             </>
             }

            
            <div className="RegisForm_1">
              <label htmlFor="inactive">
                Inactive<span>:</span>
                </label>
                <input
                  type="checkbox"
                  name="inactive"
                  checked={isInactive}
                  onChange={handleInactiveChange}
                />
             
            </div>
            {/* )} */}
          </div>

        


          <div className="Register_btn_con">
            <button
              className=" RegisterForm_1_btns"
              onClick={handleSaveOrUpdate}
            >
              {Object.keys(selectedProductIndex).length !==0 ? "Update" : "Save"}
            </button>
          </div>
       
     
    </div>
  );
}

export default LabProductMaster;
