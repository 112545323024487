import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const LeaveApproval = ( ) => {

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  const userRecord=useSelector(state=>state.userRecord?.UserData)

  const urllink=useSelector(state=>state.userRecord?.UrlLink)


  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuerypre, setSearchQuerypre] = useState('')
  const [searchQuerypre1, setSearchQuerypre1] = useState('')
  const [showsudden,setshowsudden]=useState(false)
  const [leaveCounts, setLeaveCounts] = useState({});

  console.log(userRecord)
  const [rows, setRows] = useState([]);
  // const [rowsAfter, setRowsAfter] = useState([]);

  const [rows1, setRows1] = useState([]);
  const [rows12, setRows12] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);


  const [selectedRowData, setSelectedRowData] = useState(null);
  const [expanded, setExpanded] = useState("panel1");

  const [filteredRows, setFilteredRows] = useState([]);
  const [filteredRows1, setFilteredRows1] = useState([]);

  const showdown = rows.length;
  const showdown1 = rows1.length;
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(rows.length / 10);
  // const [selectedRows, setSelectedRows] = React.useState([]);



  const [page, setPage] = useState(0);
  // const [page1, setPage1] = useState(0);
  const pageSize = 10;


  const [rolename, setRolename] = useState([])
  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/getRole_all`
      )
      .then((response) => {
        console.log(response.data);
        setRolename(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


  }, [userRecord?.location]);



  useEffect(() => {
    fetchPermissionsData();
  }, [showsudden, userRecord]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${urllink}HRmanagement/get_leave_register_data?location=${userRecord?.location}`;
        const leaveRegisterResponse = await fetch(url);

        if (!leaveRegisterResponse.ok) {
          throw new Error(`HTTP error! Status: ${leaveRegisterResponse.status}`);
        }

        const leaveRegisterData = await leaveRegisterResponse.json();

        let combinedData = [];

        if (Array.isArray(leaveRegisterData)) {
          combinedData = leaveRegisterData.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            leaveType: userdata.LeaveType,
            fromdate: userdata.FromDate,
            todate: userdata.ToDate,
            days: userdata.DaysCount,
            designation: userdata.designation,
            reason: userdata.Reason
          }));
        } else {
          console.error("Leave register data is not an array:", leaveRegisterData);
        }

        setRows(combinedData);

      } catch (error) {
        console.error("Error in fetching data:", error);
      }
    };

    fetchData();
  }, [userRecord, showsudden]);




  const handleVisibilityClick = (prams) => {
    const employeeId = prams
    axios.get(`${urllink}HRmanagement/get_employee_leave_count?location=${userRecord?.location}&EmployeeId=${employeeId}`)

      .then((responce) => {
        const data = responce.data;
        console.log('data', data)
        setLeaveCounts({
          sickleave: data.sick_leave,
          casualleave: data.casual_leave,
          totalleave: data.total_leave,
          remainsick: data.remaining_sick,
          remaincasual: data.remaining_casual,
          remainleave: data.remaining_leave
        });
        setOpenModal1(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }




  const fetchPermissionsData = () => {
    fetch(`${urllink}HRmanagement/get_for_permission?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {

        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            leaveType: userdata.LeaveType,
            fromtime: userdata.fromtime,
            totime: userdata.totime,
            hours: userdata.HoursCount,
            reason: userdata.Reason,
            designation: userdata.designation,
          }));
          setRows1(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  console.log(leaveCounts)


  const [status, setStatus] = useState('')
  const [reject, setReject] = useState('')
  // const [status1, setStatus1] = useState('')
  // const [reject1, setReject1] = useState('')

  const [columns] = React.useState([
    { field: "employeeid", headerName: "Employee ID", width: 140 },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "leaveType", headerName: "Leave Type", width: 115 },
    { field: "fromdate", headerName: "From Date", width: 115 },
    { field: "todate", headerName: "To Date", width: 120 },
    { field: 'days', headerName: 'DaysCount', width: 100 },
    { field: "reason", headerName: "Reason", width: 140 },
    {
      field: "leaveCounts",
      headerName: "Remaining Days",
      width: 140,
      renderCell: (params) => {
        if (params.row.leaveType === 'sick' || params.row.leaveType === 'casual') {
          return (
            <Button className="cell_btn" onClick={() => handleVisibilityClick(params.row.employeeid)}>
              <VisibilityIcon />
            </Button>
          );
        }
      },

    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <>
          <Button className='cell_btn' onClick={() => handleEditClick(params.row)} >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ]);

  const [leavecolumns] = React.useState([
    { field: 'id', headerName: 'Serial No', width: 180 },
    { field: "leavetype", headerName: "Leave Type", width: 190 },
    { field: "available", headerName: "Availed Leaves", width: 180 },
    { field: "remaining", headerName: "Remaining Leaves", width: 180 },

  ]);

  useEffect(() => {
    setRows12([
      {
        id: 1,
        leavetype: 'Sick Leave',
        available: leaveCounts.sickleave,
        remaining: leaveCounts.remainsick < 0 ? 'Limit Exceeded' : leaveCounts.remainsick,
      },
      {
        id: 2,
        leavetype: 'Casual Leave',
        available: leaveCounts.casualleave,
        remaining: leaveCounts.remaincasual < 0 ? 'Limit Exceeded' : leaveCounts.remaincasual,
      },
      {
        id: 3,
        leavetype: 'Total Leave',
        available: leaveCounts.totalleave,
        remaining: leaveCounts.remainleave < 0 ? 'Limit Exceeded' : leaveCounts.remainleave,
      },
    ]);
  }, [leaveCounts]);


  const [columns1] = React.useState([
    { field: "employeeid", headerName: "Employee ID", width: 180 },
    { field: "employeename", headerName: "Employee Name", width: 180 },
    { field: "leaveType", headerName: "Leave Type", width: 150 },
    { field: "fromtime", headerName: "From Time", width: 150 },
    { field: "totime", headerName: "To Time", width: 150 },
    { field: "hours", headerName: "Hour Count", width: 150 },
    { field: "reason", headerName: "Reason", width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <>
          <Button className='cell_btn' onClick={() => handleEditClick1(params.row)} >
            <EditIcon />
          </Button>
        </>
      ),
    },
  ]);

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
  };

  const handleReasonChange = (e) => {
    const newReason = e.target.value;
    setReject(newReason);
  };

  const handleEditClick = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)


  };

  const handleEditClick1 = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)


  };

  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmission = () => {
    {
      const apiUrl = `${urllink}HRmanagement/update_leave_status`;

      const submissionData = {
        Sl_No: selectedRowData.Sl_No,
        status: status,
        reject: reject,
      };
      console.log(submissionData)
      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Submission response:', data);
          setOpenModal2(false)
          setStatus('');
          setReject();
          setshowsudden(!showsudden)
        })
        .catch(error => {
          console.error('Error submitting data:', error);
        });
    }
  };

  const handleSubmission1 = () => {
    {
      const apiUrl = `${urllink}HRmanagement/update_permission_status`;

      const submissionData = {
        Sl_No: selectedRowData.Sl_No,
        status: status,
        reject: reject,
      };
      console.log(submissionData)

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Submission response:', data);
          setOpenModal(false)
          setStatus('');
          setReject();
          setshowsudden(true)
        })
        .catch(error => {
          console.error('Error submitting data:', error);
        });
    }
  };
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseQuery1 = searchQuery1.toLowerCase();

    const filterRow = (row) => {
      // Ensure the row has necessary properties before accessing them
      if (row.employeename && row.designation) {
        const lowerCaseEmployeeName = row.employeename.toLowerCase();
        const lowerCaseDesignation = row.designation.toLowerCase();
        const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
        const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);

        if (!searchQuery && !searchQuery1) {
          // If both search queries are empty, do not filter out any data
          return true;
        }

        return nameMatches && designationMatches;
      }
      return false;
    };

    const filteredData = rows.filter(filterRow);

    setFilteredRows(filteredData);

  }, [searchQuery, searchQuery1, rows]);



  useEffect(() => {
    const lowerCaseQuerypre = searchQuerypre.toLowerCase();
    const lowerCaseQuerypre1 = searchQuerypre1.toLowerCase();

    const filterRow = (row) => {
      const lowerCaseEmployeeName = row.employeename.toLowerCase();
      const lowerCaseDesignation = row.designation.toLowerCase();
      const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuerypre);
      const designationMatches = lowerCaseDesignation.includes(lowerCaseQuerypre1);

      if (!searchQuerypre && !searchQuerypre1) {
        // If both search queries are empty, do not filter out any data
        return true;
      }

      return nameMatches && designationMatches;
    };


    const filteredData1 = rows1.filter(filterRow);


    setFilteredRows1(filteredData1);
  }, [searchQuerypre, rows1, searchQuerypre1]);



  return (
    <div>
      <Accordion
        expanded={expanded !== "panel2"}
        onChange={handleChange1("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Leave Approval
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="appointment">
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">Employee Name <span>:</span></label>
                <input
                  type="text"
                  id="date"
                  name='employeeName'
                  placeholder='Enter Employee Name'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input">Designation <span>:</span></label>
                <select
                  name="designation"
                  value={searchQuery1}
                  onChange={(e) => setSearchQuery1(e.target.value)}
                  className="new-custom-input-phone wei32j"
                  required
                >
                  <option value="select">Select </option>
                  {rolename.map((role) => (
                    <option key={role.role_id} value={role.role_name}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="inp_1">
                <label htmlFor="input">Date :</label>
                <input
                  type="date"
                  id="date"
                  // value={searchFormdata.date}
                  // onChange={handleChange}
                />
              </div> */}


              {/* <button className="btn_1" onClick={handleSearch}>
                <SearchIcon />
              </button> */}
            </div>
          </div>
          <ThemeProvider theme={theme}>
            <div className=" grid_1">
              <DataGrid
                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                columns={columns}
                pageSize={100}
                hideFooter={true}
                isCellEditable={(params) => params.row.id !== 1}
                onEditCellChange={(params) => {
                  const updatedRows = rows.map((row) => {
                    if (row.id === params.id) {
                      return { ...row, [params.field]: params.props.value };
                    }
                    return row;
                  });
                  setRows(updatedRows);
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" data_grid"
              />
              {showdown > 10 && filteredRows.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
            {/* <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSubmission}>
                Submit
              </button>
            </div> */}
          </ThemeProvider>

          {showdown !== 0 && filteredRows.length !== 0 ? (
            ''
          ) : (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}
          
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange1("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Permissions Approval
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="appointment">
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">Employee Name <span>:</span></label>
                <input
                  type="text"
                  id="date"
                  name='employeeName'
                  placeholder='Enter Employee Name'
                  value={searchQuerypre}
                  onChange={(e) => setSearchQuerypre(e.target.value)}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input">Designation <span>:</span></label>
                <select
                  name="designation"
                  value={searchQuerypre1}
                  onChange={(e) => setSearchQuerypre1(e.target.value)}
              
                  required
                >
                  <option value="select">Select</option>
                  {rolename.map((role) => (
                    <option key={role.role_id} value={role.role_name}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="inp_1">
                <label htmlFor="input">Date :</label>
                <input
                  type="date"
                  id="date"
                  // value={searchFormdata.date}
                  // onChange={handleChange}
                />
              </div> */}


              {/* <button className="btn_1" onClick={handleSearch}>
                <SearchIcon />
              </button> */}
            </div>
          </div>
          <ThemeProvider theme={theme}>
            <div className=" grid_1">
              <DataGrid
                rows={filteredRows1.slice(page * pageSize, (page + 1) * pageSize)}

                columns={columns1}
                pageSize={100}
                hideFooter={true}
                isCellEditable={(params) => params.row.id !== 1}
                onEditCellChange={(params) => {
                  const updatedRows = rows1.map((row) => {
                    if (row.id === params.id) {
                      return { ...row, [params.field]: params.props.value };
                    }
                    return row;
                  });
                  setRows1(updatedRows);
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" data_grid"
              />
              {showdown1 > 10 && filteredRows1.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
              {showdown1 !== 0 && filteredRows1.length !== 0 ? (
                ''
              ) : (
                <div className='IP_norecords'>
                  <span>No Records Found</span>
                </div>
              )}
            </div>
            {/* <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSubmission1}>
                Submit
              </button>
            </div> */}
          </ThemeProvider>


          
        </AccordionDetails>
      </Accordion>
      {openModal && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModal(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
                <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1">
                    <label htmlFor="issued">Status<span>:</span></label>
                    <select
                      name="approval"
                      id="approval"
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value="">Select</option>

                      <option value="Approved">Approved</option>


                      <option value="Not Approved">Not Approved</option>

                    </select>
                  </div>
                </div>
                {status === 'Not Approved' && (
                  <div className="RegisFormcon leavecon" >
                    <div className="RegisForm_1 leaveform_1 ">
                      <label htmlFor="reason">Reason <span>:</span></label>
                      <textarea
                        type="text" name="reason" id="" onChange={handleReasonChange}></textarea>
                    </div>
                  </div>
                )}
                <div className="Register_btn_con regster_btn_contsai">
                  <button className="RegisterForm_1_btns" onClick={handleSubmission1}>Submit</button>
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
          {openModal2 && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModal2(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
                <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1">
                    <label htmlFor="issued">Status<span>:</span></label>
                    <select
                      name="approval"
                      id="approval"
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value="">Select</option>

                      <option value="Approved">Approved</option>


                      <option value="Not Approved">Not Approved</option>

                    </select>
                  </div>
                </div>
                {status === 'Not Approved' && (
                  <div className="RegisFormcon leavecon" >
                    <div className="RegisForm_1 leaveform_1 ">
                      <label htmlFor="reason">Reason <span>:</span></label>
                      <textarea
                        type="text" name="reason" id="" onChange={handleReasonChange}></textarea>
                    </div>
                  </div>
                )}
                <div className="Register_btn_con regster_btn_contsai">
                  <button className="RegisterForm_1_btns" onClick={handleSubmission}>Submit</button>
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModal2(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
          {openModal1 && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModal1(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>


                <ThemeProvider theme={theme}>
                  <div className="grid_1">
                    <DataGrid
                      rows={rows12}
                      columns={leavecolumns}
                      pageSize={100}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      onPageChange={(params) => setPage(params.page)}
                      hideFooterPagination
                      hideFooterSelectedRowCount
                      className="data_grid"
                    />
                  </div>
                </ThemeProvider>
                <div className="Register_btn_con ">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModal1(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
    </div>
  );
};

export default LeaveApproval;
